import { createAction, props } from '@ngrx/store';
import { IFallbackCreative } from '@shared/models/campaigns';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';

export const loadSizes = createAction('[Brand] Load Sizes');

export const loadSizesSuccess = createAction(
    '[Brand] Load Success Sizes',
    props<{ sizes: ISize[] }>()
);

export const loadLocalizations = createAction('[Brand] Load Localizations');

export const loadLocalizationsSuccess = createAction(
    '[Brand] Load Success Localizations',
    props<{ localizations: ILocalization[] }>()
);

export const loadFallbackCreativeSet = createAction('[Brand] Load Fallback Creative set');

export const loadFallbackCreativeSetSuccess = createAction(
    '[Brand] Load Fallback Creative Set Success',
    props<{ fallbackCreatives: IFallbackCreative[] }>()
);

export const loadPublishOptions = createAction('[Brand] Load Publish Options');

export const loadPublishOptionsSuccess = createAction(
    '[Brand] Load Publish Options Success',
    props<{ publishOptions: PublishOptionConfiguration[] }>()
);

export const loadHeavyAssetFallbackMap = createAction(
    '[Brand] Load Heavy Asset Fallback Map',
    props<{ fallbackCreatives: IFallbackCreative[] }>()
);

export const loadHeavyAssetFallbackMapSuccess = createAction(
    '[Brand] Load Heavy Asset Fallback Map Success',
    props<{ heavyAssetFallback: string[] }>()
);

export type BrandActions = ReturnType<
    | typeof loadSizes
    | typeof loadSizesSuccess
    | typeof loadLocalizations
    | typeof loadLocalizationsSuccess
    | typeof loadFallbackCreativeSet
    | typeof loadFallbackCreativeSetSuccess
    | typeof loadPublishOptions
    | typeof loadPublishOptionsSuccess
    | typeof loadHeavyAssetFallbackMap
    | typeof loadHeavyAssetFallbackMapSuccess
>;
