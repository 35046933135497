import { MemoizedSelector, createSelector } from '@ngrx/store';
import { selectEditorState } from '../editor.selectors';
import { IAppStateWithEditor, IEditorState } from '../editor.state';
import { ILeftPanelState } from './left-panel.reducers';

const selectLeftPanelState: MemoizedSelector<IAppStateWithEditor, ILeftPanelState> = createSelector(
    selectEditorState,
    (state: IEditorState) => state.leftPanel
);

export const selectLeftPanelPinned: MemoizedSelector<IAppStateWithEditor, boolean> = createSelector(
    selectLeftPanelState,
    (state: ILeftPanelState) => state.pinned
);

export const selectLeftPanelResizing: MemoizedSelector<IAppStateWithEditor, boolean> =
    createSelector(selectLeftPanelState, (state: ILeftPanelState) => state.isResizing);

export const selectLeftPanelWidth: MemoizedSelector<IAppStateWithEditor, number> = createSelector(
    selectLeftPanelState,
    (state: ILeftPanelState) => state.width
);

export const selectLeftPanelShow: MemoizedSelector<IAppStateWithEditor, boolean> = createSelector(
    selectLeftPanelState,
    (state: ILeftPanelState) => state.show
);
