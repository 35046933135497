import { inject, Injectable } from '@angular/core';
import { selectCampaign } from '@campaign/store/campaign/campaign.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import { selectBrandLocalizations } from '@core/store/brand.selectors';
import { Store } from '@ngrx/store';
import { IAd, ICampaign, IDecisionTree, NodeType } from '@shared/models/campaigns';
import { ILocalization } from '@shared/models/common';
import { IOrchestratorAd } from '@shared/models/orchestrator/orchestrator-ad';
import { IPublishReadyAd } from '@shared/models/publish/publish-ready-ad';
import { ICreativeSetVM, ICreativeVM } from '@shared/models/studio/creative-view-models';
import { from, lastValueFrom } from 'rxjs';
import { filter, mergeMap, take, toArray } from 'rxjs/operators';
import { DecisionTreeService } from './decision-tree.service';

@Injectable({ providedIn: 'root' })
export class PublishAdPrepareService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private cachedCreativeSetService = inject(CachedCreativesetService);
    private decisionTreeService = inject(DecisionTreeService);

    /**
     * Prepares the campaign for publishing by fetching validated ads,
     */
    public async prepareAds(ads: IAd[]): Promise<IPublishReadyAd[]> {
        // this is a one time fire and forget evet so Promise is good

        const campaign: ICampaign = await lastValueFrom(
            this.store.select(selectCampaign).pipe(
                filter((c) => !!c),
                take(1)
            )
        );
        const localizations: ILocalization[] = await lastValueFrom(
            this.store.select(selectBrandLocalizations).pipe(take(1))
        );

        // flattens array and runs all promises in parallel then gathers the results in array
        const publishReadyAds: IPublishReadyAd[] = await lastValueFrom(
            from(ads).pipe(
                mergeMap(async (ad) => {
                    const tree: IDecisionTree = await lastValueFrom(
                        this.decisionTreeService.selectDecisionTree(ad.adGroupId).pipe(take(1))
                    );

                    const hasDecisionRule: boolean = tree?.root.type !== NodeType.CreativeNode;
                    const hasAutoOptimisation: boolean =
                        tree?.root.type === NodeType.AutoOptimisation;
                    const language: ILocalization = localizations.find(
                        (loc) => loc.id === ad.localizationId
                    );

                    if (ad.creativeRefs.length === 1) {
                        const creativeSetId: string = ad.creativeRefs[0].creativeSetId;
                        const creativeId: string = ad.creativeRefs[0].creativeId;

                        const creativeSet: ICreativeSetVM = await lastValueFrom(
                            this.cachedCreativeSetService
                                .getCreativesetById(creativeSetId)
                                .pipe(take(1))
                        );

                        const creative: ICreativeVM = creativeSet.creatives.find(
                            (c) => c.id === creativeId
                        );

                        return {
                            id: ad.id,
                            campaignId: campaign.id,
                            campaignName: campaign.name,
                            adName: ad.displayName,
                            size: ad.size,
                            language,
                            targetUrl: ad.targetUrl,
                            creativeSetId: creativeSet.id,
                            creativeSetName: creativeSet.name,
                            creativeSizeName: creative.size.name,
                            creativeVersionName: creative.version.name,
                            hasDecisionRule,
                            hasAutoOptimisation,
                            adGroupName: tree?.adGroup.name
                        };
                    } else {
                        // multiple creativesets

                        return {
                            id: ad.id,
                            campaignId: campaign.id,
                            campaignName: campaign.name,
                            adName: ad.displayName,
                            size: ad.size,
                            language,
                            targetUrl: ad.targetUrl,
                            hasDecisionRule,
                            hasAutoOptimisation,
                            adGroupName: tree?.adGroup.name
                        };
                    }
                }),
                toArray()
            )
        );
        return publishReadyAds;
    }

    public async prepareOrchestratorAds(ads: IOrchestratorAd[]): Promise<IPublishReadyAd[]> {
        const localizations: ILocalization[] = await lastValueFrom(
            this.store.select(selectBrandLocalizations).pipe(take(1))
        );

        const publishReadyAds: IPublishReadyAd[] = await lastValueFrom(
            from(ads).pipe(
                mergeMap(async (ad) => {
                    const creativeSetId: string = ad.creativesetId;
                    const creativeId: string = ad.creativeId;

                    const creativeSet: ICreativeSetVM = await lastValueFrom(
                        this.cachedCreativeSetService
                            .getCreativesetById(creativeSetId)
                            .pipe(take(1))
                    );

                    const creative: ICreativeVM = creativeSet.creatives.find(
                        (c) => c.id === creativeId
                    );

                    return {
                        id: ad.adId,
                        size: ad.size,
                        language: localizations.find((loc) => loc.id === ad.localizationId),
                        targetUrl: ad.targetUrl,
                        creativeSetId: creativeSet.id,
                        creativeSetName: creativeSet.name,
                        creativeSizeName: creative.size.name,
                        creativeVersionName: creative.version.name,
                        hasDecisionRule: false // always false because it is zip orchestrator
                    };
                }),
                toArray()
            )
        );

        return publishReadyAds;
    }
}
