import { createAction, props } from '@ngrx/store';
import { PreviewEmptyState, IAdPreviewVM, PreviewViewTab } from '../models/ad-preview-view-model';

export const setPreviewAdGroup = createAction(
    '[Ad Preview] Set Ad Group',
    props<{ adGroupId: string }>()
);

export const selectPreviewAd = createAction('[Ad Preview] Select Ad', props<{ adId: string }>());

export const loadAdAllPreviews = createAction('[Ad Preview] Load All');

export const setPreviewViewTab = createAction(
    '[Ad Preview] Set Preview View Tab',
    props<{ viewTab: PreviewViewTab }>()
);

export const setPreviewViewIndex = createAction(
    '[Ad Preview] Set Preview View Index',
    props<{ viewIndex: number }>()
);

export const setPreviewTime = createAction(
    '[Ad Preview] Set Preview Time',
    props<{ time: number | undefined; localTime: string }>()
);

export const setPreviewEmptyState = createAction(
    '[Ad Preview] Set Current Preview Empty State',
    props<{ previewEmptyState: PreviewEmptyState }>()
);

export const unloadPreviews = createAction('[Ad Preview] Unload');

export const loadAllAdPreviewsComplete = createAction(
    '[Ad Preview] Load Complete',
    props<{ draftAdPreviews: IAdPreviewVM[]; publishedAdPreviews: IAdPreviewVM[] }>()
);

export type AdPreviewActions =
    | ReturnType<typeof setPreviewAdGroup>
    | ReturnType<typeof selectPreviewAd>
    | ReturnType<typeof loadAdAllPreviews>
    | ReturnType<typeof setPreviewViewTab>
    | ReturnType<typeof setPreviewViewIndex>
    | ReturnType<typeof setPreviewTime>
    | ReturnType<typeof setPreviewEmptyState>
    | ReturnType<typeof unloadPreviews>
    | ReturnType<typeof loadAllAdPreviewsComplete>;
