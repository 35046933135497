import { Injectable, inject } from '@angular/core';
import { showLeftPanel } from '@campaign/pages/editor/store/left-panel/left-panel.actions';
import {
    loadDraftCampaignSuccess,
    loadLiveCampaignSuccess
} from '@campaign/store/draft-campaign/draft-campaign.actions';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { CampaignApiService } from '@core/services/campaigns';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import { Store } from '@ngrx/store';
import { DraftCampaignUtilities, ICampaign, IDraftCampaign } from '@shared/models/campaigns';
import { ICreativeSet } from '@shared/models/studio';
import { lastValueFrom, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DraftCampaignService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private campaignApiService = inject(CampaignApiService);
    private cachedCreativesetService = inject(CachedCreativesetService);


    public async loadAllStates(campaign: ICampaign): Promise<void> {
        const draftCampaign: IDraftCampaign = await this.campaignApiService.getDraftCampaign(
            campaign.id
        );
        const liveCampaign: IDraftCampaign = await this.campaignApiService.getLiveCampaign(
            campaign.id
        );

        if (!draftCampaign) {
            if (campaign.ads.length === 0) {
                this.createEmptyDraftCampaignAndSave(campaign);
            } else {
                this.createDraftCampaignFromCampaign(campaign);
            }
        } else {
            this.store.dispatch(loadDraftCampaignSuccess({ draftCampaign }));
            this.store.dispatch(loadLiveCampaignSuccess({ liveCampaign }));
        }
    }

    public async createEmptyDraftCampaignAndSave(campaign: ICampaign): Promise<void> {
        let newDraftCampaign: IDraftCampaign = {
            id: campaign.id,
            decisionTrees: [],
            isDirty: true
        };

        newDraftCampaign = await this.campaignApiService.upsertDraftCampaign(newDraftCampaign);
        this.store.dispatch(loadDraftCampaignSuccess({ draftCampaign: newDraftCampaign }));
        // show creative set panel for user when campaign
        this.store.dispatch(showLeftPanel());
    }

    public async createDraftCampaignFromCampaign(campaign: ICampaign): Promise<void> {
        let newDraftCampaign: IDraftCampaign;

        const creativesetIds: string[] = campaign.ads
            .filter((ad) => ad.creativeRefs.length > 0)
            .map((ad) => ad.creativeRefs[0].creativeSetId);

        const creativesets: ICreativeSet[] = await lastValueFrom(
            this.cachedCreativesetService.getCreativesetsByIds(creativesetIds).pipe(take(1))
        );

        newDraftCampaign = DraftCampaignUtilities.createFromCampaign(campaign, creativesets);
        newDraftCampaign = await this.campaignApiService.upsertDraftCampaign(newDraftCampaign);

        this.store.dispatch(loadDraftCampaignSuccess({ draftCampaign: newDraftCampaign }));
    }
}
