import { createAction, props } from '@ngrx/store';
import {
    IAdPublishStatusUpdate,
    ICampaign,
    ICampaignPublishStatusUpdate
} from '@shared/models/campaigns';
import { ICampaignError } from '@shared/models/campaigns/validation';
import { IPublishReadyAd } from '@shared/models/publish/publish-ready-ad';

export const loadCampaign = createAction('[Campaign] Load', props<{ campaignId: string }>());

export const loadCampaignSuccess = createAction(
    '[Campaign] Load Success',
    props<{ campaign: ICampaign }>()
);

export const renameCampaign = createAction('[Campaign] Rename', props<{ campaignName: string }>());

export const updateCampaign = createAction('[Campaign] Update', props<{ campaign: ICampaign }>());

export const receivedAdPublishStatusesUpdate = createAction(
    '[Campaign] Received Ad Publish Statuses Update',
    props<{ adPublishStatuses: IAdPublishStatusUpdate[] }>()
);

export const receivedCampaignPublishStatusUpdate = createAction(
    '[Campaign] Received Campaign Publish Status Update',
    props<{ campaignPublishStatus: ICampaignPublishStatusUpdate }>()
);

export const initPublishCampaign = createAction(
    '[Campaign] Init Publish',
    props<{ filterSelectedAds?: boolean }>()
);

export const initPublishCampaignSuccess = createAction(
    '[Campaign] Init Publish Success',
    props<{ ads: IPublishReadyAd[] }>()
);

export const initPublishCampaignFailure = createAction(
    '[Campaign] Init Publish Failure',
    props<{ error: ICampaignError }>()
);

export const assignAdsPublishedToNonHeavyVideoSupport = createAction(
    '[Campaign] Assign Published To Non Heavy Video Support Ad Map',
    props<{ adsPublishedToNonHeavyVideoSupport: string[] }>()
);

export type CampaignActions =
    | ReturnType<typeof loadCampaign>
    | ReturnType<typeof loadCampaignSuccess>
    | ReturnType<typeof renameCampaign>
    | ReturnType<typeof updateCampaign>
    | ReturnType<typeof initPublishCampaign>
    | ReturnType<typeof initPublishCampaignSuccess>
    | ReturnType<typeof initPublishCampaignFailure>
    | ReturnType<typeof assignAdsPublishedToNonHeavyVideoSupport>
    | ReturnType<typeof receivedCampaignPublishStatusUpdate>
    | ReturnType<typeof receivedAdPublishStatusesUpdate>;
