import { Injectable, inject } from '@angular/core';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import {
    pushLiveDraftCampaignCancelled,
    pushLiveDraftCampaignFailure,
    pushLiveDraftCampaignSuccess
} from '@campaign/store/draft-campaign/draft-campaign.actions';
import {
    selectDraftCampaign,
    selectLiveCampaign
} from '@campaign/store/draft-campaign/draft-campaign.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import {
    checkIfAnyAdIsValidButUsesDefault,
    checkIfAnyAdIsValidButUsesFallback,
    createAdValidationMap,
    getAdValidationStatesAsArray
} from '@campaign/utilities/ad-validation.utils';
import { CampaignApiService, CampaignDialogService } from '@core/services/campaigns';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import { NotificationService } from '@core/services/internal/notification.service';
import { Store } from '@ngrx/store';
import {
    DraftCampaignUtilities,
    generateAutoOptRestartSummary,
    ICampaign,
    IDraftCampaign
} from '@shared/models/campaigns';
import {
    CampaignErrorReason,
    IAdValidationMap,
    IDraftCampaignValidationResult
} from '@shared/models/campaigns/validation';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { DraftCampaignValidationService } from './draft-campaign-validation.service';

@Injectable({ providedIn: 'root' })
export class DraftCampaignPushLiveService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private campaignApiService = inject(CampaignApiService);
    private draftCampaignValidation = inject(DraftCampaignValidationService);
    private campaignDialogService = inject(CampaignDialogService);
    private notificationService = inject(NotificationService);
    private cachedCreativesetService = inject(CachedCreativesetService);

    /**
     * Attempts to push the draft campaign to live and updates store by dispatching following actions
     *
     * Rejects promise with errors if the returnErrors is set to `false`
     *
     * @param draftCampaign
     * @param returnErrors
     */
    public async tryPushChanges(
        draftCampaign: IDraftCampaign,
        hideDialog?: boolean
    ): Promise<UIConfirmDialogResult> {
        const draftValidation: IDraftCampaignValidationResult =
            await this.draftCampaignValidation.canPushLive(draftCampaign);

        if (draftValidation.isValid) {
            const adValidationMap = createAdValidationMap(draftValidation.checkedTrees);
            const result: UIConfirmDialogResult = await this.confirmPushChanges(
                draftCampaign,
                adValidationMap,
                hideDialog
            );
            if (result === 'confirm') {
                // confirmed
                this.pushLive(draftCampaign, false, true);
            } else {
                this.store.dispatch(pushLiveDraftCampaignCancelled());
            }

            return result;
        }

        this.store.dispatch(pushLiveDraftCampaignFailure({ error: draftValidation.error }));

        return 'cancel';
    }

    public async handlePushBeforeExit(): Promise<UIConfirmDialogResult> {
        const draftCampaign: IDraftCampaign = await lastValueFrom(
            this.store.select(selectDraftCampaign).pipe(take(1))
        );

        // Show dialog, do you want to push your changes or just leave
        let result: UIConfirmDialogResult =
            await this.campaignDialogService.showDirtyNavigationDialog();

        if (result === 'confirm') {
            result = await this.tryPushChanges(draftCampaign, true);
        } else {
            this.store.dispatch(pushLiveDraftCampaignCancelled());
        }

        return result;
    }

    /**
     *
     * Updates the campaign and published ads with the changes from draft model.
     *
     * Success action:
     * ```
     * new ActionPushLiveDraftCampaignSuccess({ campaign })
     * ```
     * Failure action:
     * ```
     * new ActionPushLiveDraftCampaignFailure({ error })
     * ```
     * @param draftCampaign
     */
    public async pushLive(
        draftCampaign: IDraftCampaign,
        showNotification: boolean,
        dispatchErrors: boolean
    ): Promise<ICampaign> {
        // pushing to backend
        try {
            const campaign: ICampaign = await this.campaignApiService.pushDraftToLive(
                draftCampaign.id
            );
            this.store.dispatch(pushLiveDraftCampaignSuccess({ campaign }));

            if (!showNotification) {
                this.notificationService.showInformationNotification('All changes pushed live.');
            }

            return campaign;
        } catch (error) {
            if (dispatchErrors) {
                this.store.dispatch(
                    pushLiveDraftCampaignFailure({
                        error: { reason: CampaignErrorReason.ApiError, details: error }
                    })
                );
            }
        }
    }

    /**
     * Get confirmation from user to push changes
     *
     * @param draftCampaign
     * @param adValidationMap
     * @param hideDialog
     * @returns
     */
    private async confirmPushChanges(
        draftCampaign: IDraftCampaign,
        adValidationMap: IAdValidationMap,
        hideDialog?: boolean
    ): Promise<UIConfirmDialogResult> {
        const adValidationStates = getAdValidationStatesAsArray(adValidationMap);
        const usesDefault = checkIfAnyAdIsValidButUsesDefault(adValidationStates);
        const usesFallback = checkIfAnyAdIsValidButUsesFallback(adValidationStates);

        const { showRestartAutoOptModal, draftStates } = await lastValueFrom(
            this.draftCampaignValidation.autoOptimisationRestartState(draftCampaign)
        );

        const showPushChangesModal: boolean =
            !usesDefault && !usesFallback && !showRestartAutoOptModal && !hideDialog;

        // default result to confirm, so if no modals are valid to show. Just push changes.
        let result: UIConfirmDialogResult = 'confirm';

        if (usesDefault || usesFallback) {
            // Shows when you want to push, but if some creatives will just be covered by default or fallback
            result = await this.campaignDialogService.showContinueUsingFallbackAndOrDefaultDialog(
                usesDefault,
                usesFallback,
                true // Push changes mode
            );
        }

        if (result === 'cancel') {
            return result;
        }

        if (showRestartAutoOptModal) {
            const liveCampaign = await lastValueFrom(
                this.store.select(selectLiveCampaign).pipe(take(1))
            );
            const creativeSetsIds =
                DraftCampaignUtilities.getCreativeSetsIdsUsedInDraftCampaign(draftCampaign);

            const creativeSets = await lastValueFrom(
                this.cachedCreativesetService.getCreativesetsByIds(creativeSetsIds).pipe(take(1))
            );

            result = await this.campaignDialogService.showAutoOptimisationRestartDialog(
                generateAutoOptRestartSummary(
                    draftStates,
                    draftCampaign,
                    liveCampaign,
                    creativeSets
                )
            );
        }

        // this only runs if the above cases are not
        if (showPushChangesModal) {
            result = await this.campaignDialogService.showPushLiveConfirmDialog();
        }

        return result;
    }
}
