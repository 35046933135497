import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AdPreviewService } from '../ad-preview.service';
import { IAdPreviewVM, PreviewViewTab } from '../models/ad-preview-view-model';
import {
    loadAdAllPreviews,
    loadAllAdPreviewsComplete,
    selectPreviewAd,
    setPreviewEmptyState,
    setPreviewViewTab
} from './ad-preview.actions';
import {
    selectAdPreviewState,
    selectAdPreviews,
    selectCurrentAdGroupId,
    selectCurrentAdPreviewId,
    selectCurrentViewTab,
    selectSelectedPreviewTime
} from './ad-preview.selectors';
import { IAppStateWithAdPreview } from './ad-preview.state';

@Injectable()
export class AdPreviewEffects {
    private actions$ = inject(Actions);
    private store = inject<Store<IAppStateWithAdPreview>>(Store);
    private adPreviewService = inject(AdPreviewService);


    public loadAdPreviews$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAdAllPreviews),
            withLatestFrom(
                this.store.select(selectCurrentAdGroupId),
                this.store.select(selectSelectedPreviewTime)
            ),
            switchMap(([_action, adGroupId, { time, localTime }]) =>
                forkJoin([
                    this.adPreviewService.loadAdPreviewsForState(adGroupId, time, localTime, true),
                    this.adPreviewService.loadAdPreviewsForState(adGroupId, time, localTime, false)
                ])
            ),
            map(([draftAdPreviews, publishedAdPreviews]) =>
                loadAllAdPreviewsComplete({
                    draftAdPreviews,
                    publishedAdPreviews
                })
            )
        )
    );

    public selectDefaultAd$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAllAdPreviewsComplete),
            withLatestFrom(
                this.store.select(selectCurrentAdPreviewId),
                this.store.select(selectCurrentViewTab)
            ),
            map(([action, selectedPreviewId, currentViewTab]) => {
                const { draftAdPreviews, publishedAdPreviews } = action;

                if (!selectedPreviewId) {
                    selectedPreviewId =
                        currentViewTab === PreviewViewTab.Draft
                            ? draftAdPreviews[0].id
                            : publishedAdPreviews[0].id;
                }

                return selectPreviewAd({ adId: selectedPreviewId });
            })
        )
    );

    public setAdPreviewEmptyState$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(setPreviewViewTab, loadAllAdPreviewsComplete),
            withLatestFrom(
                this.store.select(selectCurrentViewTab),
                this.store.select(selectAdPreviews),
                this.store.select(selectAdPreviewState)
            ),
            switchMap(([_action, viewTab, adPreviews, previewState]) => {
                const allPreviews = [
                    ...previewState.draftAdPreviews,
                    ...previewState.publishedAdPreviews
                ];
                return this.adPreviewService.getPreviewEmptyState(allPreviews, adPreviews, viewTab);
            }),
            map((state) => setPreviewEmptyState({ previewEmptyState: state }))
        )
    );

    public selectFirstAdIfSelectionNotExists$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(setPreviewViewTab),
            withLatestFrom(
                this.store.select(selectCurrentAdPreviewId),
                this.store.select(selectAdPreviews)
            ),
            filter(
                ([_action, selectedPreviewId, adPreviews]) =>
                    !!selectedPreviewId && adPreviews?.length > 0
            ),
            map(([_action, selectedPreviewId, adPreviews]) => {
                const selectedAdPreview: IAdPreviewVM = adPreviews.find(
                    (adPreview) => adPreview.id === selectedPreviewId
                );

                if (!selectedAdPreview) {
                    selectedPreviewId = adPreviews[0].id;
                }

                return selectPreviewAd({ adId: selectedPreviewId });
            })
        )
    );
}
