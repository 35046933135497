import { Injectable, inject } from '@angular/core';
import { ITimeZone } from '@shared/models/campaigns/draft-campaign/nodes/schedule-node/timezone.model';
import { UserService } from '../bannerflow/user.service';

@Injectable({ providedIn: 'root' })
export class TimeZoneService {
    private userService = inject(UserService);

    private timeZones: ITimeZone[] = [];

    public setTimeZones(timeZones: ITimeZone[]): void {
        this.timeZones = timeZones;
    }

    public getTimeZones(): ITimeZone[] {
        return JSON.parse(JSON.stringify(this.timeZones));
    }

    public getTimeZone(timeZoneId: string): ITimeZone {
        const timeZone = this.timeZones.find((tz) => tz.id === timeZoneId);
        return timeZone;
    }

    public getSortedTimeZones(): ITimeZone[] {
        const timeZones = this.getTimeZones();
        timeZones.sort((timeZoneA, timeZoneB) => {
            if (timeZoneA.name.toLowerCase() > timeZoneB.name.toLowerCase()) {
                return 1;
            }

            if (timeZoneA.name.toLowerCase() < timeZoneB.name.toLowerCase()) {
                return -1;
            }

            return 0;
        });

        return timeZones;
    }

    public getDefaultTimeZone(): ITimeZone {
        const userDefaultTimeZoneId: string = this.userService.user.timezone;

        const userdefaultTimeZone: ITimeZone = this.timeZones.find(
            (timeZone) => timeZone.id === userDefaultTimeZoneId
        );

        if (userdefaultTimeZone) {
            return userdefaultTimeZone;
        }

        return this.timeZones.find((timeZone) => timeZone.id === 'UTC');
    }
}
