import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignErrorHandlerService } from '@campaign/services/campaign-error-handler.service';
import { CampaignPublishService } from '@campaign/services/campaign-publish.service';
import { getAdsPublishedToNonHeavyVideoSupport } from '@campaign/utilities/heavy-video.utils';
import { IAppState } from '@core/core.reducer';
import { CampaignApiService } from '@core/services/campaigns';
import { selectBrandPublishOptions } from '@core/store/brand.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ICampaignFolder } from '@shared/models/campaigns';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { loadDraftCampaign } from '../draft-campaign/draft-campaign.actions';
import {
    assignAdsPublishedToNonHeavyVideoSupport,
    initPublishCampaign,
    initPublishCampaignFailure,
    initPublishCampaignSuccess,
    loadCampaign,
    loadCampaignSuccess,
    receivedCampaignPublishStatusUpdate,
    renameCampaign,
    updateCampaign
} from './campaign.actions';
import { selectCampaign } from './campaign.selectors';

@Injectable()
export class CampaignEffects {
    private actions$ = inject(Actions);
    private store = inject<Store<IAppState>>(Store);
    private campaignService = inject(CampaignApiService);
    private campaignPublishService = inject(CampaignPublishService);
    private errorHandlerService = inject(CampaignErrorHandlerService);
    private router = inject(Router);


    public loadCampaign$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCampaign),
            switchMap((action) => this.campaignService.getCampaign(action.campaignId)),
            map((campaign) => loadCampaignSuccess({ campaign }))
        )
    );

    public loadCampaignSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadCampaignSuccess),
            map((action) => action.campaign),
            withLatestFrom(this.store.select(selectBrandPublishOptions)),
            switchMap(([campaign, publishOptions]) => {
                const adsPublishedToNonHeavyVideoSupport = getAdsPublishedToNonHeavyVideoSupport(
                    campaign.attempts,
                    publishOptions
                );
                return [
                    loadDraftCampaign({ campaign }),
                    assignAdsPublishedToNonHeavyVideoSupport({
                        adsPublishedToNonHeavyVideoSupport
                    })
                ];
            })
        )
    );

    public initPublish$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(initPublishCampaign),
            switchMap((action) =>
                this.campaignPublishService
                    .initPublish(action.filterSelectedAds)
                    .then((ads) => initPublishCampaignSuccess({ ads }))
                    .catch((error) => initPublishCampaignFailure({ error }))
            )
        )
    );

    public initPublishSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initPublishCampaignSuccess),
                tap((action) => {
                    const { ads } = action;
                    const publishRoute: string = this.campaignPublishService.getPublishRoute();

                    if (ads) {
                        // navigates to publish
                        this.router.navigate([publishRoute], {
                            queryParams: {
                                campaignId: ads[0].campaignId
                            },
                            queryParamsHandling: 'merge',
                            state: {
                                ads: JSON.parse(JSON.stringify(ads))
                            }
                        });
                    }
                })
            ),
        { dispatch: false }
    );

    public initPublishFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(initPublishCampaignFailure),
                tap((action) => {
                    if (action.error) {
                        this.errorHandlerService.handlePublishErrors(action.error);
                    }
                })
            ),
        { dispatch: false }
    );

    public renameCampaign$ = createEffect(() =>
        this.actions$.pipe(
            ofType(renameCampaign),
            withLatestFrom(this.store.select(selectCampaign)),
            switchMap(async ([action, campaign]) => {
                const { campaignName } = action;

                // get campaignFolder required for renaming
                const campaignFolder: Promise<ICampaignFolder> =
                    this.campaignService.getCampaignFolder(campaign.id);

                return this.campaignService.renameCampaign(
                    campaign.id,
                    campaignName,
                    await campaignFolder
                );
            }),
            map((campaign) => updateCampaign({ campaign }))
        )
    );

    public updateCampaignAfterStatusUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(receivedCampaignPublishStatusUpdate),
            distinctUntilChanged(
                (prev, current) => JSON.stringify(prev) === JSON.stringify(current)
            ),
            withLatestFrom(this.store.select(selectCampaign)),
            map(
                ([_action, campaign]) => loadCampaign({ campaignId: campaign.id }) // to refresh campaign attempts array
            )
        )
    );
}
