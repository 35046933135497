import { ITimeZone, ITimeZoneDto } from '@shared/models/campaigns';

export function mapTimeZone(timeZone: ITimeZoneDto): ITimeZone {
    const absoluteOffset = Math.abs(timeZone.offset);
    const offsetHours = Math.floor(absoluteOffset / 60)
        .toString()
        .padStart(2, '0');
    const offsetMinutes = (absoluteOffset % 60).toString().padStart(2, '0');
    const isPositive = timeZone.offset >= 0;

    const utcOffset = `UTC${isPositive ? '+' : '-'}${offsetHours}:${offsetMinutes}`;

    return {
        ...timeZone,
        utcOffset,
        name: timeZone.displayName.slice(timeZone.displayName.indexOf(')') + 2)
    };
}
