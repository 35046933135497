import { Injectable, inject } from '@angular/core';
import { AssignedCreativeChangeDetectionService } from '@campaign/services/assigned-creative-change-detection.service';
import { CampaignErrorHandlerService } from '@campaign/services/campaign-error-handler.service';
import { DraftCampaignPushLiveService } from '@campaign/services/draft-campaign-push-live.service';
import { DraftCampaignService } from '@campaign/services/draft-campaign.service';
import { IAppState } from '@core/core.reducer';
import { CampaignApiService } from '@core/services/campaigns';
import { NotificationService } from '@core/services/internal/notification.service';
import { selectBrandLocalizations } from '@core/store/brand.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
    AdUtilities,
    CampaignErrorReason,
    IAdGroup,
    IDraftAd,
    IDraftCampaign
} from '@shared/models/campaigns';
import { ILocalization } from '@shared/models/common';
import { Observable } from 'rxjs';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { loadCampaign } from '../campaign/campaign.actions';
import { selectDraftCampaignState } from '../state';
import * as draftCampaignActions from './draft-campaign.actions';
import { selectDraftCampaign } from './draft-campaign.selectors';

@Injectable()
export class DraftCampaignEffects {
    private actions$ = inject(Actions);
    private store = inject<Store<IAppState>>(Store);
    private campaignService = inject(CampaignApiService);
    private draftCampaignService = inject(DraftCampaignService);
    private draftPushLiveService = inject(DraftCampaignPushLiveService);
    private campaignErrorHandler = inject(CampaignErrorHandlerService);
    private assignedCreativeChangeDetectionService = inject(AssignedCreativeChangeDetectionService);
    private notificationService = inject(NotificationService);


    public loadDraftCampaign$: Observable<any> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.loadDraftCampaign),
                switchMap((action) => this.draftCampaignService.loadAllStates(action.campaign))
            ),
        { dispatch: false }
    );

    public loadOnlyLiveCampaign$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.loadLiveCampaign),
            switchMap((action) => this.campaignService.getLiveCampaign(action.campaignId)),
            map((liveCampaign) => draftCampaignActions.loadLiveCampaignSuccess({ liveCampaign }))
        )
    );

    public saveOnUpdate$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.updateDraftCampaign),
            map(() => draftCampaignActions.saveDraftCampaign())
        )
    );

    public save$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.saveDraftCampaign),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            concatMap(([_action, draft]) =>
                this.campaignService
                    .upsertDraftCampaign(draft)
                    .then((result) =>
                        draftCampaignActions.saveDraftCampaignSuccess({ draftCampaign: result })
                    )
                    .catch((error) =>
                        draftCampaignActions.saveDraftCampaignFailure({
                            error: {
                                reason: CampaignErrorReason.DraftSaveFailure,
                                details: error
                            }
                        })
                    )
            )
        )
    );

    public saveFailureRetryPrompt$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.saveDraftCampaignFailure),
                map((action) => this.campaignErrorHandler.handleSaveErrors(action.error))
            ),
        { dispatch: false }
    );

    public undo$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.undoDraftCampaign),
            withLatestFrom(this.store.select(selectDraftCampaignState)),
            map(([_action, state]) => {
                const { undoHistory } = state;

                if (undoHistory.length === 0) {
                    return undefined;
                }

                const previous: IDraftCampaign = undoHistory[undoHistory.length - 1];
                const newPast: IDraftCampaign[] = undoHistory.slice(0, undoHistory.length - 1);

                return {
                    draftCampaign: previous,
                    undoHistory: newPast
                };
            }),
            filter((state) => state !== undefined),
            switchMap((state) => [
                draftCampaignActions.updateDraftCampaign({
                    changes: {
                        ...state.draftCampaign
                    }
                }),
                draftCampaignActions.setDraftCampaignHistory({ history: state.undoHistory })
            ])
        )
    );

    public pushLive$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.pushLiveDraftCampaign),
                withLatestFrom(this.store.select(selectDraftCampaign)),
                switchMap(([action, draft]) =>
                    this.draftPushLiveService.tryPushChanges(draft, action.hideDialog)
                )
            ),
        { dispatch: false }
    );

    public pushLiveSuccess$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.pushLiveDraftCampaignSuccess),
            map((action) => action),
            switchMap(({ campaign }) => [
                loadCampaign({ campaignId: campaign.id }),
                draftCampaignActions.loadLiveCampaign({ campaignId: campaign.id })
            ])
        )
    );

    public pushLiveFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.pushLiveDraftCampaignFailure),
                tap((action) => this.campaignErrorHandler.handlePushLiveErrors(action.error))
            ),
        { dispatch: false }
    );

    public discardChanges$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.discardDraftCampaign),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            switchMap(([_action, draft]) => this.campaignService.discardChanges(draft.id)),
            map((lastPublishedState) =>
                draftCampaignActions.discardDraftCampaignSuccess({
                    draftCampaign: lastPublishedState
                })
            )
        )
    );

    public sortAdsAfterDraftUpdates$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(
                draftCampaignActions.loadDraftCampaignSuccess,
                draftCampaignActions.saveDraftCampaignSuccess,
                draftCampaignActions.discardDraftCampaignSuccess
            ),
            withLatestFrom(this.store.select(selectBrandLocalizations)),
            map(([action, languages]) => {
                const { draftCampaign } = action;

                const sortedDraftCampaign: IDraftCampaign = {
                    ...draftCampaign,
                    decisionTrees: draftCampaign.decisionTrees.map((tree) => ({
                        ...tree,
                        adGroup: {
                            ...tree.adGroup,
                            ads: this.sortAdGroupAds(tree.adGroup, languages)
                        }
                    }))
                };

                return sortedDraftCampaign;
            }),
            map((draftCampaign) => draftCampaignActions.sortAllDraftCampaignAds({ draftCampaign }))
        )
    );

    private sortAdGroupAds(adGroup: IAdGroup, localizations: ILocalization[]): IDraftAd[] {
        return adGroup.ads
            .map((ad) => AdUtilities.mapLanguageToAd(ad, localizations))
            .sort(AdUtilities.sortByLanguageAndSize) as IDraftAd[];
    }

    public detectCreativeSetChanges$: Observable<any> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.loadDraftCampaignSuccess),
                tap((action) => {
                    const { draftCampaign } = action;
                    this.assignedCreativeChangeDetectionService.detectStudioChanges(draftCampaign);
                })
            ),
        {
            dispatch: false
        }
    );

    public pushChangesCancelled$: Observable<any> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(draftCampaignActions.pushLiveDraftCampaignCancelled),
                tap(() => {
                    this.notificationService.showInformationNotification('No changes pushed live.');
                })
            ),
        {
            dispatch: false
        }
    );

    public updateAndPushLive$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(draftCampaignActions.updateAndPushDraftCampaign),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            switchMap(([_action, draft]) =>
                this.campaignService
                    .upsertDraftCampaign(draft)
                    .then((result) =>
                        draftCampaignActions.saveDraftCampaignSuccess({ draftCampaign: result })
                    )
                    .catch((error) =>
                        draftCampaignActions.saveDraftCampaignFailure({
                            error: {
                                reason: CampaignErrorReason.DraftSaveFailure,
                                details: error
                            }
                        })
                    )
            ),
            map(() => draftCampaignActions.pushLiveDraftCampaign({ hideDialog: true }))
        )
    );
}
