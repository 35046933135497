import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { IAppState } from '@core/core.reducer';
import { Store } from '@ngrx/store';
import { ICampaign } from '@shared/models/campaigns';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { loadCampaign } from './store/campaign/campaign.actions';
import { selectCampaign } from './store/campaign/campaign.selectors';

@Injectable({ providedIn: 'root' })
export class CampaignPageResolver implements Resolve<ICampaign> {
    private store = inject<Store<IAppState>>(Store);


    public resolve(route: ActivatedRouteSnapshot): Observable<ICampaign> {
        const campaignId: string = route.paramMap.get('campaignId');

        return of(campaignId).pipe(
            tap((id) => this.store.dispatch(loadCampaign({ campaignId: id }))),
            switchMap(() => this.store.select(selectCampaign)),
            filter(Boolean),
            take(1)
        );
    }
}
