import { Injectable } from '@angular/core';
import {
    UIAlertDialogService,
    UIConfirmDialogResult,
    UIConfirmDialogService
} from '@bannerflow/ui';

export interface ICampaignConfirmDialogConfig {
    hasPublished?: boolean;
    hasMultipleAds?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CampaignDialogService {
    constructor(
        private confirmDialogService: UIConfirmDialogService,
        private alertDialogService: UIAlertDialogService
    ) {}

    private publishProblemSupportLink =
        'Read more&nbsp;<a href="https://support.bannerflow.com/en/articles/6364783-problems-with-publishing-here-s-what-to-look-for#:~:text=Invalid%20ads%20%2D%20Missing/inactivated%20size" target="_blank">here</a>.';

    private warningDialogTemplate(warningText: string, contentText: string): string {
        return `<div class="warning">
                    <div class="warning-icon"></div>${warningText}
                </div>
                <div class='content'>${contentText}</div>`;
    }

    private unknownErrorTemplate(errorText: string): string {
        return `<div>${errorText}<br /><br />
        If you want to get in touch with us please send an email to <a href="mailto:support@bannerflow.com">support@bannerflow.com</a>.</div>`;
    }

    public showRemoveAdConfirmDialog(
        config: ICampaignConfirmDialogConfig
    ): Promise<UIConfirmDialogResult> {
        const publishedText: string = this.warningDialogTemplate(
            'This ad is published. Deleting it will, when the changes are pushed, show blank content to visitors at the publish destination if it is active.',
            'Are you sure that you want to delete the ad?'
        );

        const multiplePublishedText: string = this.warningDialogTemplate(
            'Some of the selected ads are published. Deleting published ads will, when the changes are pushed, show blank content to visitors at the publish destination if they are active.',
            'Are you sure that you want to delete these ads?'
        );

        return this.confirmDialogService.confirm({
            headerText: config.hasMultipleAds ? 'Delete Ads' : 'Delete Ad',
            text: config.hasMultipleAds ? multiplePublishedText : publishedText,
            confirmText: 'Delete',
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showRemoveAdsByLanguageConfirmDialog(
        language: string,
        adGroup: string
    ): Promise<UIConfirmDialogResult> {
        const text = this.warningDialogTemplate(
            `Some of ${language} ads in ${adGroup} are published. 
            Deleting them will, when the changes are pushed, 
            show blank content to visitors at the publish destination if it is active.`,
            `Are you sure that you want to delete all ${language} ads?`
        );

        return this.confirmDialogService.confirm({
            headerText: `Delete ${language} ads`,
            text,
            confirmText: 'Delete',
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showRemoveAdGroupDialog(): Promise<UIConfirmDialogResult> {
        const publishedText: string = this.warningDialogTemplate(
            'The ad group contains published ads. Deleting the ads will show blank content to visitors at the publish destination if the ads are still active.',
            'Are you sure that you want to delete the ad group?'
        );

        return this.confirmDialogService.confirm({
            headerText: 'Delete Ad Group',
            text: publishedText,
            confirmText: 'Delete',
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showDraftIsDirtyDialog(): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: `Can't publish until changes are pushed`,
            text: 'There are changes made to your ads that need to be pushed before you can publish to new publish options.',
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showMultipleChoicesDialog(isPushChanges?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isPushChanges ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                'Some of the ads have no assigned creatives due to multiple choices available.',
                'All ads needs to have an creative assigned. Please make sure you have selected a version and creative for all your ads in all ad groups.'
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showAdsAreInvalidDialog(isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                'Some ads have assigned creatives which are not valid and are not covered by the fallback set.',
                `<span>Please make sure all ads have valid creatives assigned or that the ad is covered by the fallback creative set. 
                ${this.publishProblemSupportLink}</span>`
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showScheduleAdsAreInvalidDialog(isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                'Some ads have assigned creatives which are not valid or are not covered by the fallback set or default set.',
                `<span>Please make sure all ads have valid creatives assigned and that the ad is covered by either the fallback creative set or the default creative set.
                ${this.publishProblemSupportLink}</span>`
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showAONodeIsMissingCreativeSetDialog(isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                'The campaign contain a test that has at least one variant without a creative set assigned.',
                'Assign creative sets to all variants and try again.  '
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showAOAdIsMissingDesignDialog(isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                'Some ads have assigned creatives which are not valid. Since auto-optimisation is used the fallback creative set cannot cover.',
                `Please make sure all ads have a valid creatives assigned. 
                ${this.publishProblemSupportLink}</span>`
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showNoAdsDialog(isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: 'There are no ads in the campaign. Create new ads and assign creatives.',
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showPushLiveConfirmDialog(): Promise<UIConfirmDialogResult> {
        return this.confirmDialogService.confirm({
            headerText: 'Push Changes',
            text: 'Do you want to push your campaign changes?',
            confirmText: 'Push Changes',
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showNoRulesAvailableDialog(): Promise<UIConfirmDialogResult> {
        return this.confirmDialogService.confirm({
            headerText: 'Enable rules and rotation',
            text: `
            <div>Do you want to boost performance with automated testing or always be relevant by making ad content dynamic?
            <br />
            Contact your customer success manager to find out more!</div>`,
            confirmText: 'Reach out',
            showCancelButton: false,
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showAutoOptimisationRestartDialog(
        restartReasons: string[]
    ): Promise<UIConfirmDialogResult> {
        const htmlReasons = restartReasons.map((reason: string) => `${reason}<br>`).join('<br>');

        return this.confirmDialogService.confirm({
            headerText: 'Push changes and restart optimisation',
            text: `
                <b>The following elements have been changed in the campaign:</b><br>
                ${htmlReasons}
                Pushing these changes will demand the auto-optimisation to restart in order to provide valid conclusions. 
                Do you want to push these changes and restart the test?
            `,
            confirmText: 'Push changes',
            showCancelButton: true,
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showAOIsUsingSameCreativeDialog(error: string, isDraft?: boolean): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: isDraft ? `Can't push changes` : `Can't publish`,
            text: this.warningDialogTemplate(
                error,
                'Please make sure each variant has unique content assigned'
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showUnknownError(): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: 'Something went wrong',
            text: this.unknownErrorTemplate(
                'We’re sorry, it seems like an error has occurred on our client. Our team has already been notified about the error.'
            ),
            confirmText: 'Ok',
            maxWidth: 600,
            theme: 'default'
        });
    }

    public showContinueUsingFallbackAndOrDefaultDialog(
        useDefault: boolean,
        useFallback: boolean,
        isPushLive?: boolean
    ): Promise<UIConfirmDialogResult> {
        const baseText = 'Some ads have assigned creatives which are not valid.';
        const action: string = isPushLive ? 'push changes' : 'publish';
        // FALLBACK ONLY
        let headerText = `${action} using fallback`;
        let details = `The fallback creative set will be used to cover those ads.<br /><br />
        Do you want to ${action} using fallback?`;
        // DEFAULT ONLY
        if (useDefault && !useFallback) {
            headerText = `${action} using default`;
            details = `The default creative set will be used to cover those ads.<br /><br />
                    Do you want to ${action} using default?`;
        }
        // FALLBACK AND DEFAULT
        else if (useFallback && useDefault) {
            headerText = `${action} using the fallback/default`;
            details = `Either the fallback creative set or the default creative set will be used to cover those ads.<br /><br />
            Do you want to ${action} using the fallback/default?`;
        }

        return this.confirmDialogService.confirm({
            headerText,
            text: `${baseText} ${details}`,
            confirmText: `${action}`,
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showDirtyNavigationDialog(): Promise<UIConfirmDialogResult> {
        return this.confirmDialogService.confirm({
            headerText: `Leave without pushing changes?`,
            text: 'Your changes are saved but does not affect the live campaign yet.',
            confirmText: `Push changes`,
            discardText: `Leave campaign as draft`,
            maxWidth: 600,
            showCancelButton: false,
            theme: 'default'
        });
    }

    public showDraftCampaignSaveFailureDialog(): Promise<UIConfirmDialogResult> {
        return this.confirmDialogService.confirm({
            headerText: `Auto saving failed`,
            text: 'Something went wrong and we couldn’t automatically save your work.',
            confirmText: `Try again`,
            discardText: `Contact support`,
            maxWidth: 600,
            showCancelButton: false,
            theme: 'default'
        });
    }

    public showStudioChangesDetectedDialog(): Promise<UIConfirmDialogResult> {
        return this.confirmDialogService.confirm({
            headerText: `Campaign updated`,
            text: `This campaign was changed and a new draft version of this campaign was created due to an assigned creative set was updated in Creative Studio. Push changes in order for these changes to go live.`,
            confirmText: `Push changes`,
            maxWidth: 600,
            showCancelButton: true,
            theme: 'default'
        });
    }

    public showDuplicatedAdGroupNamesDialog(): Promise<boolean> {
        return this.alertDialogService.show({
            headerText: `Duplicated ad group names`,
            text: 'Make sure every ad group has a unique name.',
            confirmText: `OK`,
            maxWidth: 600,
            theme: 'default'
        });
    }
}
