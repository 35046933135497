import { inject, Injectable } from '@angular/core';
import { ICreativeGeneratedInfo } from '@shared/models/campaigns/campaign/creative-info';
import { ICreativeSet } from '@shared/models/studio';
import { CreativeVMFactory, ICreativeSetVM } from '@shared/models/studio/creative-view-models';
import { BehaviorSubject, forkJoin, from, Observable, of, take, timeout } from 'rxjs';
import {
    bufferTime,
    catchError,
    distinctUntilChanged,
    filter,
    map,
    switchMap
} from 'rxjs/operators';
import { CampaignApiService } from '../campaigns';
import { StudioApiService } from '../studio/studio-api.service';

interface ICachedItem<T> {
    timestamp: number;
    data: T;
}

/**
 * NOTE:
 * After implementing WW-2626 we have some major changes in this service,
 * now it has dependency to campaign api as well as studio api.
 * Users expect to see their studio creative changes in campaign manager
 * only when they `Push Changes` in studio, we are able to do this only if there is a `generated creative`.
 *
 * Behavioral changes:
 * * Fetch the creatives as before when creative isn't generated using studio api
 * * Fetch the generated creatives when it exist using campaign api
 * * All creatives displayed in the campaign manager should follow this (including thumbnails)
 *
 * @link https://bannerflow.atlassian.net/browse/WW-2626
 * @requires CampaignApiService
 * @requires StudioApiService
 */
@Injectable({ providedIn: 'root' })
export class CachedCreativesetService {
    private readonly studioApiService = inject(StudioApiService);
    private readonly campaignApiService = inject(CampaignApiService);
    private readonly MAX_TTL: number = 1000 * 60 * 10;
    private readonly BUFFER_SPAN: number = 400;
    private readonly cachedItems$: BehaviorSubject<Map<string, ICachedItem<ICreativeSetVM>>> =
        new BehaviorSubject(new Map());
    private readonly requestQueue$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    constructor() {
        this.processQueue();
    }

    // WARNING!!!!!!!!!!!!!!!
    // If there are requests in progress, cachedItems observable counldn't get them, we can add delay, or refactor.

    public getCreativesetById(id: string): Observable<ICreativeSetVM> {
        if (!id) {
            return of(null);
        }

        const cachedItem = this.cachedItems$.getValue().get(id);
        if (cachedItem && !this.isExpired(id)) {
            return of(cachedItem.data);
        }

        this.addToQueue([id]);

        return this.cachedItems$.asObservable().pipe(
            map((items) => items.get(id)),
            filter((item) => !!item),
            map((item) => item.data),
            take(1),
            timeout(10000),
            catchError((error) => {
                console.error('Error fetching creative set:', error);
                throw error;
            })
        );
    }
    public getCreativesetsByIds(ids: string[]): Observable<ICreativeSetVM[]> {
        if (!ids?.length) {
            return of([]);
        }

        const validIds = ids.filter((id) => !!id);
        if (!validIds.length) {
            return of([]);
        }

        const cachedItems = this.cachedItems$.getValue();
        const validCachedItems = validIds
            .map((id) => cachedItems.get(id))
            .filter((item) => item && !this.isExpired(item.data.id))
            .map((item) => item.data);

        if (validCachedItems.length === validIds.length) {
            return of(validCachedItems);
        }

        const missingIds = validIds.filter(
            (id) => !validCachedItems.some((item) => item.id === id) || this.isExpired(id)
        );
        this.addToQueue(missingIds);

        return this.cachedItems$.asObservable().pipe(
            map((items) =>
                validIds
                    .map((id) => items.get(id))
                    .filter((item) => !!item)
                    .map((item) => item.data)
            ),
            filter((results) => results.length === validIds.length),
            take(1),
            catchError(() => {
                throw new Error('Failed to load creative sets');
            })
        );
    }

    private addToQueue(ids: string[]): void {
        const currentQueue: string[] = this.requestQueue$.getValue();

        // filters requests that are already in queue
        ids = ids.filter((id) => !currentQueue.includes(id));
        // filters requests that are already in cache
        ids = ids.filter((id) => this.isExpired(id));

        this.requestQueue$.next([...this.requestQueue$.getValue(), ...ids]);
    }

    private removeFromQueue(ids: string[]): void {
        this.requestQueue$.next(this.requestQueue$.getValue().filter((id) => !ids.includes(id)));
    }

    /**
     * A buffered queue processor for gathering requests in a time window for bulk execution. The flow:
     *
     * * Merges requested creative set ids for 400ms (BUFFER_SPAN),
     * * Filters out already cached ids
     * * Executes requests in bulk for studio api and campaign api
     * * Maps data to a result object
     * * Caches the result
     * * Removes the requested ids from queue
     *
     * @see rxjs
     */
    private processQueue(): void {
        this.requestQueue$
            .pipe(
                bufferTime(this.BUFFER_SPAN),
                map((mergedIds) => Array.from(new Set(mergedIds.flat()))),
                filter((ids) => ids.length > 0),
                switchMap((ids) =>
                    forkJoin([
                        this.studioApiService.getCreativeSetsByIds(ids),
                        from(this.campaignApiService.getCreativesetInfos(ids))
                    ]).pipe(
                        catchError((error) => {
                            this.removeFromQueue(ids);
                            throw error;
                        })
                    )
                ),
                map(([studioCreativeSet, generatedCreatives]) =>
                    this.addGeneratedData(studioCreativeSet, generatedCreatives)
                ),
                distinctUntilChanged()
            )
            .subscribe({
                next: (results) => {
                    this.addToCache(results);
                    this.removeFromQueue(results.map((result) => result.id));
                },
                error: (error) => {
                    console.error('Error in queue processing:', error);
                    const currentQueue = this.requestQueue$.getValue();
                    if (currentQueue.length > 0) {
                        currentQueue.forEach((id) => {
                            const errorItem: ICachedItem<ICreativeSetVM> = {
                                timestamp: Date.now(),
                                data: null
                            };
                            const items = this.cachedItems$.getValue();
                            items.set(id, errorItem);
                            this.cachedItems$.next(items);
                        });
                        this.removeFromQueue(currentQueue);
                    }
                }
            });
    }

    private addGeneratedData(
        creativesets: ICreativeSet[],
        creativeGeneratedInfo: ICreativeGeneratedInfo[]
    ): ICreativeSetVM[] {
        if (!creativesets) {
            throw new Error('Creativeset not found');
        }
        return creativesets.map((cs) =>
            CreativeVMFactory.createCreativesetVM(cs, creativeGeneratedInfo)
        );
    }

    private addToCache(creativesets: ICreativeSetVM[]): void {
        const cachedItems: Map<string, ICachedItem<ICreativeSetVM>> = this.cachedItems$.getValue();
        creativesets.forEach((cs) =>
            cachedItems.set(cs.id, { timestamp: new Date().getTime(), data: cs })
        );

        this.cachedItems$.next(cachedItems);
    }

    public isExpired(key: string): boolean {
        const cachedItem: ICachedItem<ICreativeSetVM> = this.cachedItems$.getValue().get(key);

        return cachedItem === undefined ? true : Date.now() - cachedItem.timestamp > this.MAX_TTL;
    }
}
