import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiHelperService } from '@core/services/internal/api-helper.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    private readonly apiHelper = inject(ApiHelperService);


    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            filter((event) => event instanceof HttpResponse),
            catchError((error: HttpErrorResponse) => {
                this.apiHelper.catchError(error, request);

                return throwError(() => error);
            })
        );
    }
}
