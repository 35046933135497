import { inject, Injectable } from '@angular/core';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import { selectSelectedAdsIds } from '@campaign/pages/list/store/ad-list.selectors';
import { selectCampaign } from '@campaign/store/campaign/campaign.selectors';
import { selectDraftCampaign } from '@campaign/store/draft-campaign/draft-campaign.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import {
    checkIfAnyAdIsValidButUsesDefault,
    checkIfAnyAdIsValidButUsesFallback
} from '@campaign/utilities/ad-validation.utils';
import { UserService } from '@core/services/bannerflow/user.service';
import { CampaignDialogService } from '@core/services/campaigns';
import { Store } from '@ngrx/store';
import { IAd, ICampaign, IDraftCampaign } from '@shared/models/campaigns';
import {
    CampaignErrorReason,
    ICampaignValidationResult,
    IDraftCampaignValidationResult
} from '@shared/models/campaigns/validation';
import { IPublishReadyAd } from '@shared/models/publish/publish-ready-ad';
import { lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CampaignValidationService } from './campaign-validation.service';
import { DraftCampaignPushLiveService } from './draft-campaign-push-live.service';
import { DraftCampaignValidationService } from './draft-campaign-validation.service';
import { PublishAdPrepareService } from './publish-ad-prepare.service';

@Injectable({ providedIn: 'root' })
export class CampaignPublishService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private campaignValidation = inject(CampaignValidationService);
    private draftCampaignValidation = inject(DraftCampaignValidationService);
    private pushLiveService = inject(DraftCampaignPushLiveService);
    private userService = inject(UserService);
    private campaignDialogService = inject(CampaignDialogService);
    private publishAdPrepareService = inject(PublishAdPrepareService);

    public getPublishRoute(): string {
        return `./${this.userService.accountSlug}/${this.userService.brandSlug}/publish`;
    }

    public async initPublish(filterSelectedAds?: boolean): Promise<IPublishReadyAd[]> {
        let campaign: ICampaign = await this.getCampaign();

        // if the campaign is never published attempt push live
        if (campaign.attempts.length === 0) {
            campaign = await this.handleFirstPublishPush(campaign);
        }

        const ads: IAd[] = filterSelectedAds ? await this.getSelectedAds(campaign) : campaign.ads;
        const campaignValidation: ICampaignValidationResult = await lastValueFrom(
            this.campaignValidation.canPublish(ads).pipe(take(1))
        );

        if (!campaignValidation.isValid) {
            // reject publish
            return Promise.reject(campaignValidation.error);
        }

        const useFallback = checkIfAnyAdIsValidButUsesFallback(
            campaignValidation.adValidationStates
        );
        const useDefault = checkIfAnyAdIsValidButUsesDefault(campaignValidation.adValidationStates);

        if (useFallback || useDefault) {
            // show warning dialog

            const result: UIConfirmDialogResult =
                await this.campaignDialogService.showContinueUsingFallbackAndOrDefaultDialog(
                    useDefault,
                    useFallback
                );

            if (result === 'confirm') {
                // user confirmed, preparing ads for publish
                return this.publishAdPrepareService.prepareAds(ads);
            }
            return Promise.reject();
        }

        // everything is cool! prepare campaign model for publish
        return this.publishAdPrepareService.prepareAds(ads);
    }

    /**
     * Doing a secret push live before first publish. Then question why we even exist, then cry silently.
     * @param draftCampaign
     */
    private async handleFirstPublishPush(campaign: ICampaign): Promise<ICampaign> {
        const draft: IDraftCampaign = await lastValueFrom(
            this.store.select(selectDraftCampaign).pipe(take(1))
        );

        const draftValidation: IDraftCampaignValidationResult =
            await this.draftCampaignValidation.canPushLive(draft);

        if (draftValidation.isValid) {
            // update campaign
            return this.pushLiveService.pushLive(draft, true, false);
        } else if (draftValidation.error.reason === CampaignErrorReason.DraftHasNoNewChanges) {
            // nothing changed continue publishing with campaign
            return campaign;
        } else {
            // error
            return Promise.reject(draftValidation.error);
        }
    }

    private getCampaign(): Promise<ICampaign> {
        return lastValueFrom(this.store.select(selectCampaign).pipe(take(1)));
    }

    private getSelectedAds(campaign: ICampaign): Promise<IAd[]> {
        return lastValueFrom(
            this.store.select(selectSelectedAdsIds).pipe(
                take(1),
                map((ids) => campaign.ads.filter((ad) => ids.includes(ad.id)))
            )
        );
    }
}
