import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { selectCreativeNode } from '../creative-node/creative-node.actions';
import { IAppStateWithEditor } from '../editor.state';
import { setLeftPanelWidth, showLeftPanel, togglePinLeftPanel } from './left-panel.actions';
import { LEFT_PANEL_IS_PINNED_KEY, LEFT_PANEL_WIDTH_STORAGE_KEY } from './left-panel.reducers';
import { selectLeftPanelPinned } from './left-panel.selectors';

@Injectable()
export class LeftPanelEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject<Store<IAppStateWithEditor>>(Store);


    public showPanelsOnSelectCreativeNode$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCreativeNode),
            filter((action) => !action.payload.creativesetId),
            map(() => showLeftPanel())
        )
    );

    public setLocalStorageOnLeftPanelPin$: Observable<void> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(togglePinLeftPanel),
                withLatestFrom(this.store.select(selectLeftPanelPinned)),
                map(([_action, isPinned]) => {
                    if (isPinned) {
                        window.localStorage.setItem(LEFT_PANEL_IS_PINNED_KEY, 'true');
                    } else {
                        window.localStorage.removeItem(LEFT_PANEL_IS_PINNED_KEY);
                    }
                })
            ),
        {
            dispatch: false
        }
    );

    public setLeftPanelWidth$: Observable<void> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setLeftPanelWidth),
                map((action) => action.width),
                map((width) => {
                    localStorage.setItem(LEFT_PANEL_WIDTH_STORAGE_KEY, width.toString());
                })
            ),
        {
            dispatch: false
        }
    );
}
