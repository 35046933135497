import {
    LeftPanelActions,
    showLeftPanel,
    hideLeftPanel,
    toggleLeftPanel,
    togglePinLeftPanel,
    setLeftPanelResizing,
    setLeftPanelWidth
} from './left-panel.actions';

export const LEFT_PANEL_WIDTH_STORAGE_KEY = 'leftPanelWidth';
export const LEFT_PANEL_IS_PINNED_KEY = 'isCreativeSetPanelPinned';
export const LEFT_PANEL_MAX_WIDTH = 300;
export const LEFT_PANEL_MIN_WIDTH = 100;
const LEFT_PANEL_DEFAULT_WIDTH = 300;

export interface ILeftPanelState {
    show: boolean;
    pinned: boolean;
    isResizing: boolean;
    width: number;
}

const isPinned = !!window.localStorage.getItem(LEFT_PANEL_IS_PINNED_KEY);

const storedWidth = +localStorage.getItem(LEFT_PANEL_WIDTH_STORAGE_KEY);
const isStoredWidthValid =
    storedWidth && storedWidth > LEFT_PANEL_MIN_WIDTH && storedWidth < LEFT_PANEL_MAX_WIDTH;
const width = isStoredWidthValid ? storedWidth : LEFT_PANEL_DEFAULT_WIDTH;

const leftPanelInitialState: ILeftPanelState = {
    show: isPinned,
    pinned: isPinned,
    isResizing: false,
    width
};

export function leftPanelReducer(
    state: ILeftPanelState = leftPanelInitialState,
    action: LeftPanelActions
): ILeftPanelState {
    switch (action.type) {
        case showLeftPanel.type:
            return {
                ...state,
                show: true
            };
        case hideLeftPanel.type:
            return state.pinned
                ? state
                : {
                      ...state,
                      show: false
                  };

        case toggleLeftPanel.type:
            return state.pinned
                ? state
                : {
                      ...state,
                      show: !state.show
                  };

        case togglePinLeftPanel.type:
            return {
                ...state,
                pinned: !state.pinned
            };

        case setLeftPanelResizing.type:
            return {
                ...state,
                isResizing: action.isResizing
            };

        case setLeftPanelWidth.type:
            return {
                ...state,
                width: action.width
            };

        default:
            return state;
    }
}
