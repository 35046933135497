import { NodeType } from '../draft-campaign';
import { IAdContent } from './ad-content/ad-content.model';
import { ICampaignError } from './campaign-error.model';

export interface IValidationMap {
    [adId: string]: boolean;
}

export interface ICheckedCreativeNode {
    id: string;
    isValid: boolean;
    adContents?: IAdContent[];
    parentType: NodeType;
    usesDefault: boolean;
    usesFallback: boolean;
    invalidByMultipleChoices: boolean; // it's both for sizes and versions
    hasInactiveCreative: boolean;
    isDefault: boolean;
}
export interface ICheckedDecisionTree {
    adGroupId: string;
    allAdsHaveFallback: boolean;
    adFallbackMap: IValidationMap;
    adHeavyVideoMap: IValidationMap;
    containHeavyVideo: boolean;
    checkedCreativeNodes: ICheckedCreativeNode[];
    checkedDefaultNodes?: ICheckedCreativeNode[];
    checkedExcludedCreativeNodes: ICheckedCreativeNode[]; // past events nodes
    hasMultipleChoices?: boolean;
    autoOptNodeIsMissingSet: boolean;
    autoOptAdIsMissingDesign: boolean;
    hasInvalidScheduleAd: boolean;
    isValid: boolean;
    autoOptUsingSameCreative: boolean;
}
export interface IDraftCampaignValidationResult {
    isValid: boolean;
    checkedTrees?: ICheckedDecisionTree[];
    error?: ICampaignError;
}

export enum CheckedCreativeNodeStatus {
    Valid = 0,
    Invalid = 1,
    Default = 2,
    Fallback = 3,
    Creative = 4
}
