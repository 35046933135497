import { IEnvironment } from './environment.interface';

export const sandboxEnvironment: IEnvironment = {
    production: false,
    version: 'sandbox',
    stage: 'sandbox',
    appUrl: 'https://sandbox-cm.bannerflow.com',
    idpUrl: 'https://sandbox-login.bannerflow.com',
    studioUrl: 'https://sandbox-studio.bannerflow.com',
    studioApi: 'https://sandbox-api.bannerflow.com/studio',
    acgUrl: 'https://sandbox-api.bannerflow.com/acg',
    publishServiceUrl: 'https://sandbox-api.bannerflow.com/ps',
    campaignServiceUrl: 'https://sandbox-api.bannerflow.com/cs',
    bannerflowUrl: 'https://sandbox-home.bannerflow.com',
    adServiceUrl: 'https://c.sandbox-bannerflow.net',
    gtmId: '',
    intercomId: '',
    bannerflowCdnUrl: 'https://c.sandbox-bannerflow.net/',
    commonListViewUrl: 'https://sandbox-api.bannerflow.com/list-service',
    analyticsFrontendUrl: 'https://sandbox-analytics.bannerflow.com',
    clientId: 'Ih6cO7HQzwx03endovuoYcRPwV0HG2az',
    apiGatewayUrl: 'https://sandbox-api.bannerflow.com',
    creativePreviewUrl: 'https://sandbox-api.bannerflow.com/preview',
    newRelic: {
        enabled: true,
        accountId: '4232543',
        trustKey: '4122654',
        agentId: '538562446',
        licenseKey: 'NRJS-d0b27a9b958bc4b281c',
        applicationId: '538562446',
        origins: [
            'https://sandbox-home.bannerflow.com',
            'https://c.sandbox-bannerflow.net',
            'https://sandbox-api.bannerflow.com/list-service',
            'https://sandbox-login.bannerflow.com',
            'https://sandbox-api.bannerflow.com/ps',
            'https://sandbox-sapi.bannerflow.com',
            'https://sandbox-studio.bannerflow.com',
            'https://sandbox-acg.bannerflow.com',
            'https://sandbox-api.bannerflow.com/cs',
            'https://sandbox-api.bannerflow.com/studio',
            'https://sandbox-api.bannerflow.com/notification-service/api'
        ]
    },
    accountAccessUrl: 'https://sandbox-api.bannerflow.com/account-access',
    notificationServiceApi: 'https://sandbox-api.bannerflow.com/notification-service/api',
    featureFlags: {
        enabled: true,
        url: 'https://bf-feature-flags.azurewebsites.net/api/frontend',
        clientKey: '*:development.c5ec19510f2249a4a1d07a618019eb5f41bf3442db0c34c5090d2a0c'
    },
    NSSignalR: {
        enabled: true,
        url: 'https://sandbox-api.bannerflow.com/notification-service/hub'
    }
};

export const environment = sandboxEnvironment;
