import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { GlobalErrorHandler } from '@core/services/monitoring/global-error-handler.service';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject } from 'rxjs';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { CampaignsStoreModule } from '@core/campaign-store-module';
import { provideStore } from '@ngrx/store';
import { provideRouter } from '@angular/router';
import { routes } from './app-routes';
import { environment } from '@environments/environment';
import { provideFeatureFlags } from '@bannerflow/feature-flags';
import { provideAuth0 } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from '@core/interceptors/http-auth0.interceptor';
import { HttpErrorInterceptor } from '@core/interceptors';


export const appConfig = {
    providers: [
        provideStore(),
        provideRouter(routes),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideSentinelService(
            withNewRelic({
                ...environment.newRelic,
                applicationVersion: environment.version,
                releaseName: environment.version,
                releaseId: environment.version
            })
        ),
        provideAuth0({
            domain: environment.idpUrl,
            clientId: environment.clientId,
            authorizationParams: {
                redirect_uri: window.location.search.includes('branch=')
                    ? `${window.location.origin}/${window.location.search}`
                    : `${window.location.origin}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'offline_access openid profile campaignservice bannerflow publishservice studio listservice email'
            },
            httpInterceptor: {
                allowedList: [
                    `${environment.publishServiceUrl}*`,
                    `${environment.appUrl}*`,
                    `${environment.bannerflowUrl}*`,
                    `${environment.campaignServiceUrl}*`,
                    `${environment.idpUrl}*`,
                    `${environment.studioUrl}*`,
                    `${environment.bannerflowCdnUrl}*`,
                    `${environment.commonListViewUrl}*`,
                    `${environment.analyticsFrontendUrl}*`,
                    `${environment.apiGatewayUrl}*`,
                    `${environment.adServiceUrl}*`,
                    `${environment.notificationServiceApi}*`
                ]
            },
            useRefreshTokens: true
        }),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideFeatureFlags({
            enabled: environment.featureFlags.enabled,
            appName: 'Campaign Manager',
            url: environment.featureFlags.url,
            clientKey: environment.featureFlags.clientKey
        }),
        provideNotificationService({
            apiUri: environment.notificationServiceApi,
            accessToken$: new BehaviorSubject<string | undefined>(undefined),
            signalR: {
                enabled: environment.NSSignalR.enabled,
                url: environment.NSSignalR.url
            }
        }),
        provideStoreDevtools({
            maxAge: 25,
            logOnly: environment.production,
        }),
        importProvidersFrom(BrowserModule, CampaignsStoreModule),
        Title,
        provideAnimations(),
    ]

}
