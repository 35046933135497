export class KeyValue {
    public key: string;
    public value: string;

    public static deserialize(json: any): KeyValue | null {
        if (!json) {
            return null;
        }
        const keyValue: KeyValue = new KeyValue();
        keyValue.key = json.key;
        keyValue.value = json.value;

        return keyValue;
    }

    /**
     * Clone that take value copy of this object
     */
    public clone(): KeyValue {
        const keyValue: KeyValue = new KeyValue();
        keyValue.key = this.key;
        keyValue.value = this.value;

        return keyValue;
    }
}
