export enum AdStatus {
    Unknown = 0,
    Created = 1,
    Draft = 2,
    Optimizing = 4, // waiting creatives
    BeingPublished = 8, // sent to publish service
    Published = 16,
    PublishFailed = 32,
    CreativesFailed = 64
}
