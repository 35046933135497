import { createAction, props } from '@ngrx/store';

export const panToAdGroup = createAction('[Editor] Pan to Ad Group', props<{ payload: boolean }>());

export const setEditorScroll = createAction(
    '[Editor] Set scroll position',
    props<{ payload: number }>()
);

export type EditorActions = ReturnType<typeof panToAdGroup> | ReturnType<typeof setEditorScroll>;
