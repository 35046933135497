import { DateUtilities } from '@shared/utilities/date-utilities';
import { ObjectID } from 'bson';
import { DaysOfWeekUtilities } from './days-of-week';
import { IEventRecursion } from './event-recursion';
import { IGroupedScheduleEvents, IScheduleEvent } from './schedule-event';
import { assertUnreachable } from '@shared/utilities/assert-unreachable';

export const EVENT_COLORS: string[] = [
    '#93C1E7',
    '#EBB70E',
    '#98CA96',
    '#E2A3A4',
    '#D5C8DA',
    '#03AAFF',
    '#D08F22',
    '#29A324',
    '#DC5B5B',
    '#B872D2',
    '#1558A8',
    '#986209',
    '#5F8153',
    '#AF4342',
    '#817BAF'
];

export class ScheduleEventUtilities {
    public static createDefault(
        timeZoneOffset: number,
        color: string,
        eventName?: string
    ): IScheduleEvent {
        const dayStart: Date = new Date();

        return this.createEventForDay(timeZoneOffset, color, eventName, dayStart, null);
    }

    public static createDefaultForDay(
        timeZoneOffset: number,
        color: string,
        dayStart: Date,
        dayEnd: Date
    ): IScheduleEvent {
        return this.createEventForDay(timeZoneOffset, color, null, dayStart, dayEnd);
    }

    private static createEventForDay(
        timeZoneOffset: number,
        color: string,
        eventName?: string,
        startLocal?: Date,
        endLocal?: Date
    ): IScheduleEvent {
        const startDate = DateUtilities.getStartOfLocalDate(startLocal || new Date());
        const startLocalDateTime = DateUtilities.transformLocalDateToDateString(startDate);
        const start = DateUtilities.toTimeZoneTime(+startDate, timeZoneOffset);
        const nextDay = endLocal || new Date(startLocalDateTime);
        nextDay.setHours(23, 45, 0, 0);
        const end = DateUtilities.toTimeZoneTime(+nextDay, timeZoneOffset);
        const endLocalDateTime = DateUtilities.transformLocalDateToDateString(nextDay);

        return {
            id: new ObjectID().toString(),
            start,
            startLocalDateTime,
            end,
            endLocalDateTime,
            repetitions: 1,
            period: end - start,
            name: eventName || '(No title)',
            isActive: true,
            override: false,
            weight: 1,
            color,
            recursion: null
        };
    }

    public static createDefaultRecursion(startDate: Date): IEventRecursion {
        const day: number = DaysOfWeekUtilities.getDay(startDate.getTime());

        return {
            type: 'week',
            frequency: 1,
            endType: 'never',
            daysOfWeek: DaysOfWeekUtilities.dayToEnum(day)
        };
    }

    public static groupAndSortEventsByTime(
        events: IScheduleEvent[],
        timeZoneOffset: number
    ): IGroupedScheduleEvents {
        const upcomingEvents: IScheduleEvent[] = [];
        const pastEvents: IScheduleEvent[] = [];

        events.forEach((event) => {
            this.isUpcomingEvent(event, timeZoneOffset)
                ? upcomingEvents.push(event)
                : pastEvents.push(event);
        });

        // apply sorting logic
        return {
            upcomingEvents: this.sortScheduleUpcomingEvents(upcomingEvents),
            pastEvents: this.sortSchedulePastEvents(pastEvents)
        };
    }

    public static sortScheduleUpcomingEvents(events: IScheduleEvent[]): IScheduleEvent[] {
        const clonedEvents: IScheduleEvent[] = JSON.parse(JSON.stringify(events));
        // Sort upcoming events
        const sortedEvents: IScheduleEvent[] = clonedEvents.sort((eventA, eventB) => {
            // sort by start date in descending order
            const eventALocalStartDate = DateUtilities.transformDateStringToLocalDate(
                eventA.startLocalDateTime
            );
            const eventBLocalStartDate = DateUtilities.transformDateStringToLocalDate(
                eventB.startLocalDateTime
            );
            const comparisonByStartDate =
                eventALocalStartDate.getTime() - eventBLocalStartDate.getTime();
            // sort by end date in ascending order
            const comparisonByEndDate: number = this.compareEndOfEvents(eventA, eventB);

            // if two events start at the same time, the one that ends first get on top
            return comparisonByStartDate || comparisonByEndDate;
        });

        return sortedEvents;
    }

    public static sortSchedulePastEvents(events: IScheduleEvent[]): IScheduleEvent[] {
        const clonedEvents: IScheduleEvent[] = JSON.parse(JSON.stringify(events));
        // Sort past events
        const sortedEvents: IScheduleEvent[] = clonedEvents.sort((eventA, eventB) => {
            // sort by end date in descending order
            const comparisonByEndDate: number = this.compareEndOfEvents(eventB, eventA);
            // sort by start date in descending order
            const eventALocalStartDate = DateUtilities.transformDateStringToLocalDate(
                eventA.startLocalDateTime
            );
            const eventBLocalStartDate = DateUtilities.transformDateStringToLocalDate(
                eventB.startLocalDateTime
            );
            const comparisonByStartDate =
                eventBLocalStartDate.getTime() - eventALocalStartDate.getTime();

            // if two events end at the same time, the shorter event gets on top
            return comparisonByEndDate || comparisonByStartDate;
        });

        return sortedEvents;
    }

    private static compareEndOfEvents(eventA: IScheduleEvent, eventB: IScheduleEvent): number {
        return this.getEndOfEvent(eventA) - this.getEndOfEvent(eventB);
    }

    private static getEndOfEvent(event: IScheduleEvent): number {
        if (!event.recursion) {
            return event.end;
        }
        switch (event.recursion.endType) {
            case 'never':
                return new Date(8640000000000000).getTime();
            case 'date':
                return +DateUtilities.transformDateStringToLocalDate(event.recursion.endDateLocal);
            case 'occurrence':
                return this.getEventRecursionOccurenceEndDate(event);
            default:
                throw new Error('unknown recursion type');
        }
    }

    public static colorEventsWithoutColor(events: IScheduleEvent[]): void {
        const eventWithoutColor: IScheduleEvent[] = events.filter((e) => e.color === null);
        eventWithoutColor.forEach((ewc) => {
            ewc.color = this.getNextUnusedColor(events);
        });
    }

    public static getNextUnusedColor(events: IScheduleEvent[]): string {
        const usedColors: string[] = events.filter((e) => e.color !== null).map((e) => e.color);
        if (usedColors.length < EVENT_COLORS.length) {
            return EVENT_COLORS.filter((c) => !usedColors.includes(c))[0];
        } else {
            return EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
        }
    }

    public static isUpcomingEvent(
        event: IScheduleEvent,
        timezoneOffset: number,
        currentUTC = Date.now()
    ): boolean {
        const eventEndDateLocal = DateUtilities.transformDateStringToLocalDate(
            event.endLocalDateTime
        );
        const eventEndUTC = DateUtilities.toTimeZoneTime(+eventEndDateLocal, timezoneOffset);

        if (!event.recursion) {
            return currentUTC < eventEndUTC;
        } else {
            switch (event.recursion.endType) {
                case 'never':
                    return true;
                case 'date': {
                    const recursionEndDateLocal = DateUtilities.transformDateStringToLocalDate(
                        event.recursion.endDateLocal
                    );
                    const recursionEndUTC = DateUtilities.toTimeZoneTime(
                        +recursionEndDateLocal,
                        timezoneOffset
                    );
                    return (
                        currentUTC < recursionEndUTC ||
                        this.isTimeWithinAdjustedRepeatingPeriod(event, currentUTC)
                    );
                }
                case 'occurrence':
                    return currentUTC < this.getEventRecursionOccurenceEndDate(event);

                default:
                    throw new Error('unknown recursion type');
            }
        }
    }

    private static isTimeWithinAdjustedRepeatingPeriod(
        scheduleEvent: IScheduleEvent,
        currentUTC: number
    ): boolean {
        if (scheduleEvent.recursion.endType !== 'date') {
            throw new Error('Event recursion end type has to be occurrence');
        }
        const eventStartLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.startLocalDateTime
        );
        const recursionEndLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.recursion.endDateLocal
        );

        const timeSinceStart: number =
            recursionEndLocalDate.getTime() - eventStartLocalDate.getTime();
        const timeSinceLastRecurringEventStart: number = timeSinceStart % scheduleEvent.period;

        if (timeSinceLastRecurringEventStart <= scheduleEvent.period) {
            const adjustedEnd: number =
                recursionEndLocalDate.getTime() -
                timeSinceLastRecurringEventStart +
                scheduleEvent.period;
            return currentUTC < adjustedEnd;
        }

        return false;
    }

    public static getEventRecursionOccurenceEndDate(scheduleEvent: IScheduleEvent): number {
        if (scheduleEvent.recursion.endType !== 'occurrence') {
            throw new Error('Event recursion end type has to be occurrence');
        }
        const startLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.startLocalDateTime
        );
        const endLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.endLocalDateTime
        );

        const eventStart = startLocalDate.getTime();
        const eventEnd = endLocalDate.getTime();
        const period: number = this.calculateEventPeriod(startLocalDate, scheduleEvent.recursion);

        const recursionEnd: number =
            eventStart + (scheduleEvent.recursion.endAfter - 1) * period + (eventEnd - eventStart);

        return recursionEnd;
    }

    public static processEvent(
        scheduleEvent: IScheduleEvent,
        timezoneOffset: number
    ): IScheduleEvent {
        const startLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.startLocalDateTime
        );
        const endLocalDate = DateUtilities.transformDateStringToLocalDate(
            scheduleEvent.endLocalDateTime
        );

        if (scheduleEvent.weight <= 0) {
            scheduleEvent.weight = 1;
        }

        if (scheduleEvent.recursion) {
            scheduleEvent.period = this.calculateEventPeriod(
                startLocalDate,
                scheduleEvent.recursion
            );

            if (scheduleEvent.recursion.endType === 'occurrence') {
                scheduleEvent.repetitions = scheduleEvent.recursion.endAfter;
            } else if (scheduleEvent.recursion.endType === 'date') {
                // Important in order for events with end dates to work. Jira: WW-4071
                scheduleEvent.repetitions = null;
            } else {
                // infinite event
                scheduleEvent.repetitions = null;
            }
        } else {
            const startInTimezoneTime = DateUtilities.toTimeZoneTime(
                endLocalDate.getTime(),
                timezoneOffset
            );
            const endInTimezoneTime = DateUtilities.toTimeZoneTime(
                startLocalDate.getTime(),
                timezoneOffset
            );
            const timezonePeriod = startInTimezoneTime - endInTimezoneTime;
            scheduleEvent.period = timezonePeriod;
            scheduleEvent.repetitions = 1;
        }

        return scheduleEvent;
    }

    public static calculateEventPeriod(eventStart: Date, recursion: IEventRecursion): number {
        const DAILY_PERIOD = 24 * 60 * 60 * 1000; // 86'400'000ms
        const WEEKLY_PERIOD = DAILY_PERIOD * 7; // 604'800'000ms
        const MONTHLY_PERIOD = eventStart.getDate() * DAILY_PERIOD;

        switch (recursion.type) {
            case 'day':
                return DAILY_PERIOD * recursion.frequency;
            case 'week':
                return WEEKLY_PERIOD * recursion.frequency;
            case 'month':
                return MONTHLY_PERIOD * recursion.frequency;
        }

        return assertUnreachable();
    }
}
