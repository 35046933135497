import { Injectable, inject } from '@angular/core';
import {
    selectAdsChecked,
    selectAdValidationMap
} from '@campaign/store/draft-campaign-validation/draft-campaign-validation.selectors';
import { selectDraftCampaignDirtyState } from '@campaign/store/draft-campaign/draft-campaign.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { getAdValidationStatesAsArray } from '@campaign/utilities/ad-validation.utils';
import { Store } from '@ngrx/store';
import { IAd, NodeType } from '@shared/models/campaigns';
import {
    CampaignErrorReason,
    IAdValidationState,
    ICampaignValidationResult
} from '@shared/models/campaigns/validation';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CampaignValidationService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);

    /**
     * Checks if given ads can be published
     */
    public canPublish(ads: IAd[]): Observable<ICampaignValidationResult> {
        return this.store.select(selectDraftCampaignDirtyState).pipe(
            switchMap((isDirty) => {
                if (isDirty) {
                    return of({
                        isValid: false,
                        error: {
                            reason: CampaignErrorReason.DraftIsDirty
                        }
                    });
                }

                // check if we have ads
                if (!(ads && ads.length > 0)) {
                    // error no ads
                    return of({
                        isValid: false,
                        error: {
                            reason: CampaignErrorReason.HasNoAds
                        }
                    });
                }

                // we have ads time to validate
                return this.validateAds(ads);
            })
        );
    }

    /**
     * Checks given ads validity
     * @param adsToPublish
     */
    // Observable<ICampaignValidationResult>
    private validateAds(adsToPublish: IAd[]): Observable<ICampaignValidationResult> {
        const adToPublishIds = adsToPublish.map((ad) => ad.id);

        return this.store.select(selectAdsChecked).pipe(
            filter(Boolean),
            switchMap(() => this.store.select(selectAdValidationMap)),
            map((adValidationMap) => {
                const adValidationStates: IAdValidationState[] = getAdValidationStatesAsArray(
                    adValidationMap,
                    adToPublishIds
                );

                const isValid = adValidationStates.every((ad) => ad.isValid);

                if (isValid) {
                    return {
                        isValid,
                        adValidationStates
                    };
                }

                const scheduleAdsAreInvalid = adValidationStates.some(
                    (ad) => !ad.isValid && NodeType.Schedule === ad.rootType
                );

                const reason = scheduleAdsAreInvalid
                    ? CampaignErrorReason.ScheduleAdsAreInvalid
                    : CampaignErrorReason.AdsAreInvalid;

                return {
                    isValid,
                    adValidationStates,
                    error: {
                        reason
                    }
                };
            })
        );
    }
}
