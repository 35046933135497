import { environment } from '@environments/environment';
import {
    ActionReducerMap,
    createFeatureSelector,
    MemoizedSelector,
    MetaReducer
} from '@ngrx/store';
import { brandReducer, IBrandState } from './store/brand.reducer';
import { IUserState, userReducer } from './store/user.reducer';

export interface IAppState {
    brand: IBrandState;
    user: IUserState;
}

export const reducers: ActionReducerMap<IAppState> = {
    brand: brandReducer,
    user: userReducer
};

export const metaReducers: MetaReducer<IAppState>[] = !environment.production ? [] : [];

export const selectUserState: MemoizedSelector<IAppState, IUserState> =
    createFeatureSelector<IUserState>('user');
