import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectCreativeNode } from '../creative-node/creative-node.actions';
import { showCreativePanel, hideCreativePanel } from './creative-panel.actions';
import { discardDraftCampaignSuccess } from '@campaign/store/draft-campaign/draft-campaign.actions';

@Injectable()
export class CreativePanelEffects {
    private actions$ = inject(Actions);


    public showPanelsOnSelectCreativeNode$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(selectCreativeNode),
            filter((action) => !!action.payload.creativesetId),
            map(() => showCreativePanel())
        )
    );

    public hideCreativePanelOnDiscard$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(discardDraftCampaignSuccess),
            map(() => hideCreativePanel())
        )
    );
}
