export enum OptimisationStatus {
    New = 0,
    InProgress = 1
}

export enum AutoOptimisationMetric {
    CTR = 0,
    CVR = 1
}

export interface IAutoOptimisationVariant {
    id: string;
    name: string;
}

export interface IAutoOptimisationCreativeVariant {
    creativeSetId: string;
    creativeId: string;
    probability: number;
}

export interface IAutoOptimisationData {
    adId: string;
    variants: IAutoOptimisationCreativeVariant[];
    variantLeader: string;
}
