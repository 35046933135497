import { GeneratedAdZipInfo } from './ad-zip.model';

export class AdZipRedirectData {
    public generatedAdZipInfo: GeneratedAdZipInfo[] = [];

    get hasZipGenerated(): boolean {
        // If at least one of the ads still not generated, the ad service will return failed = false and zipUrl = null
        return (
            this.generatedAdZipInfo.length &&
            !this.generatedAdZipInfo.some(
                (zipGenerated: GeneratedAdZipInfo) => !zipGenerated.failed && !zipGenerated.zipUrl
            )
        );
    }

    get numberOfFailed(): number {
        return this.generatedAdZipInfo.filter(
            (zipGenerated: GeneratedAdZipInfo) => zipGenerated.failed
        ).length;
    }
}
