import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PublishLogDataTypeGuard } from '@core/services/publish/publish-log-data-type.guards';
import { environment } from '@environments/environment';
import { PublishLogData } from '@shared/models/campaigns/publish-log/publish-log-data';
import { Observable, lastValueFrom } from 'rxjs';
import {
    CredentiatilStatusResponse,
    Destination,
    KeyValue,
    PublishOptionConfiguration
} from '../../../shared/models/publish';
import { UserService } from '../bannerflow/user.service';
import { ReportingLabel } from '@shared/models/publish/reportingLabel.model';

@Injectable({
    providedIn: 'root'
})
export class PublishApiService {
    private readonly http = inject(HttpClient);
    private readonly userService = inject(UserService);

    protected apiUrl: string = environment.publishServiceUrl;
    public newWindow: Window;
    protected campaignServiceUrl: string = environment.campaignServiceUrl;

    get brandId(): string {
        return this.userService.brandId;
    }

    get accountId(): string {
        return this.userService.user.account.id;
    }

    get accountSlug(): string {
        return this.userService.accountSlug;
    }

    get brandSlag(): string {
        return this.userService.brandSlug;
    }

    public async getPublishOptionConfigurations(): Promise<PublishOptionConfiguration[]> {
        try {
            const accountId: string = this.accountId;
            const brandId: string = this.brandId;

            const response: any = await lastValueFrom(
                this.http.get(`${this.apiUrl}/api/PublishOptionConfigurations`, {
                    params: {
                        accountId,
                        brandId
                    }
                })
            );

            return response.map((res: any) => PublishOptionConfiguration.deserialize(res));
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async postPublishOptionConfiguration(
        newPublishOptionConfiguration: PublishOptionConfiguration
    ): Promise<PublishOptionConfiguration> {
        try {
            const accountId: string = this.accountId;

            newPublishOptionConfiguration.accountId = accountId;
            const response: any = await lastValueFrom(
                this.http.post(
                    `${this.apiUrl}/api/PublishOptionConfigurations`,
                    newPublishOptionConfiguration,
                    {}
                )
            );

            return PublishOptionConfiguration.deserialize(response);
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async getDestinations(): Promise<Destination[]> {
        try {
            const accountId: string = this.accountId;

            const response: any = await lastValueFrom(
                this.http.get(`${this.apiUrl}/api/publishdestinations?accountId=${accountId}`, {})
            );

            const destinations: Destination[] = response.map((destination: Destination) =>
                Destination.deserialize(destination)
            );

            return this.sortDestinationsByNameAsc(destinations);
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async getPublishOptionConfiguration(id: string): Promise<PublishOptionConfiguration> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(`${this.apiUrl}/api/PublishOptionConfigurations/${id}`, {})
            );

            return PublishOptionConfiguration.deserialize(response);
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async getPublishOptionConfigurationTemplate(
        publishOptionId: string
    ): Promise<PublishOptionConfiguration> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(
                    `${this.apiUrl}/api/PublishOptionConfigurations/template/?publishOptionId=${publishOptionId}`,
                    {}
                )
            );

            const publishOptionConfigurationTemplate: PublishOptionConfiguration =
                PublishOptionConfiguration.deserialize(response);
            publishOptionConfigurationTemplate.publishOptionId = publishOptionId;

            return publishOptionConfigurationTemplate;
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async getPublishOptionConfigurationSyncedProperties(
        publishOptionId: string,
        propertyValue: string,
        parentValue?: string
    ): Promise<KeyValue[]> {
        try {
            const syncedBaseUrl = `${this.apiUrl}/api/SyncedProperties/?publishOptionConfigurationId=${publishOptionId}&property=${propertyValue}`;
            const syncedUrl: string = !parentValue
                ? syncedBaseUrl
                : `${syncedBaseUrl}&parent=${parentValue}`;

            const response: any = await lastValueFrom(this.http.get(syncedUrl, {}));

            const syncedValues: KeyValue[] = response.map((keyValue: KeyValue) =>
                KeyValue.deserialize(keyValue)
            );

            return this.sortSyncedByValueAsc(syncedValues);
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public async syncConfigurationProperties(publishOptionId: string): Promise<void> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(
                    `${this.apiUrl}/api/SyncedProperties/sync/?publishOptionConfigurationId=${publishOptionId}`,
                    {}
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public authenticateDestination(
        publishConfiguration: PublishOptionConfiguration,
        callback: any
    ): any {
        let oauthUrl: string = publishConfiguration.authentication.oAuthLoginUrl;

        // Pass credential reference id as a query string case already exists to update existing token
        oauthUrl += publishConfiguration.authentication.credentialReference
            ? `?id=${publishConfiguration.authentication.credentialReference}`
            : '';

        const destinationName: string = publishConfiguration.destination.name;

        this.showPopup(oauthUrl, destinationName, 600, 600, callback);
    }

    public async checkCredentialStatus(
        publishOptionId: string
    ): Promise<CredentiatilStatusResponse> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(
                    `${this.apiUrl}/api/PublishOptionConfigurations/${publishOptionId}/credentialstatus`,
                    {}
                )
            );

            return response;
        } catch {
            return null;
        }
    }

    private showPopup(
        url: string,
        title: string,
        width: number,
        height: number,
        callback: any
    ): any {
        const top: number = window.top.outerHeight / 2 + window.top.screenY - height / 2;
        const left: number = window.top.outerWidth / 2 + window.top.screenX - width / 2;
        let oAuthResultReceived = false;

        this.newWindow = window.open(
            url,
            title,
            `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`
        );

        if (window.focus) {
            this.newWindow.focus();
        }

        const callbackOauth: any = (event: any) => {
            if (event?.data?.type === 'oauth_callback') {
                oAuthResultReceived = true;
                if (event.data.credentialsReference) {
                    callback({ result: event.data.credentialsReference, canceled: false });
                } else {
                    callback({ result: {}, canceled: true });
                }

                this.newWindow.close();
                (window as any).removeEventListener('message', callbackOauth);
            }
        };

        const timeInterval: any = setInterval(() => {
            if (this.newWindow.closed && !oAuthResultReceived) {
                clearInterval(timeInterval);
                callback({ result: {}, canceled: true });
                (window as any).removeEventListener('message', callbackOauth);
            }
        }, 50);

        (window as any).addEventListener('message', callbackOauth);
    }

    private sortDestinationsByNameAsc(destinations: Destination[]): Destination[] {
        return destinations.sort((destination1, destination2) => {
            if (destination1.name.toLowerCase() > destination2.name.toLowerCase()) {
                return 1;
            }

            if (destination1.name.toLowerCase() < destination2.name.toLowerCase()) {
                return -1;
            }

            return 0;
        });
    }

    private sortSyncedByValueAsc(syncedValues: KeyValue[]): KeyValue[] {
        return syncedValues.sort((synced1: KeyValue, synced2: KeyValue) => {
            if (synced1.value.toLowerCase() > synced2.value.toLowerCase()) {
                return 1;
            }

            if (synced1.value.toLowerCase() < synced2.value.toLowerCase()) {
                return -1;
            }

            return 0;
        });
    }

    public async getAdPublishLog(adId: string): Promise<PublishLogData[]> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(`${this.apiUrl}/api/PublishLogs`, {
                    params: { adId }
                })
            );

            if (!response && !PublishLogDataTypeGuard.isPublishLog(response)) {
                throw new TypeError('object does not match IPublishLogData');
            }

            return response;
        } catch (error) {
            return Promise.reject(error instanceof Error ? error : new Error(String(error)));
        }
    }

    public getReportingLabels(brandId: string): Observable<ReportingLabel[]> {
        return this.http.get<ReportingLabel[]>(
            `${this.campaignServiceUrl}/api/${this.accountSlug}/${this.brandSlag}/ReportingLabel?brandId=${brandId}`
        );
    }
}
