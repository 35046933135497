export class TagPattern {
    public value: string;

    public static deserialize(json: any): TagPattern | null {
        if (!json) {
            return null;
        }
        const adTagPattern: TagPattern = new TagPattern();
        adTagPattern.value = json.value;

        return adTagPattern;
    }

    public serialize(): TagPattern {
        const adTagPattern: TagPattern = new TagPattern();
        adTagPattern.value = this.value ? this.value : undefined;

        return adTagPattern;
    }

    public clone(): TagPattern {
        const adTagPattern: TagPattern = new TagPattern();
        adTagPattern.value = this.value;

        return adTagPattern;
    }
}
