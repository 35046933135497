import { CreativeSetPreviewEffects } from './creative-set-preview/creative-set-preview.effects';
import { CreativeNodeEffects } from './creative-node/creative-node.effects';
import { LeftPanelEffects } from './left-panel/left-panel.effects';
import { CreativePanelEffects } from './creative-panel/creative-panel.effects';

export const editorEffects = [
    LeftPanelEffects,
    CreativePanelEffects,
    CreativeNodeEffects,
    CreativeSetPreviewEffects
];
