import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthHttpInterceptor, AuthService } from '@auth0/auth0-angular';
import { CYPRESS_FLAG_KEY } from '@cypress/cypress-flag.const';
import { environment } from '@environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    protected adServiceUrl: string = environment.adServiceUrl;
    constructor(
        private readonly authHttpInterceptor: AuthHttpInterceptor,
        private readonly authService: AuthService
    ) {}

    private isCypress(): boolean {
        return !!localStorage.getItem(CYPRESS_FLAG_KEY);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isCypress()) {
            return next.handle(request);
        }

        const skipUrls = [
            `${this.adServiceUrl}/zip-publish`,
            `${this.adServiceUrl}/creative-downloader`
        ];

        // Check if the request URL should skip the Bearer token
        if (skipUrls.some((url) => request.url.includes(url))) {
            return next.handle(request); // Skip adding the Bearer token
        } else {
            return this.authHttpInterceptor.intercept(request, next);
        }
    }
}
