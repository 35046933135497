import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdEvents } from '@campaign/pages/list/models/ad-event';
import { IAdListFilter, IFilterValues } from '@campaign/pages/list/models/ad-list-filter';
import { IAdListSort } from '@campaign/pages/list/models/ad-list-sort.model';
import { IAdsPagination } from '@campaign/pages/list/models/ad-list.model';
import { deserializeFilter } from '@campaign/pages/list/utilities/ad-list.utilities';
import { environment } from '@environments/environment';
import {
    AutoOptimisationRestartChangeType,
    AutoOptimisationRestartDraftState,
    AutoOptimisationRestartRequest,
    AutoOptimisationRestartResponse,
    DraftCampaignPostDto,
    IAutoOptimisationData,
    ICampaign,
    ICampaignFolder,
    IDraftCampaign,
    IFallbackCreative,
    IPublishAttempt
} from '@shared/models/campaigns';
import {
    IAdPreviewRequest,
    IAdPreviewResponse
} from '@shared/models/campaigns/ad/ad-preview.model';
import { ICreativeGeneratedInfo } from '@shared/models/campaigns/campaign/creative-info';
import {
    ITimeZone,
    ITimeZoneDto
} from '@shared/models/campaigns/draft-campaign/nodes/schedule-node/timezone.model';
import {
    Destination,
    ParametersDictionary,
    PublishAd,
    SupportedAdType
} from '@shared/models/publish';
import { PublicationSpecification } from '@shared/models/publish/publish-option-specification';
import { getUniquesById } from '@shared/utilities/array-utilities';
import { Observable, catchError, lastValueFrom, map, throwError } from 'rxjs';
import { UserService } from '../bannerflow/user.service';
import { PublicationSpecificationFactory } from '../publish/publication-specification-factory';
import { CampaignTypeGuards } from './campaign-type-guards';
import { mapTimeZone } from './mappers/timezone.mapper';
import { DraftCampaginApiMiddlewareService } from './middleware/draft-campaign-api-middleware.service';
import { DraftCampaginAppMiddlewareService } from './middleware/draft-campaign-app-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class CampaignApiService {
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private apiMiddleware: DraftCampaginApiMiddlewareService,
        private appMiddleware: DraftCampaginAppMiddlewareService
    ) {}

    // Needed for overriding mock tests
    protected getApiPrefix(): string {
        return `${environment.campaignServiceUrl}/api`;
    }

    // User current brand and account
    protected getApiPrefixWithBrand(): string {
        return `${environment.campaignServiceUrl}/api/${this.userService.accountSlug}/${this.userService.brandSlug}`;
    }

    // User current brand and account
    protected getApiPrefixWithBrandId(): string {
        return `${environment.campaignServiceUrl}/api/${this.userService.accountSlug}/${this.userService.user?.brand.id}`;
    }

    // #region CAMPAIGN

    // campaign creation starting from STUDIO
    public createCampaign(
        campaignName: string,
        draftCampaign: DraftCampaignPostDto
    ): Observable<ICampaign> {
        return this.http.post<ICampaign>(
            `${this.getApiPrefixWithBrand()}/Campaign/withdecisionforest`,
            {
                campaignName,
                draftCampaignModel: draftCampaign
            }
        );
    }

    public async getCampaign(campaignId: string): Promise<ICampaign> {
        try {
            const response: any = await await lastValueFrom(
                this.http.get(`${this.getApiPrefixWithBrand()}/Campaign/${campaignId}`)
            );

            if (!CampaignTypeGuards.isCampaign(response)) {
                throw new TypeError('object does not match ICampaign');
            }

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public async getCampaignFolder(campaignId: string): Promise<ICampaignFolder> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(
                    `${this.getApiPrefixWithBrand()}/Folder/GetByCampaignId?campaignId=${campaignId}`
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public async renameCampaign(
        campaignId: string,
        campaignName: string,
        campaignFolder?: ICampaignFolder
    ): Promise<ICampaign> {
        try {
            const response: any = await lastValueFrom(
                this.http.patch(`${this.getApiPrefixWithBrand()}/Campaign`, {
                    campaignId,
                    campaignFolderId: campaignFolder?.folderId || undefined,
                    campaignName
                })
            );

            if (!CampaignTypeGuards.isCampaign(response)) {
                throw new TypeError('object does not match ICampaign');
            }

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    // TODO note that draft endpoints dont have account/brand
    public async getDraftCampaign(id: string): Promise<IDraftCampaign> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(`${this.getApiPrefixWithBrand()}/DraftCampaign/${id}`)
            );

            return this.appMiddleware.perpareDraftCampaignForApp(response);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    // TODO note that draft endpoints dont have account/brand
    public async getLiveCampaign(id: string): Promise<IDraftCampaign> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(`${this.getApiPrefixWithBrand()}/DraftCampaign/${id}`, {
                    params: {
                        specifyDraft: 'false'
                    }
                })
            );

            return this.appMiddleware.perpareDraftCampaignForApp(response);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    // TODO note that draft enpoints dont have account/brand
    public async pushDraftToLive(id: string): Promise<ICampaign> {
        try {
            const response: any = await lastValueFrom(
                this.http.post(`${this.getApiPrefix()}/DraftCampaign/${id}/save`, {})
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    // TODO note that draft endpoints dont have account/brand
    public async upsertDraftCampaign(draftCampaign: IDraftCampaign): Promise<IDraftCampaign> {
        try {
            draftCampaign = this.apiMiddleware.prepareForDraftCampaignAPI(draftCampaign);

            const response = await lastValueFrom(
                // TODO change to boolean only after BE fix isDirty
                this.http.post<boolean | IDraftCampaign>(
                    `${this.getApiPrefixWithBrand()}/DraftCampaign`,
                    draftCampaign
                )
            );

            // TODO remove after BE fix isDirty
            const isDirty = typeof response === 'boolean' ? response : response.isDirty;

            return this.appMiddleware.perpareDraftCampaignForApp({
                ...draftCampaign,
                isDirty
            });
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    // TODO note that draft endpoints dont have account/brand
    public async discardChanges(id: string): Promise<IDraftCampaign> {
        try {
            const response: any = await lastValueFrom(
                this.http.post(`${this.getApiPrefix()}/DraftCampaign/${id}/reset`, {})
            );

            return this.appMiddleware.perpareDraftCampaignForApp(response);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public getTimezones(): Observable<ITimeZone[]> {
        return this.http
            .get<ITimeZoneDto[]>(`${this.getApiPrefix()}/DraftCampaign/TimeZones`)
            .pipe(map((timeZones) => timeZones.map(mapTimeZone)));
    }

    // #endregion
    // #region AD

    public async removeAds(adIds: string[]): Promise<boolean> {
        try {
            await lastValueFrom(
                this.http.delete(`${this.getApiPrefixWithBrand()}/Ad`, {
                    params: {
                        ids: adIds
                    }
                })
            );

            return true;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public async getAdPreviews(
        campaignId: string,
        request: IAdPreviewRequest
    ): Promise<IAdPreviewResponse[]> {
        try {
            const response: IAdPreviewResponse[] = await lastValueFrom(
                this.http.post<IAdPreviewResponse[]>(
                    `${this.getApiPrefix()}/Preview/${campaignId}`,
                    request
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    /**
     * Get ad as a script in pure text
     */
    public getAdPreviewScript(creativeNodeId: string): Observable<string> {
        return this.http
            .get(`${this.getApiPrefixWithBrand()}/creative/${creativeNodeId}/preview`, {
                responseType: 'text'
            })
            .pipe(
                catchError((error) => {
                    console.error('An error occurred:', error);
                    return throwError(() => error);
                })
            );
    }

    public getAds(
        campaignId: string,
        pageSize: string,
        pageNumber: string,
        filterObject: IAdListFilter,
        sort: IAdListSort
    ): Observable<IAdsPagination> {
        const url = `${this.getApiPrefixWithBrand()}/Campaign/${campaignId}/ads`;
        let params = new HttpParams();
        const filter = deserializeFilter(filterObject);

        params = params.appendAll({
            pageSize,
            pageNumber,
            ...filter
        });

        if (sort.sortBy) {
            params = params.appendAll({
                SortBy: sort.sortBy,
                Descending: sort.descending
            });
        }

        return this.http.get<IAdsPagination>(url, { params });
    }

    public getFilterValues(campaignId: string): Observable<IFilterValues> {
        const url = `${this.getApiPrefixWithBrand()}/Campaign/${campaignId}/filtervalues`;
        return this.http.get<IFilterValues>(url).pipe(
            // The backend boys made us do it
            map((filterValues) => {
                const mappedSizes = filterValues.sizes.map((size) => ({
                    ...size,
                    id: `${size.width}x${size.height}`
                }));

                const sizes = getUniquesById(mappedSizes);

                return {
                    ...filterValues,
                    sizes
                };
            })
        );
    }

    // #endregion
    // #region PUBLISH

    /**
     * Publish ads to a destination based on publish option configuration
     */
    public async publishAds(
        publishOptionConfigurationId: string,
        ads: PublishAd[],
        parameters: ParametersDictionary,
        configDestination: Destination
    ): Promise<void> {
        const body: PublicationSpecification = PublicationSpecificationFactory.create(
            publishOptionConfigurationId,
            ads,
            parameters,
            configDestination
        );

        try {
            await lastValueFrom(this.http.post(`${this.getApiPrefixWithBrand()}/Ad/publish`, body));
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    /**
     * Publish Tag to a destination based on publish option configuration
     */
    public async publishTag(
        publishOptionConfigurationId: string,
        ads: PublishAd[],
        parameters: ParametersDictionary,
        configDestination: Destination,
        supportedAdType: SupportedAdType
    ): Promise<string[]> {
        const body: PublicationSpecification = PublicationSpecificationFactory.create(
            publishOptionConfigurationId,
            ads,
            parameters,
            configDestination,
            supportedAdType
        );

        try {
            const response: any = await lastValueFrom(
                this.http.post(`${this.getApiPrefixWithBrand()}/Ad/publishTag`, body)
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    /**
     * Publish Zip to a destination based on publish option configuration
     */
    public async publishZip(
        publishOptionConfigurationId: string,
        ads: PublishAd[],
        parameters: ParametersDictionary,
        configDestination: Destination
    ): Promise<string> {
        const body: PublicationSpecification = PublicationSpecificationFactory.create(
            publishOptionConfigurationId,
            ads,
            parameters,
            configDestination
        );

        try {
            const publishAttempt: any = await lastValueFrom(
                this.http.post(`${this.getApiPrefixWithBrand()}/Ad/publishZip`, body)
            );

            return publishAttempt.correlationId;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    /**
     * get publish attempt
     */
    public async getPublishAttempt(
        correlationId: string,
        campaignId: string
    ): Promise<IPublishAttempt> {
        try {
            const response: any = await lastValueFrom(
                this.http.get(
                    `${this.getApiPrefixWithBrand()}/${campaignId}/PublishAttempt/id?id=${correlationId}`
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public async prepareAdPublish(adsId: string[]): Promise<void> {
        try {
            await lastValueFrom(
                this.http.post(`${this.getApiPrefixWithBrand()}/Ad/preparepublish`, adsId)
            );
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }
    // #endregion

    public getFallbackCreatives(): Observable<IFallbackCreative[]> {
        return this.http.get(`${this.getApiPrefixWithBrand()}/Creative/FallbackCreatives`).pipe(
            map((response) => {
                if (Array.isArray(response)) {
                    if (response.every((item) => CampaignTypeGuards.isFallbackCreative(item))) {
                        return response;
                    }
                    throw new TypeError('object does not match IFallbackCreative[]');
                }

                return [];
            })
        );
    }

    public async getAutoOptimisationData(campaignId: string): Promise<IAutoOptimisationData[]> {
        try {
            const response: IAutoOptimisationData[] = await lastValueFrom(
                this.http.get<IAutoOptimisationData[]>(
                    `${this.getApiPrefixWithBrand()}/AutoOptimization/${campaignId}/leaders`
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public async getCreativesetInfos(creativesetIds: string[]): Promise<ICreativeGeneratedInfo[]> {
        try {
            const response: any = await lastValueFrom(
                this.http.post(
                    `${this.getApiPrefixWithBrand()}/Creative/GetCreativeInfos`,
                    creativesetIds
                )
            );

            return response;
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    }

    public getAdsEvents(adIds: string[], campaignId?: string): Observable<IAdEvents> {
        const url = `${this.getApiPrefixWithBrandId()}/Ad/GetAdImpressions`;

        if (adIds) {
            return this.http.get<IAdEvents>(url, {
                params: {
                    AdIds: adIds,
                    DaysToRetrieve: ['1', '7', '30']
                }
            });
        } else if (campaignId) {
            return this.http.get<IAdEvents>(url, {
                params: {
                    CampaignId: campaignId,
                    DaysToRetrieve: ['1', '7', '30']
                }
            });
        }
    }

    public autoOptimisationRestartCheck(
        draftCampaign: IDraftCampaign
    ): Observable<AutoOptimisationRestartDraftState[]> {
        const url = `${this.getApiPrefixWithBrandId()}/DraftCampaign/optimization-restart-check`;
        const body: AutoOptimisationRestartRequest = { draftCampaignModel: draftCampaign };

        return this.http.post<AutoOptimisationRestartResponse>(url, body).pipe(
            map(({ draftStates }: AutoOptimisationRestartResponse) => {
                draftStates.map(
                    // backend sends a number that we convert to its string equivalent here for better FE troubleshooting
                    (autoOptimisationDraftState: AutoOptimisationRestartDraftState) => ({
                        ...autoOptimisationDraftState,
                        changeType: (autoOptimisationDraftState.changeType = Object.values(
                            AutoOptimisationRestartChangeType
                        )[autoOptimisationDraftState.changeType])
                    })
                );
                return draftStates;
            })
        );
    }
}
