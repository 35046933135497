import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './core.reducer';
import { CampaignEffects } from '@campaign/store/campaign/campaign.effects';
import { DraftCampaignEffects } from '@campaign/store/draft-campaign/draft-campaign.effects';
import { DraftCampaignValidationEffects } from '@campaign/store/draft-campaign-validation/draft-campaign-validation.effects';
import { AutoOptimisationEffects } from '@campaign/store/auto-optimisation/auto-optimisation.effects';
import { DecisionTreeEffects } from '@campaign/store/decision-tree/decision-tree.effects';
import { editorEffects } from '@campaign/pages/editor/store/editor.effects';
import { AdPreviewEffects } from '@campaign/pages/ad-preview/store/ad-preview-effects';
import { editorReducer } from '@campaign/pages/editor/store/editor.reducers';
import { BrandEffects } from './store/brand.effects';
import { campaignManagerReducer } from '@campaign/store/state';
import { adPreviewReducer } from '@campaign/pages/ad-preview/store/ad-preview.reducer';


@NgModule({
    imports: [
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: false,
                strictActionSerializability: false
            }
        }),
        StoreModule.forFeature('campaignManager', campaignManagerReducer),
        StoreModule.forFeature('editor', editorReducer),
        StoreModule.forFeature('adPreview', adPreviewReducer),
        EffectsModule.forRoot([BrandEffects]),
        EffectsModule.forFeature([CampaignEffects,
            DraftCampaignEffects,
            DraftCampaignValidationEffects,
            AutoOptimisationEffects,
            DecisionTreeEffects,
            AdPreviewEffects,
            ...editorEffects
        ])
    ]
})
export class CampaignsStoreModule {}
