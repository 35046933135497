import { IDraftAd, IFallbackCreative, ISizeLanguagePair } from '@shared/models/campaigns';
import { ILocalization, SizeUtilities } from '@shared/models/common';
import { ICreative } from '@shared/models/studio';
import { groupByKey } from '@shared/utilities/array-utilities';
import { ObjectID } from 'bson';
import { AnyAd } from '../any-ad.model';

export class AdUtilities {
    public static sortByLanguageAndSize(adA: AnyAd, adB: AnyAd): number {
        const languageCompare: number = adA.localization.name.localeCompare(adB.localization.name);
        const widthCompare: number = adA.size.width - adB.size.width;
        const heightCompare: number = adA.size.height - adB.size.height;

        return languageCompare || widthCompare || heightCompare;
    }

    public static mapLanguageToAd(ad: AnyAd, languages: ILocalization[]): AnyAd {
        return {
            ...ad,
            localization: languages.find((language) => language.id === ad.localizationId)
        };
    }

    public static canAssign(ad: AnyAd, creative: ICreative | IFallbackCreative): boolean {
        return (
            SizeUtilities.isSameSize(creative.size, ad.size) &&
            creative.version?.localization?.id === ad.localizationId &&
            !!creative.design?.id
        );
    }

    public static hasFallback(ad: AnyAd, fallbackCreatives: IFallbackCreative[]): boolean {
        return (
            Array.isArray(fallbackCreatives) &&
            fallbackCreatives.length > 0 &&
            fallbackCreatives.some((fc) => this.canAssign(ad, fc))
        );
    }

    public static groupByLanguageId(ads: AnyAd[]): Map<string, AnyAd[]> {
        return groupByKey(ads, 'localizationId');
    }

    public static groupAdsByLanguage(ads: AnyAd[]): Map<ILocalization, AnyAd[]> {
        return groupByKey(ads, 'localization');
    }

    public static createDraftAdFromPair(pair: ISizeLanguagePair): IDraftAd {
        return {
            id: new ObjectID().toString(),
            size: pair.size,
            localizationId: pair.language.id,
            localization: pair.language
        };
    }

    public static createDraftAdFromCreative(
        creative: ICreative,
        localization: ILocalization
    ): IDraftAd {
        return {
            id: new ObjectID().toString(),
            size: creative.size,
            localizationId: localization.id,
            localization
        };
    }
}
