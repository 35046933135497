import { BasicCredential } from './basic-credential.model';

export class Authentication {
    public type: string;
    public oAuthLoginUrl: string;
    public credentialReference: string;
    public basicCredentialProperties?: BasicCredential[];

    public static deserialize(json: any): Authentication | null {
        if (!json) {
            return null;
        }
        const authentication: Authentication = new Authentication();
        authentication.oAuthLoginUrl = json.oAuthLoginUrl;
        authentication.type = json.type;
        authentication.credentialReference = json.credentialReference;
        if (json.basicCredentialProperties) {
            authentication.basicCredentialProperties = json.basicCredentialProperties.map(
                (basic: any) => BasicCredential.deserialize(basic)
            );
        }

        return authentication;
    }

    public serialize(): Authentication {
        const authentication: Authentication = new Authentication();
        authentication.credentialReference = this.credentialReference;
        authentication.oAuthLoginUrl = this.oAuthLoginUrl;
        authentication.type = this.type;
        authentication.basicCredentialProperties = this.basicCredentialProperties
            ? this.basicCredentialProperties.map((basic) => basic.serialize())
            : this.basicCredentialProperties;

        return authentication;
    }

    public clone(): Authentication {
        const authentication: Authentication = new Authentication();
        authentication.credentialReference = this.credentialReference;
        authentication.oAuthLoginUrl = this.oAuthLoginUrl;
        authentication.type = this.type;
        authentication.basicCredentialProperties = this.basicCredentialProperties
            ? this.basicCredentialProperties.map((basic) => basic.clone())
            : this.basicCredentialProperties;

        return authentication;
    }
}
