import { ICreative, ICreativeDto, ICreativeSet, ICreativeSetDto } from '@shared/models/studio';


export function mapCreativeSet(creativeSet: ICreativeSetDto): ICreativeSet {
    const enrichedCreatives: ICreative[] = creativeSet.creatives.map((creative) =>
        mapCreative(creative, creativeSet)
    );
    return {
        ...creativeSet,
        id: creativeSet.creativeSetId.toString(),
        sizes: creativeSet.sizes.map((size) => ({ ...size, id: size?.id.toString() })),
        versions: creativeSet.versions.map((version) => ({
            ...version,
            id: version?.id.toString(),
            localization: {
                id: version?.localizationId?.toString()
            }
        })),
        creatives: enrichedCreatives
    };
}

function mapCreative(creative: ICreativeDto, creativeSet: ICreativeSetDto): ICreative {
    const versionDetails = findDetailsById(creativeSet.versions, creative.versionId);
    const designDetails = findDetailsById(creativeSet.designs, creative.designId);
    const sizeDetails = findDetailsById(creativeSet.sizes, creative.sizeId);

    return {
        ...creative,
        id: creative?.id.toString(),
        version: versionDetails
            ? {
                  ...versionDetails,
                  id: versionDetails?.id.toString(),
                  localization: {
                      id: versionDetails?.localizationId
                  }
              }
            : null,
        design: designDetails
            ? {
                  ...designDetails,
                  id: designDetails?.id.toString(),
                  hasHeavyVideo: creative.hasHeavyAsset
              }
            : null,
        size: sizeDetails
            ? {
                  ...sizeDetails,
                  id: sizeDetails?.id.toString()
              }
            : null
    };
}

function findDetailsById(detailsArray: any[], id: number): any {
    return detailsArray?.find((detail) => detail?.id === id);
}
