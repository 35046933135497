import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService, GenericError } from '@auth0/auth0-angular';
import { filter, mergeMap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor(private readonly authService: AuthService) {
        this.authService.error$
            .pipe(
                takeUntilDestroyed(),
                filter(
                    (e) =>
                        e instanceof GenericError &&
                        (e.error === 'login_required' || e.error === 'invalid_grant')
                ),
                mergeMap(() => this.authService.logout())
            )
            .subscribe();
    }
}
