import { ICheckedDecisionTree, NodeType } from '@shared/models/campaigns';
import {
    IAdContent,
    IAutoOptimisationNodeAdContent,
    ICreativeNodeAdContent,
    IScheduleNodeAdContent
} from '@shared/models/campaigns/validation';
import {
    IAdValidationBaseState,
    IAdValidationMap,
    IAdValidationState,
    IAutoOptimisationAdValidationBaseState,
    IAutoOptimisationValidationState,
    ICreativeNodeAdValidationBaseState,
    ICreativeNodeValidationState,
    IScheduleNodeAdValidationBaseState,
    IScheduleNodeValidationState
} from '@shared/models/campaigns/validation/ad-validation.model';

export function getAllAdContentsFromTrees(trees: ICheckedDecisionTree[]): IAdContent[] {
    return trees.flatMap((tree) => {
        const creativeNodesAdContents = tree.checkedCreativeNodes.flatMap(
            (node) => node.adContents
        );

        const defaultNodesAdContents = tree.checkedDefaultNodes.flatMap((node) => node.adContents);

        return [...creativeNodesAdContents, ...defaultNodesAdContents];
    });
}

export function createAdContentMap(adContents: IAdContent[]): { [adId: string]: IAdContent[] } {
    return adContents.reduce((result: { [adId: string]: IAdContent[] }, current) => {
        result[current.adId] = result[current.adId]
            ? [...result[current.adId], current]
            : [current];

        return result;
    }, {});
}

function validateCreativeNodeAd(
    adContentBaseValidation: IAdValidationBaseState,
    adContents: ICreativeNodeAdContent[]
): ICreativeNodeValidationState {
    const creativeNodeAdValidationBase: ICreativeNodeAdValidationBaseState = {
        ...adContentBaseValidation,
        rootType: NodeType.CreativeNode
    };

    const isValid = adContents.every((adContent) => adContent.isValid);

    // true === isValid to satisfy TS union type guard
    if (true === isValid) {
        const usesCreative = adContents.some(
            (adContent) => adContent.isValid && adContent.usesCreative
        );
        const usesFallback = adContents.some(
            (adContent) => adContent.isValid && adContent.usesFallback
        );

        return {
            ...creativeNodeAdValidationBase,
            isValid,
            usesCreative,
            usesFallback
        };
    } else {
        const invalidByMissingCreative = adContents.some(
            // empty content without choices
            (adContent) => false === adContent.isValid && adContent.invalidByMissingCreative
        );
        const invalidByInactiveCreative = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByInactiveCreative
        );
        const invalidByMultipleVersions = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleVersions
        );
        const invalidByMultipleSizes = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleSizes
        );

        return {
            ...creativeNodeAdValidationBase,
            isValid,
            invalidByMissingCreative,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes
        };
    }
}

function validateAutoOptimisationNodeAd(
    adContentBaseValidation: IAdValidationBaseState,
    adContents: IAutoOptimisationNodeAdContent[]
): IAutoOptimisationValidationState {
    const autoOptimisationAdValidationBase: IAutoOptimisationAdValidationBaseState = {
        ...adContentBaseValidation,
        rootType: NodeType.AutoOptimisation
    };

    const isValid = adContents.every((adContent) => adContent.isValid);

    // true === isValid to satisfy TS union type guard
    if (true === isValid) {
        const usesCreative = adContents.some(
            (adContent) => adContent.isValid && adContent.usesCreative
        );

        return {
            ...autoOptimisationAdValidationBase,
            isValid,
            usesCreative
        };
    } else {
        const invalidByMissingCreative = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMissingCreative
        );
        const invalidByInactiveCreative = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByInactiveCreative
        );
        const invalidByMultipleVersions = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleVersions
        );
        const invalidByMultipleSizes = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleSizes
        );

        const invalidByMissingCreativeSet = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMissingCreativeSet
        );

        return {
            ...autoOptimisationAdValidationBase,
            isValid,
            invalidByMissingCreative,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes,
            invalidByMissingCreativeSet
        };
    }
}

function validateScheduleNodeAd(
    adContentBaseValidation: IAdValidationBaseState,
    adContents: IScheduleNodeAdContent[]
): IScheduleNodeValidationState {
    const eventAdContents = adContents.filter((content) => !content.isDefault);
    const defaultAdContent = adContents.find((content) => content.isDefault);

    if (!defaultAdContent) {
        throw new Error('Missing default ad content in schedule ad.');
    }

    const hasDefault = defaultAdContent.hasDesign;

    const scheduleAdValidationBase: IScheduleNodeAdValidationBaseState = {
        ...adContentBaseValidation,
        rootType: NodeType.Schedule,
        hasDefault
    };

    const isValid = defaultAdContent.isValid;

    // true === isValid to satisfy TS union type guard
    if (true === isValid) {
        const usesCreative = eventAdContents.some(
            (adContent) => adContent.isValid && adContent.usesCreative
        );

        const usesDefault =
            eventAdContents.some((adContent) => adContent.isValid && adContent.usesDefault) ||
            (eventAdContents.length === 0 && defaultAdContent.usesDefault);

        const usesFallback =
            eventAdContents.some((adContent) => adContent.isValid && adContent.usesFallback) ||
            (eventAdContents.length === 0 && defaultAdContent.usesFallback);

        return {
            ...scheduleAdValidationBase,
            isValid,
            usesCreative,
            usesDefault,
            usesFallback
        };
    } else {
        const invalidByMissingCreative = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMissingCreative
        );
        const invalidByInactiveCreative = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByInactiveCreative
        );
        const invalidByMultipleVersions = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleVersions
        );
        const invalidByMultipleSizes = adContents.some(
            (adContent) => false === adContent.isValid && adContent.invalidByMultipleSizes
        );

        return {
            ...scheduleAdValidationBase,
            isValid,
            invalidByMissingCreative,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes
        };
    }
}

export function createAdValidationMap(trees: ICheckedDecisionTree[]): IAdValidationMap {
    const allAdContents = getAllAdContentsFromTrees(trees);
    const adContentMap = createAdContentMap(allAdContents);

    return Object.keys(adContentMap).reduce((result: IAdValidationMap, adId: string) => {
        const currentAdContents = adContentMap[adId];
        const rootType = currentAdContents[0].rootType; // every ad content has the same rootType

        const hasCreative = currentAdContents.every((adContent) => adContent.hasDesign);
        const hasFallback = currentAdContents.every((adContent) => adContent.hasFallback);

        const hasMissingCreative = currentAdContents.some((adContent) => !adContent.creativeId);
        const hasInactiveCreative = currentAdContents.some(
            (adContent) => adContent.hasInactiveCreative
        );
        const hasMultipleVersions = currentAdContents.some(
            (adContent) => adContent.hasMultipleVersions
        );
        const hasMultipleSizes = currentAdContents.some((adContent) => adContent.hasMultipleSizes);

        const adContentBaseValidation: IAdValidationBaseState = {
            hasCreative,
            hasFallback,
            hasInactiveCreative,
            hasMissingCreative,
            hasMultipleVersions,
            hasMultipleSizes
        };

        switch (rootType) {
            case NodeType.CreativeNode:
                result[adId] = validateCreativeNodeAd(
                    adContentBaseValidation,
                    currentAdContents as ICreativeNodeAdContent[]
                );
                break;
            case NodeType.AutoOptimisation:
                result[adId] = validateAutoOptimisationNodeAd(
                    adContentBaseValidation,
                    currentAdContents as IAutoOptimisationNodeAdContent[]
                );
                break;

            case NodeType.Schedule:
                result[adId] = validateScheduleNodeAd(
                    adContentBaseValidation,
                    currentAdContents as IScheduleNodeAdContent[]
                );
                break;

            default:
                throw new Error('Invalid root type passed for validation.');
        }

        return result;
    }, {});
}

export function getAdValidationStatesAsArray(
    adValidationMap: IAdValidationMap | undefined,
    selectedAdIds?: string[]
): IAdValidationState[] {
    if (!adValidationMap) {
        return [];
    }

    return Object.keys(adValidationMap)
        .filter((adId) => (selectedAdIds ? selectedAdIds.includes(adId) : true))
        .map((adId) => adValidationMap[adId]);
}

export function checkIfAnyAdIsValidButUsesFallback(
    adValidationStates: IAdValidationState[]
): boolean {
    return adValidationStates.some(
        (ad) =>
            (ad.rootType === NodeType.CreativeNode || ad.rootType === NodeType.Schedule) &&
            ad.isValid &&
            ad.usesFallback
    );
}

export function checkIfAnyAdIsValidButUsesDefault(
    adValidationStates: IAdValidationState[]
): boolean {
    return adValidationStates.some(
        (ad) => ad.rootType === NodeType.Schedule && ad.isValid && ad.usesDefault
    );
}
