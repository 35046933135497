import { Injectable, inject } from '@angular/core';
import { UserService } from '@core/services/bannerflow/user.service';
import { TimeZoneService } from '@core/services/internal/timezone.service';
import {
    AnyNode,
    CreativeNodeUtilities,
    DEFAULT_NODE_SUFFIX,
    IDecisionTree,
    IDraftCampaign,
    IScheduleNode,
    NodeType,
    ScheduleNodeUtilities
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { DateUtilities } from '@shared/utilities/date-utilities';

@Injectable({ providedIn: 'root' })
export class DraftCampaginAppMiddlewareService {
    private readonly timeZoneService = inject(TimeZoneService);
    private readonly userService = inject(UserService);


    public perpareDraftCampaignForApp(draftCampaign: IDraftCampaign): IDraftCampaign {
        if (!draftCampaign) {
            return;
        }

        // filter trees that have decision node as root, because creative node can't have children
        const treesWithDecisionNode: IDecisionTree[] = draftCampaign.decisionTrees.filter(
            (tree) => tree.root.type !== NodeType.CreativeNode
        );

        // adds parent ids to all children
        treesWithDecisionNode.forEach((tree) => {
            this.addParentIdToAllChildren(tree.root);
            this.prepareNodes(tree.root);
        });

        return draftCampaign;
    }

    private addParentIdToAllChildren(rootNode: AnyNode): void {
        const children: AnyNode[] = DecisionTreeUtilities.getChildren(rootNode);

        if (children) {
            children.forEach((child) => {
                if (!child.parentId) {
                    child.parentId = rootNode.id;
                }
                this.addParentIdToAllChildren(child);
            });
        }
    }

    private prepareNodes(rootNode: AnyNode): void {
        DecisionTreeUtilities.getAllDescendants([rootNode]).forEach((node) => {
            if (ScheduleNodeUtilities.isScheduleNode(node)) {
                const offset = this.timeZoneService.getTimeZone(node.timeZoneId).offset;
                this.setDefaultNode(node);
                this.setDefaultTimezone(node);
                this.setLocalDateTimeValuesForEvents(node, offset);
            }
        });
    }

    private setDefaultNode(scheduleNode: IScheduleNode): void {
        // if default not does not exists
        if (!scheduleNode.children.find((child) => CreativeNodeUtilities.isDefaultNode(child))) {
            // adds default node
            scheduleNode.children.push({
                ...CreativeNodeUtilities.create(),
                id: `${scheduleNode.id}${DEFAULT_NODE_SUFFIX}`,
                parentId: scheduleNode.id
            });
        }
    }

    private setDefaultTimezone(scheduleNode: IScheduleNode): void {
        if (!scheduleNode.timeZoneId) {
            const timezoneId: string = this.userService.user.timezone || 'UTC';
            scheduleNode.timeZoneId = timezoneId;
        }
    }

    // temporary for backward compatibility without new properties
    private setLocalDateTimeValuesForEvents(node: IScheduleNode, offset: number): void {
        node.events = node.events.map((event) => {
            if (event.startLocalDateTime && event.endLocalDateTime && !event.recursion) {
                return event;
            }

            const startLocalDateTimeUTC: number = DateUtilities.toLocalTime(event.start, offset);
            const endLocalDateTimeUTC: number = DateUtilities.toLocalTime(event.end, offset);
            const startLocalDate = new Date(startLocalDateTimeUTC);
            const endLocalDate = new Date(endLocalDateTimeUTC);
            const startLocalDateTimeString =
                DateUtilities.transformLocalDateToDateString(startLocalDate);
            const endLocalDateTimeString =
                DateUtilities.transformLocalDateToDateString(endLocalDate);

            if (!event.recursion) {
                return {
                    ...event,
                    startLocalDateTime: startLocalDateTimeString,
                    endLocalDateTime: endLocalDateTimeString
                };
            }

            if (event.recursion && !event.recursion.endDateLocal && event.recursion.endDate) {
                const endRecursionLocalDateTimeUTC: number = DateUtilities.toLocalTime(
                    event.recursion.endDate,
                    offset
                );
                const endRecursionLocalDate = new Date(endRecursionLocalDateTimeUTC);
                const endRecursionLocalDateTimeString =
                    DateUtilities.transformLocalDateToDateString(endRecursionLocalDate);

                const recursion = {
                    ...event.recursion,
                    endDateLocal: endRecursionLocalDateTimeString
                };

                return {
                    ...event,
                    startLocalDateTime: startLocalDateTimeString,
                    endLocalDateTime: endLocalDateTimeString,
                    recursion
                };
            }

            return event;
        });
    }
}
