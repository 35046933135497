import { Injectable } from '@angular/core';
import { BannerflowApiService } from '@core/services/bannerflow';
import { PublishApiService } from '@core/services/publish';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    loadLocalizationsSuccess,
    loadPublishOptionsSuccess,
    loadSizesSuccess
} from './brand.actions';

@Injectable()
export class BrandEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly bannerflowService: BannerflowApiService,
        private readonly publishService: PublishApiService
    ) {}

    public loadSizes$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Sizes'),
            switchMap(() =>
                this.bannerflowService
                    .getSizes()
                    .pipe(map((sizes: ISize[]) => loadSizesSuccess({ sizes })))
            )
        )
    );

    public loadLocalizations$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Localizations'),
            switchMap(() =>
                this.bannerflowService
                    .getLocalizations()
                    .pipe(
                        map((localizations: ILocalization[]) =>
                            loadLocalizationsSuccess({ localizations })
                        )
                    )
            )
        )
    );

    public loadPublishOptions$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Publish Options'),
            switchMap(() =>
                from(this.publishService.getPublishOptionConfigurations()).pipe(
                    map((publishOptions: PublishOptionConfiguration[]) =>
                        loadPublishOptionsSuccess({ publishOptions })
                    )
                )
            )
        )
    );
}
