import { RoleEnum } from './enums/role.enum';
import { IRoleNameDescription, IRole } from './user.interface';

class RoleNameDescription implements IRoleNameDescription {
    public name: string;
    public description: string;

    public deserialize(json: any): RoleNameDescription {
        if (!json) {
            return;
        }

        const roleNameDescription: RoleNameDescription = new RoleNameDescription();

        roleNameDescription.description = json.description;
        roleNameDescription.name = json.name;

        return roleNameDescription;
    }
}

export class Role implements IRole {
    public accessAllBrands: boolean;
    public description: string;
    public name: string;
    public role: number;

    public deserialize(json: any): Role {
        if (!json) {
            return this;
        }

        this.accessAllBrands = json.accessAllBrands;
        this.description = json.description;
        this.role = json.role;
        this.name = Role.getRoleByName(RoleEnum[this.role]).name;

        return this;
    }

    public static getRoleByName(role: string): RoleNameDescription {
        const descriptions: any = {
            Admin: {
                name: 'Admin',
                description:
                    'Admins have full access to everything in the account, including adding new brands and inviting new users.'
            },
            User: {
                name: 'User',
                description:
                    'Users have full access to the brands that they have been given permission to by the admin.'
            },
            ExternalUser: {
                name: 'External User',
                description:
                    'An external user has access to the brand he or she is invited to, but not any kind of settings or account data.'
            },
            UserWithoutEditor: {
                name: 'Text Editor',
                description: 'A user without access to the banner builder.'
            },
            UserWithoutPublish: {
                name: 'Designer',
                description: 'A user without access to the Publish views.'
            },
            UserWithoutEditorWithDuplicate: {
                name: 'Text Editor Extended',
                description:
                    'A user without access to the banner builder. The user can duplicate campaigns.'
            },
            UserWithoutAccessOthersContent: {
                name: 'External Designer',
                description: 'A user without access to content that others have created.'
            },
            UserWithOnlyPublish: {
                name: 'Publisher',
                description: 'A user that only can publish banners and scheduled placeholders.'
            },
            UserWithOnlyAnalytics: {
                name: 'Analyzer',
                description: 'A user that only has access to the Analytics module.'
            },
            UserWithoutEditorWithAnalytics: {
                name: 'Text Editor with Analytics',
                description:
                    'A user without access to the banner builder but has access to analytics.'
            },
            UserWithoutDesign: {
                name: 'Publisher Extended',
                description: 'A user without access to design view.'
            }
        };

        return descriptions[role];
    }
}
