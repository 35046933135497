import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppState } from '@campaign/pages/list/store/ad-list.selectors';
import { selectUserState } from '@core/core.reducer';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';
import { IPixel } from '@shared/models/bannerflow/user/iPixel.model';
import { ILocalization, ISize } from '@shared/models/common';
import { Observable, filter, map, take } from 'rxjs';
import { BannerflowTypeGuards } from './bannerflow-type-guards';

@Injectable({
    providedIn: 'root'
})
export class BannerflowApiService {
    public v2Prefix: string;
    private v1apiPrefix: string;
    private v2apiPrefix: string;
    private brandManagerPrefix: string;

    constructor(
        private readonly http: HttpClient,
        store: Store<IAppState>
    ) {
        store
            .select(selectUserState)
            .pipe(
                filter((userState) => userState && !!userState.user),
                map((userState) => userState.user),
                take(1)
            )
            .subscribe((user) => {
                const { bannerflowUrl } = environment;
                const { brandManagerUrl } = environment;
                const accountSlug = user.account.slug;
                const brandSlug = user.brand.slug;

                this.v1apiPrefix = `${bannerflowUrl}/api/v1/b/${accountSlug}/${brandSlug}`;
                this.v2apiPrefix = `${bannerflowUrl}/api/v2/${accountSlug}/${brandSlug}`;
                this.v2Prefix = `${bannerflowUrl}/v2/${accountSlug}/${brandSlug}`;
                this.brandManagerPrefix = `${brandManagerUrl}/${accountSlug}/${brandSlug}`;
            });
    }
    /**
     * Gets logged in user info from bannerflow api
     */
    public getUser(accountSlug: string, brandSlug: string): Observable<User> {
        const url = `${environment.apiGatewayUrl}/account-access/api/${accountSlug}/${brandSlug}/users/current`;
        return this.http
            .get(url, { params: { origin: 'campaignmanager' } })
            .pipe(map((response: any) => new User().deserialize(response)));
    }

    /**
     * Gets brand localizations from bannerflow api
     */
    public getLocalizations(): Observable<ILocalization[]> {
        const url = `${this.v2apiPrefix}/localizations`;
        return this.http.get(url).pipe(
            map((response: any) => {
                if (response.some((loc) => !BannerflowTypeGuards.isLocalization(loc))) {
                    // TODO we can handle type errors without crashing probably
                    throw new TypeError('object does not match ILocalization');
                }

                return response as ILocalization[];
            })
        );
    }

    /**
     * Gets brand sizes from bannerflow api
     */
    public getSizes(): Observable<ISize[]> {
        const url = `${this.v2apiPrefix}/sizeformats`;
        return this.http.get(url).pipe(
            map((response: any) =>
                response.map((sizeFormat: any) => {
                    if (!BannerflowTypeGuards.isSizeFormat(sizeFormat)) {
                        // TODO we can handle type errors without crashing probably
                        throw new TypeError('object does not match ISizeFormat');
                    }

                    return {
                        ...sizeFormat.size,
                        name: sizeFormat.name,
                        id: sizeFormat.id
                    };
                })
            )
        );
    }

    public getPixels(): Observable<IPixel[]> {
        const url = `${this.v2apiPrefix}/brands/pixels`;
        return this.http.get<IPixel[]>(url);
    }
}
