import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Signal, signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from '@environments/environment';
import { Observable, of, shareReplay, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PullRequestEnvService {
    private readonly httpClient: HttpClient;
    public branchesSignal: Signal<string[]>;
    public currentBranchSignal = signal('main');

    constructor() {
        const handler = inject(HttpBackend);

        this.httpClient = new HttpClient(handler); // skipping interceptor
        this.branchesSignal = toSignal(
            this.getBranches().pipe(
                catchError(() => of([])),
                shareReplay(1)
            ),
            { initialValue: [] }
        );
    }

    public setBranch(branchName: string): void {
        this.currentBranchSignal.set(branchName);
    }

    public redirectToBranch(branchName: string): void {
        const url = new URL(window.location.href);
        if (branchName !== 'main') {
            url.searchParams.set('branch', branchName);
        } else {
            url.searchParams.delete('branch');
        }
        window.history.pushState({}, '', url);
        window.location.reload();
    }

    private getBranches(): Observable<string[]> {
        if (environment.stage !== 'sandbox') {
            return of([]);
        }

        return this.httpClient.get<string[]>(
            'https://sandbox-cm.bannerflow.com/deployment-slots.json'
        );
    }
}
