import { Injectable, inject } from '@angular/core';
import { CampaignApiService } from '@core/services/campaigns';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectCampaign } from '../campaign/campaign.selectors';
import { loadDraftCampaignSuccess } from '../draft-campaign/draft-campaign.actions';
import {
    selectDraftCampaign,
    selectLiveCampaign
} from '../draft-campaign/draft-campaign.selectors';
import { IAppStateWithCampaign } from '../state';
import {
    loadAutoOptimisationData,
    loadAutoOptimisationDataSuccess
} from './auto-optimisation.actions';

@Injectable()
export class AutoOptimisationEffects {
    private actions$ = inject(Actions);
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private campaignApiService = inject(CampaignApiService);


    public loadAutoOptimisationLeaders$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAutoOptimisationData),
            withLatestFrom(this.store.select(selectCampaign)),
            switchMap(([_action, campaign]) =>
                this.campaignApiService.getAutoOptimisationData(campaign.id)
            ),
            map((autoOptimisationData) => loadAutoOptimisationDataSuccess({ autoOptimisationData }))
        )
    );

    public loadAutoOptimisationData$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDraftCampaignSuccess),
            withLatestFrom(
                this.store.select(selectDraftCampaign),
                this.store.select(selectLiveCampaign)
            ),
            filter(([_action, draft, live]) => {
                // only ask backend for leaders if campaign has auto-optimisation
                const hasDraftAutoOpt = !!DecisionTreeUtilities.getTreesWithAutoOptimisation(
                    draft?.decisionTrees
                )?.length;
                const hasLiveAutoOpt = !!DecisionTreeUtilities.getTreesWithAutoOptimisation(
                    live?.decisionTrees
                )?.length;

                return hasDraftAutoOpt || hasLiveAutoOpt;
            }),
            map(() => loadAutoOptimisationData())
        )
    );
}
