import { IDraftAd, IPublishAttempt } from '@shared/models/campaigns';
import { IAdPreviewCreativeNode } from '@shared/models/campaigns/ad/ad-preview.model';

export enum PreviewViewTab {
    Unknown = 'unknown',
    Draft = 'draft',
    Published = 'published'
}

export enum PreviewEmptyState {
    NotEmpty = 'not empty',
    DraftIsEmpty = 'draft empty',
    PublishedIsEmpty = 'published empty',
    BothEmpty = 'both empty',
    Unknown = 'unknown'
}

export interface IAdPreviewCreativeNodeVM extends IAdPreviewCreativeNode {
    imageUrl: string;
}

export interface IAdPreviewVM extends IDraftAd {
    isPublished: boolean;
    publishAttempts: IPublishAttempt[];
    creativeNodes?: IAdPreviewCreativeNodeVM[];
    isPlaceholder?: boolean;
}
