import { Palette } from './palette.model';
import { IBrand } from './user.interface';
import { BrandSafetyLevel, BrandServiceLevel } from './enums';

export class Brand implements IBrand {
    public id: string;
    public name: string;
    public logo: string;
    public slug: string;
    public mediabuyerbrandsafteylevel: BrandSafetyLevel;
    public mediaBuyerServiceLevel: BrandServiceLevel;
    public accountSlug: string;
    public impressionsLastWeek: number;
    public targetUrl: string;
    public amountOfUsers: number;
    public amountOfBanners: number;
    public amountOfBannerSets: number;
    public lastModified: Date;

    public bannerImageWeightLimit: number;
    public bannerWeightLimit: number;
    public imageDefaultNameConvention: string;
    public landingPageDefaultNameConvention: string;
    public culture: string;
    public palettes: Palette[];
    public videoDefaultNameConvention: string;

    public deserialize(json: any): this {
        if (!json) {
            return null;
        }
        this.id = json.id;
        this.name = json.name;
        this.logo = json.logo;
        this.slug = json.slug;
        this.accountSlug = json.accountSlug;
        this.impressionsLastWeek = json.impressionsLastWeek;
        this.mediabuyerbrandsafteylevel = json.mediaBuyerBrandSafteyLevel;
        this.mediaBuyerServiceLevel = json.mediaBuyerServiceLevel;
        this.targetUrl = json.targetUrl;
        this.amountOfUsers = json.amountOfUsers;
        this.amountOfBanners = json.amountOfBanners;
        this.amountOfBannerSets = json.amountOfBannerSets;
        this.lastModified = json.lastModified;
        this.culture = json.culture;
        this.bannerImageWeightLimit = json.bannerImageWeightLimit / 1024;
        this.bannerWeightLimit = json.bannerWeightLimit / 1024;
        this.imageDefaultNameConvention = json.imageDefaultNameConvention;
        this.landingPageDefaultNameConvention = json.landingPageDefaultNameConvention;
        this.videoDefaultNameConvention = json.videoDefaultNameConvention;

        this.palettes = json.palettes
            ? json.palettes.map((palette: any) => new Palette().deserialize(palette))
            : [];

        return this;
    }
}
