import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { IAppState } from '@core/core.reducer';
import { TimeZoneService } from '@core/services/internal/timezone.service';
import {
    loadFallbackCreativeSetSuccess,
    loadLocalizationsSuccess,
    loadPublishOptionsSuccess,
    loadSizesSuccess
} from '@core/store/brand.actions';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BannerflowApiService } from '../bannerflow';
import { UserService } from '../bannerflow/user.service';
import { CampaignApiService } from '../campaigns';
import { PublishStatusService } from '../campaigns/publishing-status.service';
import { PublishApiService } from '../publish';

@Injectable({ providedIn: 'root' })
export class AppInitService {
    private readonly store = inject<Store<IAppState>>(Store);
    private readonly userService = inject(UserService);
    private readonly bannerflowService = inject(BannerflowApiService);
    private readonly campaignService = inject(CampaignApiService);
    private readonly timeZoneService = inject(TimeZoneService);
    private readonly publishService = inject(PublishApiService);
    private readonly title = inject(Title);
    private readonly publishStatusService = inject(PublishStatusService);

    private readonly appNonCMPTitle: string = 'Html5 Export';
    private readonly appCMPTitle: string =
        environment.stage === 'development' ? 'CM-Local' : 'Campaign Manager';

    public loadAllInParallel(): Observable<boolean> {
        if (!this.userService.isAccountCMP) {
            this.title.setTitle(this.appNonCMPTitle);

            return forkJoin([
                this.bannerflowService.getLocalizations(),
                from(this.publishService.getPublishOptionConfigurations())
            ]).pipe(
                map(([localizations, publishOptions]) => {
                    this.store.dispatch(loadLocalizationsSuccess({ localizations }));
                    this.store.dispatch(loadPublishOptionsSuccess({ publishOptions }));

                    return true;
                })
            );
        } else {
            this.title.setTitle(this.appCMPTitle);
            // loads initial brand data
            return forkJoin([
                this.bannerflowService.getSizes(),
                this.bannerflowService.getLocalizations(),
                this.campaignService.getFallbackCreatives(),
                this.campaignService.getTimezones(),
                from(this.publishService.getPublishOptionConfigurations())
            ]).pipe(
                map(([sizes, localizations, fallbackCreatives, timezones, publishOptions]) => {
                    this.timeZoneService.setTimeZones(timezones);
                    this.store.dispatch(loadSizesSuccess({ sizes }));
                    this.store.dispatch(loadLocalizationsSuccess({ localizations }));
                    this.store.dispatch(loadFallbackCreativeSetSuccess({ fallbackCreatives }));
                    this.store.dispatch(loadPublishOptionsSuccess({ publishOptions }));
                    this.publishStatusService.prepareConnection();

                    // expose store to cypress through window
                    if ((window as any).Cypress) {
                        (window as any).store = this.store;
                    }

                    return true;
                })
            );
        }
    }
}
