import {
    AnyAd,
    CreativeNodeUtilities,
    ICheckedCreativeNode,
    ICreativeNodeWithParentType,
    IValidationMap,
    NodeType
} from '@shared/models/campaigns';
import {
    IAdContent,
    IAdContentBase,
    IAutoOptimisationNodeAdContent,
    IAutoOptimisationNodeAdContentBase,
    ICreativeNodeAdContent,
    ICreativeNodeAdContentBase,
    IScheduleNodeAdContent,
    IScheduleNodeAdContentBase
} from '@shared/models/campaigns/validation';
import { SizeUtilities } from '@shared/models/common';
import { ICreativeSet } from '@shared/models/studio';
import { ICreativeSetVM } from '@shared/models/studio/creative-view-models';

export function checkIfCreativeHasDesign(
    creativeId: string | undefined,
    creativeSet: ICreativeSet | undefined
): boolean {
    if (!creativeSet) {
        return false;
    }

    const creative = creativeSet.creatives.find((_creative) => _creative.id === creativeId);

    if (!creative) {
        return false;
    }

    return !!creative.design?.id;
}

export function checkIfAdHasMultipleVersions(ad: AnyAd, creativeSet?: ICreativeSet): boolean {
    if (!creativeSet) {
        return false;
    }

    const adSizeHash = SizeUtilities.createSizeHash(ad.size);
    const creativeSetSizeHashes = creativeSet.sizes.map((size) =>
        SizeUtilities.createSizeHash(size)
    );

    const creativesSetIncludesAdSize = creativeSetSizeHashes.includes(adSizeHash);

    if (!creativesSetIncludesAdSize) {
        return false;
    }

    return (
        creativeSet.versions.filter((version) => version.localizationId === ad.localizationId)
            .length > 1
    );
}

export function checkIfAdHasMultipleSizes(ad: AnyAd, creativeSet?: ICreativeSet): boolean {
    if (!creativeSet) {
        return false;
    }

    const adSizeHash = SizeUtilities.createSizeHash(ad.size);

    return (
        creativeSet.sizes
            .map((size) => SizeUtilities.createSizeHash(size))
            .filter((sizeHash) => sizeHash === adSizeHash).length > 1
    );
}

function validateCreativeNodeAdContent(adContentBase: IAdContentBase): ICreativeNodeAdContent {
    const {
        hasDesign,
        hasFallback,
        hasInactiveCreative,
        hasMultipleSizes,
        hasMultipleVersions,
        creativeId
    } = adContentBase;

    const creativeNodeAdContentBase: ICreativeNodeAdContentBase = {
        ...adContentBase,
        rootType: NodeType.CreativeNode
    };

    const isValid: boolean = hasFallback || hasDesign;

    // true === isValid to satisfy TS union type guard
    if (true === isValid) {
        const usesCreative = hasDesign;
        const usesFallback = !hasDesign && hasFallback;

        return {
            ...creativeNodeAdContentBase,
            isValid,
            usesCreative,
            usesFallback
        };
    } else {
        // empty content without choices
        const invalidByMissingCreative =
            !hasMultipleSizes && !hasMultipleVersions && !creativeId && !hasFallback;

        const invalidByInactiveCreative = hasInactiveCreative && !hasFallback;

        const invalidByMultipleVersions = hasMultipleVersions && !creativeId && !hasFallback;

        const invalidByMultipleSizes = hasMultipleSizes && !creativeId && !hasFallback;

        return {
            ...creativeNodeAdContentBase,
            isValid,
            invalidByMissingCreative,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes
        };
    }
}

function validateAutoOptimisationAdContent(
    adContentBase: IAdContentBase,
    creativeSet: ICreativeSet
): IAutoOptimisationNodeAdContent {
    const { hasDesign, hasInactiveCreative, hasMultipleSizes, hasMultipleVersions, creativeId } =
        adContentBase;

    const isValid: boolean = hasDesign;

    const autoOptimisationAdContentBase: IAutoOptimisationNodeAdContentBase = {
        ...adContentBase,
        rootType: NodeType.AutoOptimisation
    };

    // true === isValid to satisfy TS union type guard
    if (true === isValid) {
        const usesCreative = hasDesign;

        return {
            ...autoOptimisationAdContentBase,
            isValid,
            usesCreative
        };
    } else {
        // empty content without choices
        const invalidByMissingCreative = !hasMultipleSizes && !hasMultipleVersions && !creativeId;
        const invalidByInactiveCreative = hasInactiveCreative;
        const invalidByMultipleVersions = hasMultipleVersions && !creativeId;
        const invalidByMultipleSizes = hasMultipleSizes && !creativeId;
        const invalidByMissingCreativeSet = invalidByMissingCreative && !creativeSet;

        return {
            ...autoOptimisationAdContentBase,
            isValid,
            invalidByMissingCreative,
            invalidByMissingCreativeSet,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes
        };
    }
}

function validateScheduleAdContent(
    adContentBase: IAdContentBase,
    isDefaultNode: boolean,
    hasDefault: boolean
): IScheduleNodeAdContent {
    const {
        hasDesign,
        hasFallback,
        hasInactiveCreative,
        hasMultipleSizes,
        hasMultipleVersions,
        creativeId
    } = adContentBase;

    const isValid: boolean = hasFallback || hasDefault || hasDesign;

    const scheduleAdContentBase: IScheduleNodeAdContentBase = {
        ...adContentBase,
        hasDefault,
        rootType: NodeType.Schedule,
        isDefault: isDefaultNode
    };

    if (true === isValid) {
        const usesCreative = hasDesign && !isDefaultNode; // default node can only use itself or fallback
        const usesDefault = isDefaultNode
            ? hasDesign // for default node itself
            : !hasDesign && hasDefault; // for regular(creative) node

        const usesFallback = isDefaultNode
            ? !hasDesign && hasFallback
            : !hasDesign && !hasDefault && hasFallback;

        return {
            ...scheduleAdContentBase,
            isValid,
            usesCreative,
            usesDefault,
            usesFallback
        };
    } else {
        const invalidByMissingCreative =
            !hasMultipleSizes && !hasMultipleVersions && !creativeId && !hasDefault && !hasFallback;

        const invalidByInactiveCreative = hasInactiveCreative && !hasDefault && !hasFallback;

        const invalidByMultipleVersions =
            hasMultipleVersions && !creativeId && !hasDefault && !hasFallback;

        const invalidByMultipleSizes =
            hasMultipleSizes && !creativeId && !hasDefault && !hasFallback;

        return {
            ...scheduleAdContentBase,
            isValid,
            invalidByMissingCreative,
            invalidByInactiveCreative,
            invalidByMultipleVersions,
            invalidByMultipleSizes
        };
    }
}

export function createAdContent(
    ad: AnyAd,
    creativeNode: ICreativeNodeWithParentType,
    adFallbackMap: IValidationMap,
    creativeset: ICreativeSetVM | undefined,
    defaultSiblingNode?: ICheckedCreativeNode | undefined
): IAdContent {
    const rootType = creativeNode.rootType;
    const adId = ad.id;
    const creativeId: string = creativeNode.properties[adId];
    const hasDesign: boolean = checkIfCreativeHasDesign(creativeId, creativeset);
    const hasFallback = !!adFallbackMap[adId];
    const isDefaultNode = CreativeNodeUtilities.isDefaultNode(creativeNode);

    const hasInactiveCreative: boolean = !!creativeId && !hasDesign;
    const hasMultipleVersions: boolean = checkIfAdHasMultipleVersions(ad, creativeset);
    const hasMultipleSizes: boolean = checkIfAdHasMultipleSizes(ad, creativeset);

    const adContentBaseValidation: IAdContentBase = {
        adId,
        creativeId,
        hasDesign,
        hasFallback,
        hasMultipleVersions,
        hasMultipleSizes,
        hasInactiveCreative
    };

    switch (rootType) {
        case NodeType.CreativeNode:
            return validateCreativeNodeAdContent(adContentBaseValidation);
        case NodeType.AutoOptimisation:
            return validateAutoOptimisationAdContent(adContentBaseValidation, creativeset);

        case NodeType.Schedule:
            let hasDefault = false;
            if (rootType === NodeType.Schedule && !isDefaultNode) {
                const defaultNodeAdContent = defaultSiblingNode?.adContents.find(
                    (adContent) => adContent.adId === adId
                );
                hasDefault = !!defaultNodeAdContent && defaultNodeAdContent.hasDesign;
            }
            return validateScheduleAdContent(adContentBaseValidation, isDefaultNode, hasDefault);

        default:
            throw new Error('Invalid root type passed for validation.');
    }
}
