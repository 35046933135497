import { ICreative } from '..';

export class CreativeUtilities {
    /**
     * Studios sorting of creatives in the open view, copied and modified a bit with localeCompare, should give same result.
     */
    public static sortCreativesByRatioWidthLocale(a: any, b: any): number {
        // support mapped creatives
        const creativeA: ICreative = a?.creative ? a.creative : a;
        const creativeB: ICreative = b?.creative ? b.creative : b;

        if (!creativeA || !creativeB) {
            return;
        }

        const aRatio: number = creativeA.size.width / creativeA.size.height;
        const bRatio: number = creativeB.size.width / creativeB.size.height;
        if (aRatio > bRatio) {
            return 1;
        }
        if (aRatio < bRatio) {
            return -1;
        }
        if (creativeA.size.width > creativeB.size.width) {
            return 1;
        }
        if (creativeA.size.width < creativeB.size.width) {
            return -1;
        }

        return creativeA.version.name.localeCompare(creativeB.version.name);
    }
}
