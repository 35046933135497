import {
    ICampaign,
    IDraftCampaign,
    IAd,
    IPublishAttempt,
    IPublishedAdRef,
    IFallbackCreative,
    IDraftAd
} from '@shared/models/campaigns';

/**
 * Provides deep type checking for campaign objects
 */
export class CampaignTypeGuards {
    public static isCampaign(json: any): json is ICampaign {
        return (
            'id' in json &&
            'name' in json &&
            'ads' in json &&
            'attempts' in json &&
            'status' in json &&
            this.checkAds(json.ads) &&
            this.checkAttempts(json.attempts)
        );
    }

    public static isDraftCampaign(json: any): json is IDraftCampaign {
        return 'id' in json && 'name' in json && 'size' in json;
    }

    public static isAd(json: any): json is IAd {
        return (
            'id' in json &&
            'size' in json &&
            'localizationId' in json &&
            'targetUrl' in json &&
            'creativeRefs' in json &&
            'status' in json
        );
    }

    public static isDraftAd(json: any): json is IDraftAd {
        return 'id' in json && 'size' in json && 'localizationId' in json;
    }

    public static isPublishAttempt(json: any): json is IPublishAttempt {
        return (
            'correlationId' in json &&
            'destination' in json &&
            'created' in json &&
            'completedAt' in json &&
            'status' in json &&
            'user' in json &&
            'ads' in json &&
            'created' in json &&
            this.checkPublishedAdRef(json.ads)
        );
    }

    public static isPublishAttemptAdRef(json: any): json is IPublishedAdRef {
        return 'id' in json;
    }

    public static isFallbackCreative(json: any): json is IFallbackCreative {
        return (
            'id' in json &&
            'targetUrl' in json &&
            'size' in json &&
            'version' in json &&
            json.version !== null &&
            'checksum' in json &&
            'design' in json &&
            'creativeset' in json
        );
    }

    public static checkAds(ads: any): boolean {
        return Array.isArray(ads) && ads.every((ad) => this.isAd(ad));
    }

    public static checkAttempts(attempts: any): boolean {
        return (
            Array.isArray(attempts) && attempts.every((attempt) => this.isPublishAttempt(attempt))
        );
    }
    public static checkPublishedAdRef(attemptAds: any): boolean {
        return (
            Array.isArray(attemptAds) &&
            attemptAds.every((attemptAd) => this.isPublishAttemptAdRef(attemptAd))
        );
    }
}
