export class BasicCredential {
    public key: string;
    public value: string;
    public isRequired?: boolean;
    public defaultValue?: string;
    public name?: string;
    public type?: string;

    public static deserialize(json: any): BasicCredential | null {
        if (!json) {
            return null;
        }
        const basicCredential: BasicCredential = new BasicCredential();
        basicCredential.key = json.key;
        basicCredential.value = json.value;
        basicCredential.defaultValue = json.defaultValue;
        basicCredential.name = json.name;
        basicCredential.type = json.type;
        basicCredential.isRequired = json.isRequired;

        return basicCredential;
    }

    public serialize(): BasicCredential {
        const basicCredential: BasicCredential = new BasicCredential();
        basicCredential.key = this.key;
        basicCredential.value = this.value;
        basicCredential.defaultValue = this.defaultValue;
        basicCredential.name = this.name;
        basicCredential.type = this.type;
        basicCredential.isRequired = this.isRequired;

        return basicCredential;
    }

    public clone(): BasicCredential {
        const basicCredential: BasicCredential = new BasicCredential();
        basicCredential.key = this.key;
        basicCredential.value = this.value;
        basicCredential.defaultValue = this.defaultValue;
        basicCredential.name = this.name;
        basicCredential.type = this.type;
        basicCredential.isRequired = this.isRequired;

        return basicCredential;
    }
}
