import { Injectable, inject } from '@angular/core';
import { panToAdGroup } from '@campaign/pages/editor/store/editor.actions';
import { DecisionTreeService } from '@campaign/services/decision-tree.service';
import { updateDraftCampaign } from '@campaign/store/draft-campaign/draft-campaign.actions';
import { selectDraftCampaign } from '@campaign/store/draft-campaign/draft-campaign.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { duplicateDecisionTree } from '@campaign/utilities/decision-tree-duplicator';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { IDecisionTree } from '@shared/models/campaigns';
import { replaceItem } from '@shared/utilities/immutable-array-functions';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import {
    createDecisionTrees,
    deleteDecisionTree,
    duplicateDecisionTree as duplicateDecisionTreeAction,
    reorderDecisionTrees,
    updateDecisionTree
} from './decision-tree.actions';

@Injectable()
export class DecisionTreeEffects {
    private actions$ = inject(Actions);
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private decisionTreeService = inject(DecisionTreeService);


    public createDecisionTree$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(createDecisionTrees),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            map(([action, draftCampaign]) => {
                const { decisionTrees, insertTop } = action;

                const createdDecisionTrees: IDecisionTree[] = JSON.parse(
                    JSON.stringify(decisionTrees)
                );
                createdDecisionTrees[0].adGroup.selected = true;

                const newDecisionTrees: IDecisionTree[] = insertTop
                    ? [...createdDecisionTrees, ...draftCampaign.decisionTrees]
                    : [...draftCampaign.decisionTrees, ...createdDecisionTrees];

                this.store.dispatch(panToAdGroup({ payload: true }));
                return updateDraftCampaign({
                    changes: { decisionTrees: newDecisionTrees }
                });
            })
        )
    );

    public duplicateDecisionTree$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicateDecisionTreeAction),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            map(([action, draftCampaign]) => {
                const { adGroupId } = action;

                const tree: IDecisionTree = draftCampaign.decisionTrees.find(
                    (_tree) => _tree.adGroup.id === adGroupId
                );
                const clonedTree: IDecisionTree = duplicateDecisionTree(tree);
                clonedTree.adGroup.selected = true;

                this.store.dispatch(panToAdGroup({ payload: true }));

                return updateDraftCampaign({
                    changes: { decisionTrees: [...draftCampaign.decisionTrees, clonedTree] }
                });
            })
        )
    );

    public updateDraftOnDeleteDecisionTree$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteDecisionTree),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            map(([action, draftCampaign]) => {
                const { adGroupId } = action;

                return updateDraftCampaign({
                    changes: {
                        decisionTrees: draftCampaign.decisionTrees.filter(
                            (tree) => tree.adGroup.id !== adGroupId
                        )
                    }
                });
            })
        )
    );

    public updateDraftonUpdateDecisionTree$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDecisionTree),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            map(([action, draftCampaign]) => {
                const { decisionTree } = action;

                const index: number = draftCampaign.decisionTrees.findIndex(
                    (tree) => tree.adGroup.id === decisionTree.adGroup.id
                );

                return updateDraftCampaign({
                    changes: {
                        decisionTrees: replaceItem(draftCampaign.decisionTrees, decisionTree, index)
                    }
                });
            })
        )
    );

    public reorderDecisionTrees$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(reorderDecisionTrees),
            map((action) => {
                const { decisionTrees } = action;

                return updateDraftCampaign({
                    changes: {
                        decisionTrees
                    }
                });
            })
        )
    );
}
