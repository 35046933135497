import { Injectable, inject } from '@angular/core';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import {
    AdCreativePairUtilities,
    AdUtilities,
    IAdCreativePair,
    IDraftAd,
    ICreativeNode,
    CreativeNodeUtilities
} from '@shared/models/campaigns';
import { ICreativeVersion } from '@shared/models/studio';
import { ICreativeSetVM, ICreativeVM } from '@shared/models/studio/creative-view-models';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdCreativePairingService {
    private cachedCreativesetService = inject(CachedCreativesetService);


    public async getSingleMatchAdCreativePairsForCreativeNode(
        node: ICreativeNode,
        ads: IDraftAd[]
    ): Promise<IAdCreativePair[]> {
        const creativeset: ICreativeSetVM = await lastValueFrom(
            this.cachedCreativesetService.getCreativesetById(node.creativesetId).pipe(take(1))
        );

        let accumulatedPairs: IAdCreativePair[] = [];
        const adsGroupedByLanguageId: Map<string, IDraftAd[]> = AdUtilities.groupByLanguageId(ads);

        Array.from(adsGroupedByLanguageId.keys()).forEach((localizationId) => {
            const filteredAds: IDraftAd[] = adsGroupedByLanguageId.get(localizationId);
            const filtertedCreatives: ICreativeVM[] = creativeset.creatives.filter(
                (creative) => creative.version.localization.id === localizationId
            );

            const adCreativePairs: AdCreativePairUtilities = new AdCreativePairUtilities(
                filteredAds,
                filtertedCreatives
            );
            let currentPairs: IAdCreativePair[] = [];

            // we have selected a specific version before
            const selectedVersion: ICreativeVersion | undefined =
                CreativeNodeUtilities.extractVersionFromCreativeNode(node, filtertedCreatives);

            if (selectedVersion) {
                currentPairs = adCreativePairs.getSingleMatchingPairsForVersion(selectedVersion.id);
            }

            accumulatedPairs = [...accumulatedPairs, ...currentPairs];
        });

        return accumulatedPairs;
    }
}
