import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ICreativeVersion } from '@shared/models/studio';
import { IAppStateWithEditor, IEditorState } from './editor.state';

export const selectEditorState: MemoizedSelector<IAppStateWithEditor, IEditorState> =
    createFeatureSelector<IEditorState>('editor');

export const selectCreativeSetPreviewShow: MemoizedSelector<IAppStateWithEditor, boolean> =
    createSelector(selectEditorState, (state: IEditorState) => state.showCreativeSetPreview);

export const selectSelectedCreativeVersion: MemoizedSelector<
    IAppStateWithEditor,
    ICreativeVersion
> = createSelector(selectEditorState, (state: IEditorState) => state.selectedCreativeVersion);

export const selectEditorScroll: MemoizedSelector<IAppStateWithEditor, number> = createSelector(
    selectEditorState,
    (state: IEditorState) => state.editorScroll
);

export const selectPanToAdGroup: MemoizedSelector<IAppStateWithEditor, boolean> = createSelector(
    selectEditorState,
    (state: IEditorState) => state.panToAdGroup
);

export const selectSelectedCreativeNode = createSelector(
    selectEditorState,
    (state: IEditorState) => state.selectedCreativeNode
);
