import { ObjectID } from 'bson';
import { CreativeNodeUtilities } from '../creative-node/creative-node-utilities';
import { AnyNode, NodeType, DEFAULT_NODE_SUFFIX } from '../node';
import { IScheduleEvent } from './schedule-event';
import { ScheduleEventUtilities } from './schedule-event-utilities';
import { IScheduleNode } from './schedule-node';
import { ITimeZone } from '.';
import { ICreativeNode } from '../creative-node';

export class ScheduleNodeUtilities {
    public static isScheduleNode(node: AnyNode): node is IScheduleNode {
        return node?.type === NodeType.Schedule;
    }

    public static createNode(defaultTimeZone: ITimeZone, childNode?: AnyNode): IScheduleNode {
        // Creates a new event from start of today to end of today
        const eventColor: string = ScheduleEventUtilities.getNextUnusedColor([]);
        const scheduleEvent: IScheduleEvent = ScheduleEventUtilities.createDefault(
            defaultTimeZone.offset,
            eventColor,
            '(No title)'
        );

        const scheduleNodeId: string = new ObjectID().toString();
        let child: AnyNode = childNode || CreativeNodeUtilities.create();
        child = CreativeNodeUtilities.assignParentIdToNode(child, scheduleNodeId);

        const defaultChild: AnyNode = {
            ...CreativeNodeUtilities.create(),
            id: `${scheduleNodeId}${DEFAULT_NODE_SUFFIX}`,
            parentId: scheduleNodeId
        };

        // because of the layout hack we can't put child node in children
        const node: IScheduleNode = {
            id: scheduleNodeId,
            type: NodeType.Schedule,
            name: 'Schedule',
            timeZoneId: defaultTimeZone.id,
            properties: {
                [scheduleEvent.id]: child.id
            },
            events: [scheduleEvent],
            children: [child, defaultChild]
        };

        return node;
    }

    public static getDefaultOfScheduleNode(node: AnyNode): ICreativeNode {
        if (!ScheduleNodeUtilities.isScheduleNode(node)) {
            return;
        }

        return node.children.find((child) =>
            CreativeNodeUtilities.isDefaultNode(child)
        ) as ICreativeNode;
    }
}
