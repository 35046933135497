import {
    IAdGroup,
    AnyNode,
    IValidationMap,
    IDraftAd,
    CreativeNodeUtilities,
    ICreativeNode,
    IAd,
    IPublishAttempt,
    AdAttemptStatus
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { ICreativeSet } from '@shared/models/studio';
import { ICreativeSetVM, ICreativeVM } from '@shared/models/studio/creative-view-models';

export type AdPropertiesMap = Map<string, string[]>;

export function prepateEmptyAdsIdMap(ads: IDraftAd[]): AdPropertiesMap {
    return ads.reduce((result: AdPropertiesMap, current: IDraftAd) => {
        result.set(current.id, []);
        return result;
    }, new Map<string, string[]>());
}

export function fillAdsMapWithProperties(
    nodes: AnyNode[],
    emptyAdsMap: AdPropertiesMap
): AdPropertiesMap {
    return nodes.reduce((result: Map<string, string[]>, current: ICreativeNode) => {
        Object.keys(current.properties).forEach((adId: string) => {
            result.set(
                adId,
                result.has(adId)
                    ? [...new Set([...result.get(adId), current.properties[adId]])]
                    : [current.properties[adId]]
            );
        });

        return result;
    }, new Map(emptyAdsMap));
}

export function createHeavyVideoMapForCreativeSets(
    creativeSetMap: Map<string, ICreativeSetVM>
): Map<string, boolean> {
    return [...creativeSetMap.keys()].reduce(
        (result: Map<string, boolean>, currentCreativeSetId: string) => {
            creativeSetMap.get(currentCreativeSetId).creatives.forEach((creative: ICreativeVM) => {
                result.set(creative?.id, !!creative.design?.hasHeavyVideo);
            });

            return result;
        },
        new Map<string, boolean>()
    );
}

export function generateAdHeavyVideoMapForNode(
    adGroup: IAdGroup,
    root: AnyNode,
    creativeSetMap: Map<string, ICreativeSet>
): IValidationMap {
    const allNodes: AnyNode[] = DecisionTreeUtilities.getAllDescendants([root]);
    const nodes = allNodes.filter(
        (node) =>
            CreativeNodeUtilities.isCreativeNode(node) && !CreativeNodeUtilities.isDefaultNode(node)
    );

    const emptyAdsMap = prepateEmptyAdsIdMap(adGroup.ads);
    const adsPropertiesMap = fillAdsMapWithProperties(nodes, emptyAdsMap);

    const heavyVideoCreativesMap = createHeavyVideoMapForCreativeSets(creativeSetMap);

    return [...adsPropertiesMap.keys()].reduce((result: IValidationMap, currentAdId: string) => {
        result[currentAdId] = adsPropertiesMap
            .get(currentAdId)
            .some((creativeId) => !!heavyVideoCreativesMap.get(creativeId));
        return result;
    }, {});
}

export function checkIfMapContainHeavyVideo(adHeavyVideoMap: IValidationMap) {
    return Object.keys(adHeavyVideoMap).some((adId) => !!adHeavyVideoMap[adId]);
}

export function createCreativeSetHeavyVideoSizeIdMap(creativeSet: ICreativeSetVM): IValidationMap {
    return creativeSet.creatives.reduce((result: IValidationMap, current) => {
        result[current.size?.id] = current.design?.hasHeavyVideo;
        return result;
    }, {});
}

export function createCreativeSetHeavyVideoSizeHashMap(
    creativeSet: ICreativeSetVM
): IValidationMap {
    return creativeSet.creatives.reduce((result: IValidationMap, current) => {
        const currentHash = `${current.size.width}x${current.size.height}`;
        // For now we are assigning the first size that matches
        // https://bannerflow.atlassian.net/browse/WW-3997
        if (result.hasOwnProperty(currentHash)) {
            return result;
        }
        result[currentHash] = !!result[currentHash] || current.design.hasHeavyVideo;
        return result;
    }, {});
}

export function getAdsSizeHashes(ads: IDraftAd[]): string[] {
    return ads.map((ad) => `${ad.size.width}x${ad.size.height}`);
}

export function checkWillAdGroupContainHeavyVideo(
    adGroup: IAdGroup,
    creativeSet: ICreativeSetVM
): boolean {
    // function uses hashes beacouse sizes with the same dimmesions in ad group and creative
    // might have different ids :|
    const adGroupSizeHashes = getAdsSizeHashes(adGroup.ads);
    const creativeSetHeavyVideoSizeHashMap = createCreativeSetHeavyVideoSizeHashMap(creativeSet);
    return adGroupSizeHashes.some((sizeId) => !!creativeSetHeavyVideoSizeHashMap[sizeId]);
}

export function generateAdHeavyVideoMapForLiveAds(ads: IAd[], creativeSets: ICreativeSet[]) {
    const creativeSetsMap = creativeSets.reduce((result: Map<string, ICreativeSet>, current) => {
        result.set(current.id, current);
        return result;
    }, new Map<string, ICreativeSet>());

    const heavyVideoCreativesMap = createHeavyVideoMapForCreativeSets(creativeSetsMap);

    return ads.reduce((result: IValidationMap, current) => {
        const isCurrentAdHeavy = current.creativeRefs
            .map((ref) => ref.creativeId)
            .some((creativeId) => !!heavyVideoCreativesMap.get(creativeId));

        result[current.id] = isCurrentAdHeavy;
        return result;
    }, {});
}

export function getAdsPublishedToNonHeavyVideoSupport(
    attemps: IPublishAttempt[],
    publishOptions: PublishOptionConfiguration[]
): string[] {
    const nonSupportPublishOptionsIds = publishOptions
        .filter((option) => !option.supportsHeavyVideo)
        .map((option) => option.id);

    return attemps
        .filter((attempt) =>
            nonSupportPublishOptionsIds.some((optionId) => attempt.configurationId === optionId)
        )
        .reduce((result: string[], current) => {
            const currentAds = current.ads
                .filter(
                    (ad) =>
                        AdAttemptStatus.Success === ad.status ||
                        AdAttemptStatus.Pending === ad.status
                )
                .map((ad) => ad.id);

            return [...new Set([...result, ...currentAds])];
        }, []);
}
