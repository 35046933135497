import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    RouterStateSnapshot,
    UrlSegment
} from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { CYPRESS_FLAG_KEY } from '@cypress/cypress-flag.const';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppAuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(private readonly auth0Guard: Auth0Guard) {}

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
        if (this.isCypress()) {
            return true;
        }
        return this.auth0Guard.canLoad(route, segments);
    }
    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.isCypress()) {
            return true;
        }
        return this.auth0Guard.canActivate(route, state);
    }
    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.isCypress()) {
            return true;
        }
        return this.auth0Guard.canActivateChild(childRoute, state);
    }

    private isCypress(): boolean {
        return !!localStorage.getItem(CYPRESS_FLAG_KEY);
    }
}
