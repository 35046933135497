import { PublishOption } from './publish-option.model';

export class Destination {
    public id: string;
    public name: string;
    public isEnabled: boolean;
    public exclusiveFor: string;
    public logo: string;
    public icon: string;
    public externalSupportArticle?: string;
    public internalSupportArticle?: string;
    public publishOptions?: PublishOption[] = [];

    public static deserialize(json: any): Destination | null {
        if (!json) {
            return null;
        }
        const destination: Destination = new Destination();
        destination.id = json.id;
        destination.name = json.name;
        destination.isEnabled = json.isEnabled;
        destination.exclusiveFor = json.exclusiveFor;
        destination.logo = json.logo;
        destination.icon = json.icon;
        destination.externalSupportArticle = json.externalSupportArticle;
        destination.internalSupportArticle = json.internalSupportArticle;

        if (json.publishOptions) {
            destination.publishOptions = json.publishOptions.map((publishOptionReference: any) =>
                PublishOption.deserialize(publishOptionReference)
            );
        }

        return destination;
    }

    /**
     * Serialize destination into an object that can be sent to server through our api service
     */
    public serialize(): Destination {
        const destination: Destination = new Destination();

        destination.id = this.id;
        destination.name = this.name;
        destination.isEnabled = this.isEnabled;
        destination.exclusiveFor = this.exclusiveFor;
        destination.logo = this.logo;
        destination.icon = this.icon;
        destination.externalSupportArticle = this.internalSupportArticle
            ? this.externalSupportArticle
            : undefined;
        destination.internalSupportArticle = this.externalSupportArticle
            ? this.internalSupportArticle
            : undefined;
        destination.publishOptions = undefined;

        return destination;
    }
}

export class CredentiatilStatusResponse {
    public status: string;
    public message: string;
}
