import { ICreativeNode } from '@shared/models/campaigns';
import {
    CreativeNodeActions,
    selectCreativeNode,
    clearSelectedCreativeNode
} from './creative-node.actions';

export function selectedCreativeNode(
    state: ICreativeNode,
    action: CreativeNodeActions
): ICreativeNode | undefined {
    switch (action.type) {
        case selectCreativeNode.type:
            return action.payload;

        case clearSelectedCreativeNode.type:
            return undefined;

        default:
            return state;
    }
}
