import { IAccount } from './user.interface';

export class Account implements IAccount {
    public id: string;
    public name: string;
    public slug: string;
    public features: string[];

    public deserialize(json: any): Account {
        if (!json) {
            return null;
        }

        const i: Account = new Account();
        i.id = json.id;
        i.name = json.name;
        i.slug = json.slug;
        i.features = json.features;

        return i;
    }
}
