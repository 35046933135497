import { ICreativeSet } from '@shared/models/studio';
import { ICampaign } from '../campaign';
import { IDecisionTree } from './decision-tree';
import { DecisionTreeCreateUtilities } from './decision-tree-create-utilities';
import { DecisionTreeUtilities } from './decision-tree-utilities';
import { IDraftCampaign } from './draft-campaign';
import { AnyNode, AutoOptimisationNodeUtilities, ICreativeNode, isDecisionNode } from './nodes';

export class DraftCampaignUtilities {
    public static createFromCampaign(
        campaign: ICampaign,
        creativeSets: ICreativeSet[]
    ): IDraftCampaign {
        const decisionTrees: IDecisionTree[] =
            DecisionTreeCreateUtilities.createFromAdsAndCreativeSets(campaign.ads, creativeSets);

        // Return draft campaign
        return {
            id: campaign.id,
            decisionTrees,
            // isDirty set to true because of backend roundtrip data returned true
            isDirty: true
        };
    }

    public static findNodeInDraftCampaign(decisionTres: IDecisionTree[], nodeId: string): AnyNode {
        const allRoots: AnyNode[] = decisionTres.map((tree) => tree.root);

        return DecisionTreeUtilities.getAllDescendants(allRoots).find((node) => node.id === nodeId);
    }

    public static hasAONodes(draftCampaign: IDraftCampaign): boolean {
        return (
            draftCampaign.decisionTrees.filter((tree: IDecisionTree) =>
                AutoOptimisationNodeUtilities.isAONode(tree.root)
            ).length > 0
        );
    }

    public static getCreativeSetsIdsUsedInDraftCampaign(draftCampaign: IDraftCampaign): string[] {
        return draftCampaign.decisionTrees.reduce((ids: string[], currentTree: IDecisionTree) => {
            let currentIds: string[] = [];

            if (isDecisionNode(currentTree.root)) {
                currentIds = currentTree.root.children
                    .map((child) => (child as ICreativeNode).creativesetId)
                    .filter(Boolean);
            } else {
                currentIds = [(currentTree.root as ICreativeNode).creativesetId].filter(Boolean);
            }

            return [...new Set([...ids, ...currentIds])];
        }, []);
    }

    public static findRootTreeInDraftCampaignById(
        draftCampaign: IDraftCampaign,
        nodeId: string,
        throwIfNotFound = true
    ): IDecisionTree | undefined {
        const rootTree = draftCampaign.decisionTrees.find((tree) => tree.root.id === nodeId);

        if (!rootTree && throwIfNotFound) {
            throw new Error(
                `Root tree with id: ${nodeId} was not found in a given draft campaign.`
            );
        }

        return rootTree;
    }
}
