import {
    IPublishAttempt,
    IPublishedAdRef,
    AdAttemptStatus,
    IAdPublishStatusUpdate,
    AdStatus
} from '@shared/models/campaigns';

export class PublishAttemptUtilities {
    public static getSuccessfulAttemptsOfAd(
        attempts: IPublishAttempt[],
        adId: string
    ): IPublishAttempt[] {
        return attempts.filter((attempt) => {
            const adRef: IPublishedAdRef = attempt.ads.find((_adRef) => _adRef.id === adId);

            return adRef ? adRef.status === AdAttemptStatus.Success : false;
        });
    }

    public static getPublishedAdIdsFromAttempts(
        attempts: IPublishAttempt[],
        adIds: string[]
    ): string[] {
        return adIds.filter((id) => this.getSuccessfulAttemptsOfAd(attempts, id).length > 0);
    }

    public static getPublishedAdIdsFromStatusUpdate(
        adPublishStatuses: IAdPublishStatusUpdate[]
    ): string[] {
        return adPublishStatuses
            .filter((adStatusInfo) => adStatusInfo.status === AdStatus.Published)
            .map((publishedAdStatusInfo) => publishedAdStatusInfo.adId);
    }

    public static checkIfAnyIdMatchesPublishedAdIds(
        publishedIds: string[],
        idsToCheck: string[]
    ): boolean {
        return idsToCheck.some((id) => publishedIds.includes(id));
    }

    public static getAttemptsOfAd(attempts: IPublishAttempt[], adId: string): IPublishAttempt[] {
        // uses filter
        return attempts.filter((attempt) => {
            const adRef: IPublishedAdRef = attempt.ads.find((_adRef) => _adRef.id === adId);

            return !!adRef;
        });
    }
}
