import { createAction, props } from '@ngrx/store';

export const showLeftPanel = createAction('[Left Panel] Show');
export const hideLeftPanel = createAction('[Left Panel] Hide');
export const toggleLeftPanel = createAction('[Left Panel] Toggle');
export const togglePinLeftPanel = createAction('[Left Panel] Toggle Pin');
export const setLeftPanelResizing = createAction(
    '[Left Panel] Set Resizing',
    props<{ isResizing: boolean }>()
);
export const setLeftPanelWidth = createAction(
    '[Left Panel] Set CreativeSet Panel width',
    props<{ width: number }>()
);

export type LeftPanelActions =
    | ReturnType<typeof showLeftPanel>
    | ReturnType<typeof hideLeftPanel>
    | ReturnType<typeof toggleLeftPanel>
    | ReturnType<typeof togglePinLeftPanel>
    | ReturnType<typeof setLeftPanelResizing>
    | ReturnType<typeof setLeftPanelWidth>;
