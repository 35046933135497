import {
    AnyNode,
    CreativeNodeUtilities,
    DEFAULT_NODE_SUFFIX,
    IDecisionTree,
    IDraftAd,
    NodeType
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { ObjectID } from 'bson';

function replaceKey(object: any, newKey: string, oldKey: string): any {
    object = { ...object, [newKey]: object[oldKey] };
    delete object[oldKey];

    return object;
}

/**
 * Duplicates given decision tree, creating new ids and nodes
 */
export function duplicateDecisionTree(decisionTree: IDecisionTree): IDecisionTree {
    // clone model
    const clonedTree: IDecisionTree = JSON.parse(JSON.stringify(decisionTree));
    const ads: IDraftAd[] = clonedTree.adGroup.ads;

    // new id for ad group
    clonedTree.adGroup.id = new ObjectID().toString();
    clonedTree.adGroup.name = `Copy of ${clonedTree.adGroup.name}`;

    // create new ad ids and save to dictionary we will use it later
    const cloneAdIdMap: Map<string, string> = new Map(
        ads.map((ad) => [ad.id, new ObjectID().toString()])
    );
    // replace with cloned ids
    ads.forEach((ad) => (ad.id = cloneAdIdMap.get(ad.id)));

    // get all nodes
    const nodes: AnyNode[] = DecisionTreeUtilities.getAllDescendants([clonedTree.root]);
    // create new node ids and save to dictionary we will use it later
    const cloneNodeIdMap: Map<string, string> = new Map(
        nodes.map((node) => [node.id, new ObjectID().toString()])
    );

    // update all the nodes with new ids and properties
    nodes.forEach((node) => {
        // everyone gets new a parentId
        node.parentId = cloneNodeIdMap.get(node.parentId);

        // everyone gets new a node id except default nodes
        node.id = CreativeNodeUtilities.isDefaultNode(node)
            ? `${node.parentId}${DEFAULT_NODE_SUFFIX}`
            : cloneNodeIdMap.get(node.id);

        switch (node.type) {
            case NodeType.CreativeNode:
                // replace ad ids in creative properties
                cloneAdIdMap.forEach(
                    (newKey, key) => (node.properties = replaceKey(node.properties, newKey, key))
                );

                break;
            case NodeType.Schedule:
                node.events.forEach((event) => {
                    // first fix the property values
                    const connectedNodeId: string = node.properties[event.id];
                    node.properties[event.id] = cloneNodeIdMap.get(connectedNodeId);

                    // then fix the key of properties
                    const newEventId: string = new ObjectID().toString();
                    node.properties = replaceKey(node.properties, newEventId, event.id);

                    // finally set the new eventId
                    event.id = newEventId;
                });

                break;
            case NodeType.AutoOptimisation:
                node.variants.forEach((variant) => {
                    // first fix the property values
                    const connectedNodeId: string = node.properties[variant.id];
                    node.properties[variant.id] = cloneNodeIdMap.get(connectedNodeId);

                    // then fix the key of properties
                    const newVariantId: string = new ObjectID().toString();
                    node.properties = replaceKey(node.properties, newVariantId, variant.id);

                    // finally set the new variantId
                    variant.id = newVariantId;
                });

                break;
            default:
                break;
        }
    });

    return clonedTree;
}
