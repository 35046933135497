import { ICreativeVersion } from '@shared/models/studio';
import {
    CreativeSetPreviewActions,
    showCreativeSetPreview as showPreview,
    hideCreativeSetPreview,
    setSelectedCreativeVersion
} from './creative-set-preview.actions';

export function showCreativeSetPreview(
    state: boolean = false,
    action: CreativeSetPreviewActions
): boolean {
    switch (action.type) {
        case showPreview.type:
            return true;

        case hideCreativeSetPreview.type:
            return false;

        default:
            return state;
    }
}

export function selectedCreativeVersion(
    state: ICreativeVersion,
    action: CreativeSetPreviewActions
): ICreativeVersion {
    if (action.type === setSelectedCreativeVersion.type) {
        return action.payload;
    } else {
        return state;
    }
}
