import { createAction, props } from '@ngrx/store';
import { ICreativeVersion } from '@shared/models/studio';

export const showCreativeSetPreview = createAction('[Creative Set Preview] Show');
export const hideCreativeSetPreview = createAction('[Creative Set Preview] Hide');
export const setSelectedCreativeVersion = createAction(
    '[Creative Set Preview] Set selected creative version',
    props<{ payload: ICreativeVersion }>()
);

export type CreativeSetPreviewActions =
    | ReturnType<typeof showCreativeSetPreview>
    | ReturnType<typeof hideCreativeSetPreview>
    | ReturnType<typeof setSelectedCreativeVersion>;
