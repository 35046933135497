import { AdStatus, IPublishDestination, PublishAttemptStatus } from '@shared/models/campaigns';
import { ISize } from '@shared/models/common';

export interface IPagination {
    totalAmountOfItems: number;
    totalAmountOfPages: number;
    currentPage: number;
    currentPageSize: number;
}

export interface IAdsPagination {
    ads: IListAd[];
    totalAmountOfItems: number;
    totalAmountOfPages: number;
    currentPage: number;
}

export interface IListAd {
    id: string;
    size: ISize;
    language: IListLanguage;
    adGroup: IListAdGroup;
    creativeSets: IListCreativeSet[];
    destinations: IPublishDestination[];
    targetUrl?: string;
    status: AdStatus;
    publishAttempts: IListPublishAttempt[];
    hasFallback: boolean;
    adType: AdType;
}

export interface IListAdGroup {
    id: string;
    name: string;
}
export interface IListCreativeSet {
    id: string;
    name?: string;
}

interface IListLanguage {
    id: string;
    cultureLanguage: string;
    cultureCode: string;
}

export interface IListPublishAttempt {
    status: PublishAttemptStatus;
    destination: IPublishDestination;
    created?: string;
    completedAt: string;
    isMigrated?: boolean;
}

export enum AdType {
    CreativeNode = 1,
    Schedule = 2,
    AutoOptimisation = 3
}

export interface IAdPublishState {
    adId: string;
    status: AdStatus;
    destinations: IPublishDestination[];
}

export type AdPublishStateMap = Record<string, IAdPublishState>;

// The base options have to be in order in the dropdown, no duplicates
export const MIN_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE = 25;

export const PAGE_SIZES = [MIN_PAGE_SIZE, DEFAULT_PAGE_SIZE, 50, 100];
