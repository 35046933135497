import { ILocalization } from '@shared/models/common';
import { ICreativeVersion } from '@shared/models/studio';

export class LanguageUtilities {
    /**
     * Sorts alphabetically
     */
    public static readonly sortFn = (a: ILocalization, b: ILocalization): number =>
        a.name.localeCompare(b.name);
}

export class VersionUtilies {
    /**
     * Sorts alphabetically
     */
    public static readonly sortFn = (a: ICreativeVersion, b: ICreativeVersion): number =>
        a.name.localeCompare(b.name);

    /**
     * Sorts by language alphabetically
     */
    public static readonly sortByLanguageFn = (
        a: ICreativeVersion,
        b: ICreativeVersion
    ): number => {
        try {
            return a.injectedLanguage.name.localeCompare(b.injectedLanguage.name);
        } catch {
            return 0;
        }
    };
}
