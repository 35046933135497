import { Component, OnInit } from '@angular/core';
import { UILogoComponent } from '@bannerflow/ui';

@Component({
    selector: 'not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
    imports: [UILogoComponent]
})
export class NotFoundPageComponent implements OnInit {
    constructor() {}

    public ngOnInit() {}
}
