import { Destination } from './destination.model';
import { ParametersDictionary } from './parameterDictionary.model';
import { PublishAd } from './publish-ad.model';
import { SupportedAdType } from './publish-option-configuration.model';
import { IPublicationSpecification } from './publish-specification';

export class PublicationSpecification implements IPublicationSpecification {
    public publishOptionConfigurationId: string;
    public ads: PublishAd[];
    public parameters?: ParametersDictionary;
    public destination: Destination;
    public supportedAdType: SupportedAdType;

    public deserialize(json: any): this {
        this.publishOptionConfigurationId = json.publishOptionConfigurationId;
        this.ads = json.ads;
        this.parameters = json.parameters || undefined;
        this.destination = json.destination;
        this.supportedAdType = json.supportedAdType || undefined;

        if (json.parameters && json.parameters.publishWithBackupImage !== undefined) {
            this.parameters.publishWithBackupImage = json.parameters.publishWithBackupImage;
        }
        return this;
    }
}
