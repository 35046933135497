import { IAutoOptimisationData } from '@shared/models/campaigns';
import { createReducer, on } from '@ngrx/store';
import {
    loadAutoOptimisationData,
    loadAutoOptimisationDataSuccess
} from './auto-optimisation.actions';

export interface IAutoOptimisationState {
    autoOptimisationData: IAutoOptimisationData[];
    adGroupIds: string[];
}

export const initialState: IAutoOptimisationState = {
    autoOptimisationData: [],
    adGroupIds: []
};

export const autoOptimisationReducer = createReducer(
    initialState,
    on(loadAutoOptimisationData, (state) => state),
    on(loadAutoOptimisationDataSuccess, (state, { autoOptimisationData }) => {
        if (!autoOptimisationData || autoOptimisationData.length === 0) {
            return state;
        }

        return {
            ...state,
            autoOptimisationData
        };
    })
);
