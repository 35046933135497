import { ISize, SizeHash } from '@shared/models/common';

export class SizeUtilities {
    /**
     * Checks if given sizes are duplicate
     * @param sizeA
     * @param sizeB
     * @param compareId optional, when given it compares the size ids
     */
    public static isSameSize(sizeA: ISize, sizeB: ISize, compareId?: boolean): boolean {
        if (compareId) {
            return (
                sizeA.width === sizeB.width &&
                sizeA.height === sizeB.height &&
                sizeA.id === sizeB.id
            );
        } else {
            return sizeA.width === sizeB.width && sizeA.height === sizeB.height;
        }
    }

    /**
     * Sorts sizes by width & height in ascending order
     */
    public static readonly sortFn = (a: ISize, b: ISize): number => {
        const widthCompare: number = a.width - b.width;
        const heightCompare: number = a.height - b.height;

        return widthCompare || heightCompare;
    };

    public static createSizeHash(size: ISize): SizeHash {
        const { width, height } = size;

        return `${width}x${height}`;
    }
}
