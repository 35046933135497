import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { selectCampaign } from '@campaign/store/campaign/campaign.selectors';
import {
    selectDraftCampaign,
    selectLiveCampaign
} from '@campaign/store/draft-campaign/draft-campaign.selectors';
import { UserService } from '@core/services/bannerflow/user.service';
import { CampaignApiService } from '@core/services/campaigns';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import { PullRequestEnvService } from '@core/services/pull-request-env/pull-request.service';
import {
    selectBrandFallbackCreativeSetId,
    selectBrandLocalizations
} from '@core/store/brand.selectors';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import {
    AdAttemptStatus,
    IAdGroup,
    ICampaign,
    IDecisionTree,
    IDraftCampaign,
    IPublishAttempt,
    IPublishedAdRef,
    NodeType,
    ScheduleNodeUtilities
} from '@shared/models/campaigns';
import {
    IAdPreviewCreativeNode,
    IAdPreviewRequest,
    IAdPreviewResponse,
    PreviewType
} from '@shared/models/campaigns/ad/ad-preview.model';
import { PublishAttemptUtilities } from '@shared/models/campaigns/campaign/publish-attempt-utilities';
import { ILocalization } from '@shared/models/common';
import { ICreativeVM } from '@shared/models/studio/creative-view-models';
import { DateUtilities } from '@shared/utilities/date-utilities';
import { Observable, combineLatest, from, lastValueFrom } from 'rxjs';
import { concatMap, filter, map, take, toArray } from 'rxjs/operators';
import {
    IAdPreviewCreativeNodeVM,
    IAdPreviewVM,
    PreviewEmptyState,
    PreviewViewTab
} from './models/ad-preview-view-model';
import {
    loadAdAllPreviews,
    selectPreviewAd,
    setPreviewAdGroup,
    setPreviewTime,
    setPreviewViewTab
} from './store/ad-preview.actions';
import { selectCurrentAdGroupId, selectCurrentViewTab } from './store/ad-preview.selectors';
import { IAppStateWithAdPreview } from './store/ad-preview.state';

@Injectable({ providedIn: 'root' })
export class AdPreviewService {
    private readonly store = inject<Store<IAppStateWithAdPreview>>(Store);
    private readonly campaignApiService = inject(CampaignApiService);
    private readonly cachedCreativeSetService = inject(CachedCreativesetService);
    private readonly router = inject(Router);
    private readonly userService = inject(UserService);
    private readonly prService = inject(PullRequestEnvService);

    public async updateStoreWithParamsAndLoadPreviews(
        route: ActivatedRouteSnapshot
    ): Promise<void> {
        const adGroupId: string = route.paramMap.get('adGroupId');
        const adId: string = route.queryParamMap.get('adId');
        const time: number = parseInt(route.queryParamMap.get('time'), 10) || undefined;
        const localTime = route.queryParamMap.get('localTime');

        // we need to check if draft campaign is dirty (note: published campaign is always not dirty)
        const draftCampaign: IDraftCampaign = await this.getDraftCampaign();
        const campaign: ICampaign = await this.getCampaign();

        if (adGroupId) {
            this.store.dispatch(setPreviewAdGroup({ adGroupId }));
        }

        if (adId) {
            this.store.dispatch(selectPreviewAd({ adId }));
        }

        // if campaign is not published before or in a dirty state then show draft tab
        const showDraftTab: boolean = campaign.attempts.length === 0 || draftCampaign.isDirty;

        this.store.dispatch(
            setPreviewViewTab({
                viewTab: showDraftTab ? PreviewViewTab.Draft : PreviewViewTab.Published
            })
        );

        if (time && localTime) {
            this.store.dispatch(setPreviewTime({ time, localTime }));
        } else {
            // if there is no time parameter &&
            // if the root node is a schedule node returns default time with the timezone from schedule node
            const defaultDateFromSchedule = await this.getDefaultTimeIfScheduleExist(
                draftCampaign.isDirty
            );
            const localDefaultTime = defaultDateFromSchedule
                ? DateUtilities.transformLocalDateToDateString(defaultDateFromSchedule)
                : undefined;
            const defaultTime = defaultDateFromSchedule ? +defaultDateFromSchedule : undefined;

            this.store.dispatch(setPreviewTime({ time: defaultTime, localTime: localDefaultTime }));
        }

        // loads ad preview data
        this.store.dispatch(loadAdAllPreviews());
    }

    public async loadAdPreviewsForState(
        adGroupId: string,
        timestamp: number,
        localTime: string,
        isDraft: boolean
    ): Promise<IAdPreviewVM[]> {
        const decisionForest: IDraftCampaign = await (isDraft
            ? this.getDraftCampaign()
            : this.getPublishedCampaign());

        // published campaign can be undefined, because campaign is not published
        if (!decisionForest) {
            return [];
        }

        const campaign: ICampaign = await this.getCampaign();
        const languages: ILocalization[] = await this.getBrandLanguages();

        const decisionTree = decisionForest.decisionTrees.find(
            (tree) => tree.adGroup.id === adGroupId
        );

        // decisionTree can be undefined, because this ad is not published
        if (!decisionTree) {
            return [];
        }

        const adGroup: IAdGroup = decisionTree.adGroup;
        const timeZoneId: string =
            decisionTree.root.type === NodeType.Schedule ? decisionTree.root.timeZoneId : '';

        const adPreviews: IAdPreviewVM[] = adGroup.ads.map((ad) => ({
            ...ad,
            localization: languages.find((l) => l.id === ad.localizationId),
            isPublished: this.isAdPublished(campaign, ad.id),
            publishAttempts: PublishAttemptUtilities.getAttemptsOfAd(campaign.attempts, ad.id),
            creativeNodes: [],
            isPlaceholder: false
        }));
        const adMap: Map<string, IAdPreviewVM> = new Map(adPreviews.map((ad) => [ad.id, ad]));
        const adIds: string[] = adPreviews.map((ad) => ad.id);

        // prepare draft preview creatives
        const adPreviewResponses: IAdPreviewResponse[] = await this.getAdPreviews(campaign.id, {
            adIds,
            previewType: isDraft ? PreviewType.Draft : PreviewType.Published,
            time: timestamp || undefined,
            localTime: localTime,
            timeZoneName: timeZoneId
        });

        for (const preview of adPreviewResponses) {
            const ad: IAdPreviewVM = adMap.get(preview.adId);

            ad.creativeNodes = await this.prepareCreativeNodes(preview.creativeNodes, isDraft);
            ad.creativeNodes.sort(this.sortCreativeNode);
        }

        return Array.from(adMap.values()).sort(this.sortAdPreview);
    }

    /**
     * Gets current campaign name
     */
    public async getCampaignName(): Promise<string> {
        const campaign: ICampaign = await this.getCampaign();

        return campaign.name;
    }

    /**
     * Gets the decision tree of the current selected preview ad
     */
    public getDecisionTreeOfCurrentAdGroup(): Observable<IDecisionTree> {
        return combineLatest([
            this.getDraftCampaign(),
            this.getPublishedCampaign(),
            this.store.select(selectCurrentViewTab),
            this.store.select(selectCurrentAdGroupId)
        ]).pipe(
            map(([draftCampaign, publishedCampaign, previewTab, adGroupId]) => {
                const decisionForest: IDraftCampaign =
                    previewTab === PreviewViewTab.Draft ? draftCampaign : publishedCampaign;

                return decisionForest?.decisionTrees.find((tree) => tree.adGroup.id === adGroupId);
            })
        );
    }

    public async getDefaultTimeIfScheduleExist(isDraft: boolean): Promise<Date> {
        const decisionForest: IDraftCampaign = await (isDraft
            ? this.getDraftCampaign()
            : this.getPublishedCampaign());
        const adGroupId: string = await lastValueFrom(
            this.store.select(selectCurrentAdGroupId).pipe(take(1))
        );
        const tree: IDecisionTree = decisionForest?.decisionTrees.find(
            (dt) => dt.adGroup.id === adGroupId
        );

        if (ScheduleNodeUtilities.isScheduleNode(tree?.root)) {
            const defaultDate = new Date();
            defaultDate.setHours(12, 0, 0, 0);

            return defaultDate;
        }

        return undefined; // We are not in schedule tree -> there is no default time
    }

    public async getPreviewEmptyState(
        allPreviews: IAdPreviewVM[],
        currentTabPreviews: IAdPreviewVM[],
        viewTab: PreviewViewTab
    ): Promise<PreviewEmptyState> {
        const isBothEmpty =
            !allPreviews.length ||
            allPreviews.every((adPreview) => !adPreview.creativeNodes?.length);

        if (isBothEmpty) {
            return PreviewEmptyState.BothEmpty;
        }

        if (
            currentTabPreviews.length > 0 &&
            currentTabPreviews.some((adPreview) => adPreview.creativeNodes?.length > 0)
        ) {
            return PreviewEmptyState.NotEmpty;
        } else if (viewTab === PreviewViewTab.Draft) {
            return PreviewEmptyState.DraftIsEmpty;
        } else if (viewTab === PreviewViewTab.Published) {
            return PreviewEmptyState.PublishedIsEmpty;
        }
    }

    private isAdPublished(campaign: ICampaign, adId: string): boolean {
        // gets the last successful attempt of ad
        const attempts: IPublishAttempt[] = PublishAttemptUtilities.getAttemptsOfAd(
            campaign.attempts,
            adId
        );

        // this ad is not published
        if (attempts.length === 0) {
            return false;
        }

        // sort by date in desc order
        attempts.sort(
            (a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
        );

        // get the published ad reference
        const publishedAd: IPublishedAdRef = attempts[0].ads.find((ad) => ad.id === adId);

        // if latest attempt is successful
        return publishedAd.status === AdAttemptStatus.Success;
    }

    private async getAdPreviews(
        campaignId: string,
        adPreviewRequest: IAdPreviewRequest
    ): Promise<IAdPreviewResponse[]> {
        const response: IAdPreviewResponse[] = await this.campaignApiService.getAdPreviews(
            campaignId,
            adPreviewRequest
        );

        return response;
    }

    private prepareCreativeNodes(
        creatives: IAdPreviewCreativeNode[],
        isDraft: boolean
    ): Promise<IAdPreviewCreativeNodeVM[]> {
        if (!isDraft) {
            // published
            return Promise.resolve(
                creatives.map((creativeNode) => ({
                    ...creativeNode,
                    imageUrl: `${environment.bannerflowCdnUrl}${creativeNode.creative.image.url}`,
                    isFallback: creativeNode.isFallback
                }))
            );
        } else {
            // draft
            return lastValueFrom(
                from(creatives).pipe(
                    concatMap(async (creativeNode) => {
                        // WARNING!
                        // This check is not 100% correct in all cases. We cannot determine this based on
                        // data structure, as the data structure is inconsistent.
                        // We need to get a flag from backend telling whether creativeNode isFallback
                        // and possibly also if creativeNode isDefaultNode (for schedule).
                        const isFallback: boolean = creativeNode.isFallback;

                        let creativesetId = '';

                        if (isFallback) {
                            creativesetId = await this.getFallbackCreativesetId();

                            // prefilling data for fallback, needed to render creative
                            creativeNode.creative.creativeset.id = creativesetId;
                        } else {
                            creativesetId = creativeNode.creative.creativeset.id;
                        }

                        const creative: ICreativeVM = await this.getCreative(
                            creativesetId,
                            creativeNode.creative.id
                        );
                        const imageUrl: string = creative?.preloadImageUrl || '';

                        return {
                            ...creativeNode,
                            imageUrl,
                            isFallback
                        };
                    }),
                    toArray()
                )
            );
        }
    }

    private getDraftCampaign(): Promise<IDraftCampaign> {
        return lastValueFrom(
            this.store.select(selectDraftCampaign).pipe(
                filter((campaign) => !!campaign),
                take(1)
            )
        );
    }

    private async getPublishedCampaign(): Promise<IDraftCampaign> {
        const campaign: ICampaign = await this.getCampaign();

        // published campaign only exists if the campaign is published
        if (campaign.attempts.length > 0) {
            return lastValueFrom(
                this.store.select(selectLiveCampaign).pipe(
                    filter((publishedCampaign) => !!publishedCampaign),
                    take(1)
                )
            );
        }
    }

    private getCampaign(): Promise<ICampaign> {
        return lastValueFrom(
            this.store.select(selectCampaign).pipe(
                filter((campaign) => !!campaign),
                take(1)
            )
        );
    }

    private getBrandLanguages(): Promise<ILocalization[]> {
        return lastValueFrom(
            this.store.select(selectBrandLocalizations).pipe(
                filter((languages) => !!languages),
                take(1)
            )
        );
    }

    private getCreative(creativeSetId: string, creativeId: string): Promise<ICreativeVM> {
        return lastValueFrom(
            this.cachedCreativeSetService.getCreativesetById(creativeSetId).pipe(
                map((creativeSet) => {
                    const creative = creativeSet.creatives.find((c) => c.id === creativeId);

                    if (!creative) {
                        console.warn(
                            `Creative with id: ${creativeId} was not found in creative set: ${creativeSetId}`
                        );
                    }

                    return creative;
                }),
                take(1)
            )
        );
    }

    private getFallbackCreativesetId(): Promise<string> {
        return lastValueFrom(this.store.select(selectBrandFallbackCreativeSetId).pipe(take(1)));
    }

    public sortAdPreview(previewA: IAdPreviewVM, previewB: IAdPreviewVM): number {
        const languageCompare: number = previewA.localization.name.localeCompare(
            previewB.localization.name
        );
        const widthCompare: number = previewA.size.width - previewB.size.width;
        const heightCompare: number = previewA.size.height - previewB.size.height;

        return languageCompare || widthCompare || heightCompare;
    }

    private sortCreativeNode(
        nodeA: IAdPreviewCreativeNodeVM,
        nodeB: IAdPreviewCreativeNodeVM
    ): number {
        return nodeB.probability - nodeA.probability;
    }

    public exitAdPreview() {
        this.store
            .select(selectCampaign)
            .pipe(take(1))
            .subscribe((campaign) => {
                const { accountSlug, brandSlug } = this.userService;
                const campaignId = campaign.id;
                const previousView = this.router.routerState.snapshot.root.queryParams.previous;
                const url = `/${accountSlug}/${brandSlug}/campaign/${campaignId}/${
                    previousView || 'editor'
                }`;

                if (environment.stage === 'sandbox') {
                    const branch = this.prService.currentBranchSignal();
                    if (branch) {
                        this.router.navigate([url], { queryParams: { branch } });
                        return;
                    }
                }

                this.router.navigate([url]);
            });
    }
}
