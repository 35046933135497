import { Injectable, inject } from '@angular/core';
import { DraftCampaignValidationService } from '@campaign/services/draft-campaign-validation.service';
import { createAdValidationMap } from '@campaign/utilities/ad-validation.utils';
import { IAppState } from '@core/core.reducer';
import { loadFallbackCreativeSetSuccess } from '@core/store/brand.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    loadDraftCampaignSuccess,
    updateDraftCampaign,
    discardDraftCampaignSuccess,
    pushLiveDraftCampaignSuccess
} from '../draft-campaign/draft-campaign.actions';
import { selectDraftCampaign } from '../draft-campaign/draft-campaign.selectors';
import {
    validateDraftCampaign,
    validateDraftCampaignComplete,
    validateAdsComplete
} from './draft-campaign-validation.actions';

@Injectable()
export class DraftCampaignValidationEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject<Store<IAppState>>(Store);
    private readonly draftValidation = inject(DraftCampaignValidationService);


    public triggerValidation$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(
                loadFallbackCreativeSetSuccess,
                loadDraftCampaignSuccess,
                updateDraftCampaign,
                discardDraftCampaignSuccess,
                pushLiveDraftCampaignSuccess
            ),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            filter(([_action, draft]) => !!draft),
            map(() => validateDraftCampaign())
        )
    );

    public validateDraft$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(validateDraftCampaign),
            withLatestFrom(this.store.select(selectDraftCampaign)),
            switchMap(([_action, draft]) => this.draftValidation.checkDraftCampaign(draft)),
            map((validatedTrees) => validateDraftCampaignComplete({ validatedTrees }))
        )
    );

    public validateAds$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(validateDraftCampaignComplete),
            map((action) => {
                const adValidationMap = createAdValidationMap(action.validatedTrees);
                return validateAdsComplete({ adValidationMap });
            })
        )
    );
}
