import { IAppState } from '@core/core.reducer';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { ICampaign } from '@shared/models/campaigns';
import { CampaignPublishStatus } from '@shared/models/campaigns/campaign/campaign-publish-status.enum';
import { selectCampaignState } from '../state';
import { ICampaignState } from './campaign.reducer';

export const selectCampaign: MemoizedSelector<IAppState, ICampaign> = createSelector(
    selectCampaignState,
    (state: ICampaignState) => state.campaign
);

export const selectCampaignStatus: MemoizedSelector<IAppState, CampaignPublishStatus> =
    createSelector(selectCampaign, (campaign: ICampaign) => campaign?.status);

export const selectIsFirstPublishAttempt: MemoizedSelector<IAppState, boolean> = createSelector(
    selectCampaign,
    (campaign: ICampaign) => !!campaign && campaign.attempts?.length === 0
);

export const selectAdsPublishedToNonHeavyVideoSupport = createSelector(
    selectCampaignState,
    (state: ICampaignState) => state.adsPublishedToNonHeavyVideoSupport
);

export const selectPublishedAdIds = createSelector(
    selectCampaignState,
    (state: ICampaignState) => state.currentPublishedAdIds
);
