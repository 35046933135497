import { ICampaign, IValidationMap } from '@shared/models/campaigns';
import { PublishAttemptUtilities } from '@shared/models/campaigns/campaign/publish-attempt-utilities';
import { CampaignActions } from './campaign.actions';

export interface ICampaignState {
    campaign: ICampaign;
    initPublish: boolean;
    heavyVideoAdsMap: IValidationMap;
    adsPublishedToNonHeavyVideoSupport: string[];
    currentPublishedAdIds: string[];
}

const initialState: ICampaignState = {
    campaign: undefined,
    initPublish: false,
    heavyVideoAdsMap: {},
    adsPublishedToNonHeavyVideoSupport: [],
    currentPublishedAdIds: []
};

export function campaignReducer(
    state: ICampaignState = initialState,
    action: CampaignActions
): ICampaignState {
    switch (action.type) {
        case '[Campaign] Load Success':
            return {
                ...state,
                campaign: action.campaign,
                currentPublishedAdIds: PublishAttemptUtilities.getPublishedAdIdsFromAttempts(
                    action.campaign.attempts,
                    action.campaign.ads.map((ad) => ad.id)
                )
            };

        case '[Campaign] Update':
            return {
                ...state,
                campaign: action.campaign
            };

        case '[Campaign] Init Publish':
            return {
                ...state,
                initPublish: true
            };

        case '[Campaign] Init Publish Success':
        case '[Campaign] Init Publish Failure':
            return {
                ...state,
                initPublish: false
            };

        case '[Campaign] Assign Published To Non Heavy Video Support Ad Map':
            return {
                ...state,
                adsPublishedToNonHeavyVideoSupport: action.adsPublishedToNonHeavyVideoSupport
            };

        case '[Campaign] Received Campaign Publish Status Update':
            return {
                ...state,
                campaign: {
                    ...state.campaign,
                    status: action.campaignPublishStatus.campaignStatus
                }
            };

        case '[Campaign] Received Ad Publish Statuses Update':
            const publishedAdIdsFromUpdate =
                PublishAttemptUtilities.getPublishedAdIdsFromStatusUpdate(action.adPublishStatuses);

            return {
                ...state,
                currentPublishedAdIds: [
                    ...new Set([...state.currentPublishedAdIds, ...publishedAdIdsFromUpdate])
                ]
            };

        default:
            return state;
    }
}
