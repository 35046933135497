import { createAction } from '@ngrx/store';

export enum CreativePanelActionTypes {
    Show = '[Creative Panel] Show',
    Hide = '[Creative Panel] Hide'
}

export const showCreativePanel = createAction(CreativePanelActionTypes.Show);

export const hideCreativePanel = createAction(CreativePanelActionTypes.Hide);

export type CreativePanelActions =
    | ReturnType<typeof showCreativePanel>
    | ReturnType<typeof hideCreativePanel>;
