import { Injectable, inject } from '@angular/core';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import {
    updateAndPushDraftCampaign,
    updateDraftCampaign
} from '@campaign/store/draft-campaign/draft-campaign.actions';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { CampaignDialogService } from '@core/services/campaigns';
import { Store } from '@ngrx/store';
import {
    AnyNode,
    CreativeNodeUtilities,
    IAdCreativePair,
    IAdGroup,
    ICreativeNode,
    IDecisionTree,
    IDraftAd,
    IDraftCampaign,
    INodeProperties
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { ICreative } from '@shared/models/studio';
import { isEmpty } from '@shared/utilities/object-utilities';
import { AdCreativePairingService } from './ad-creative-pairing.service';

interface ICreativeNodeChanges {
    nodeId: string;
    adGroupId: string;
    newProperties: INodeProperties;
    newPropertiesWithActiveCreativesOnly: INodeProperties;
}

@Injectable({
    providedIn: 'root'
})
export class AssignedCreativeChangeDetectionService {
    private adCreativePairingService = inject(AdCreativePairingService);
    private campaignDialogService = inject(CampaignDialogService);
    private store = inject<Store<IAppStateWithCampaign>>(Store);

    public async detectStudioChanges(draftCampaign: IDraftCampaign): Promise<void> {
        const creativeNodeChangesPromises: Promise<ICreativeNodeChanges>[] =
            draftCampaign.decisionTrees
                ?.map((tree) =>
                    // gets all creative nodes from draft campaign
                    DecisionTreeUtilities.getAllCreativeNodes(tree.root)
                        // filters the ones that has a creative set assigned
                        .filter((node) => !!node.creativesetId)
                        // returns promise of ICreativeNodeChanges, we will execute all later
                        .map((node) => this.detectChangesForCreativeNode(tree.adGroup, node))
                )
                .reduce((prev, curr) => prev.concat(curr), []);

        const creativeNodeChanges: ICreativeNodeChanges[] = await Promise.all(
            creativeNodeChangesPromises
        );

        if (
            draftCampaign.isDirty &&
            creativeNodeChanges.some((node) => !isEmpty(node.newProperties))
        ) {
            const updatedTrees = this.prepareUpdatedDecisionTrees(
                draftCampaign,
                creativeNodeChanges
            );
            this.store.dispatch(updateDraftCampaign({ changes: { decisionTrees: updatedTrees } }));
            return;
        }

        if (
            creativeNodeChanges.some((node) => !isEmpty(node.newPropertiesWithActiveCreativesOnly))
        ) {
            // show dialog
            const result: UIConfirmDialogResult =
                await this.campaignDialogService.showStudioChangesDetectedDialog();

            if (result === 'confirm') {
                // update all nodes and push changes
                const updatedTrees = this.prepareUpdatedDecisionTrees(
                    draftCampaign,
                    creativeNodeChanges
                );
                this.store.dispatch(
                    updateAndPushDraftCampaign({
                        changes: { decisionTrees: updatedTrees }
                    })
                );
            }
        }
    }

    private async detectChangesForCreativeNode(
        adGroup: IAdGroup,
        node: ICreativeNode
    ): Promise<ICreativeNodeChanges> {
        const emptyAds: IDraftAd[] = adGroup.ads.filter(
            (ad) => !Object.keys(node.properties).includes(ad.id)
        );

        const pairs: IAdCreativePair[] =
            await this.adCreativePairingService.getSingleMatchAdCreativePairsForCreativeNode(
                node,
                emptyAds
            );

        const emptyAdsWithMatchingCreatives: Map<string, ICreative[]> =
            this.mapAdsWithCreatives(pairs);

        const newProperties: INodeProperties = this.getNodePropertiesFromSortedAdCreativesMap(
            emptyAdsWithMatchingCreatives
        );

        const inactiveCreativeIds = this.getInactiveCreativeIds(emptyAdsWithMatchingCreatives);

        const newPropertiesWithActiveCreativesOnly = this.getPropertiesWithActiveOnlyCreatives(
            newProperties,
            inactiveCreativeIds
        );

        return {
            nodeId: node.id,
            adGroupId: adGroup.id,
            newProperties,
            newPropertiesWithActiveCreativesOnly
        };
    }

    /**
     * Get the adids and the id from the first creative in the map to the corresponding adid, and send it back as nodeproperties
     */
    private getNodePropertiesFromSortedAdCreativesMap(
        adCreativeMap: Map<string, ICreative[]>
    ): INodeProperties {
        const properties: INodeProperties = {};

        adCreativeMap.forEach((value, key) => {
            value.sort((a, b) => {
                const x: number = parseInt(a.id, 10);
                const y: number = parseInt(b.id, 10);

                return x - y;
            });

            properties[key] = value[0].id;
        });

        return properties;
    }

    /**
     * Returns map of adId and an array of matching Creatives from AdCreative-pairs
     */
    private mapAdsWithCreatives(pairs: IAdCreativePair[]): Map<string, ICreative[]> {
        return pairs.reduce((prev, curr) => {
            const adId: string = curr.ad.id;

            if (adId) {
                const defaultGroup: ICreative[] = prev.get(adId) || [];
                defaultGroup.push(curr.creative);
                prev.set(adId, defaultGroup);
            }

            return prev;
        }, new Map<string, ICreative[]>());
    }

    private prepareUpdatedDecisionTrees(
        draftCampaign: IDraftCampaign,
        creativeNodeChanges: ICreativeNodeChanges[]
    ): IDecisionTree[] {
        const decisionTrees: IDecisionTree[] = draftCampaign.decisionTrees.map((tree) => {
            const cloneTree: IDecisionTree = JSON.parse(JSON.stringify(tree));

            // find all the nodes of this decision tree
            const nodeChanges: ICreativeNodeChanges[] = creativeNodeChanges.filter(
                (node) => node.adGroupId === cloneTree.adGroup.id
            );

            nodeChanges.forEach((nodeChange) => {
                const foundCreativeNode: AnyNode = DecisionTreeUtilities.findNode(
                    cloneTree.root,
                    nodeChange.nodeId
                );

                if (CreativeNodeUtilities.isCreativeNode(foundCreativeNode)) {
                    foundCreativeNode.properties = {
                        ...foundCreativeNode.properties,
                        ...nodeChange.newProperties
                    };
                }
            });

            return cloneTree;
        });

        return decisionTrees;
    }

    private getInactiveCreativeIds(adIdCreativesMap: Map<string, ICreative[]>): string[] {
        return [...adIdCreativesMap.values()]
            .flatMap((creatives) => creatives)
            .filter((creative) => !creative.design?.id)
            .map((creative) => creative.id);
    }

    private getPropertiesWithActiveOnlyCreatives(
        properties: INodeProperties,
        inactiveCreativeIds: string[]
    ): INodeProperties {
        return Object.keys(properties)
            .filter((key) => !inactiveCreativeIds.includes(properties[key]))
            .reduce((result: INodeProperties, adId: string) => {
                result[adId] = properties[adId];
                return result;
            }, {});
    }
}
