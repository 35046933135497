import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '@core/core.reducer';
import { IFallbackCreative } from '@shared/models/campaigns';
import { ISize, ILocalization } from '@shared/models/common';
import { IBrandState } from './brand.reducer';
import { PublishOptionConfiguration } from '@shared/models/publish';

export const selectBrandState: MemoizedSelector<IAppState, IBrandState> =
    createFeatureSelector<IBrandState>('brand');

export const selectBrandSizes: MemoizedSelector<IAppState, ISize[]> = createSelector(
    selectBrandState,
    (state: IBrandState) => state.sizes
);

export const selectBrandLocalizations: MemoizedSelector<IAppState, ILocalization[]> =
    createSelector(selectBrandState, (state: IBrandState) => state?.localizations || []);
export const selectBrandFallbackCreatives: MemoizedSelector<IAppState, IFallbackCreative[]> =
    createSelector(selectBrandState, (state: IBrandState) => state?.fallbackCreatives);

export const selectBrandFallbackCreativeSetId: MemoizedSelector<IAppState, string> = createSelector(
    selectBrandState,
    (state: IBrandState) => state.fallbackCreativeSetId
);

export const selectBrandPublishOptions: MemoizedSelector<IAppState, PublishOptionConfiguration[]> =
    createSelector(selectBrandState, (state: IBrandState) => state.publishOptions);

export const selectBrandPublishOptionDestinations = (
    types?: string[]
): MemoizedSelector<IAppState, string[]> =>
    createSelector(selectBrandPublishOptions, (configurations) => {
        const destinations = configurations
            .filter(
                (config) =>
                    (!types || types.length === 0 ? true : types.includes(config.type)) &&
                    config.destination
            )
            .map((config) => config.destination.name);
        return [...new Set(destinations)].sort((a, b) => a.localeCompare(b));
    });

export const selectBrandPublishOptionTypes = (
    destinations?: string[]
): MemoizedSelector<IAppState, string[]> =>
    createSelector(selectBrandPublishOptions, (configurations) => {
        const types = configurations
            .filter(
                (config) =>
                    (!destinations || destinations.length === 0
                        ? true
                        : destinations.includes(config.destination.name)) && config.type
            )
            .map((config) => config.type);
        return [...new Set(types)];
    });

export const selectFilteredOptions = ({
    destination = [],
    type = []
}: {
    destination?: string[];
    type?: string[];
}): MemoizedSelector<IAppState, PublishOptionConfiguration[]> =>
    createSelector(selectBrandPublishOptions, (options: PublishOptionConfiguration[]) => {
        if (!destination?.length && !type?.length) {
            return options;
        }
        let filteredOptions = options;

        if (destination?.length) {
            filteredOptions = filteredOptions.filter((option) =>
                destination.includes(option.destination.name)
            );
        }

        if (type?.length) {
            filteredOptions = filteredOptions.filter((option) => type.includes(option.type));
        }

        return filteredOptions;
    });

export const selectBrandHeavyAssetFallbackMap: MemoizedSelector<IAppState, string[]> =
    createSelector(selectBrandState, (state: IBrandState) => state?.heavyAssetFallback);
