import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { IAdPreviewVM, PreviewEmptyState, PreviewViewTab } from '../models/ad-preview-view-model';
import { IAdPreviewState, IAppStateWithAdPreview } from './ad-preview.state';

export const selectAdPreviewState: MemoizedSelector<IAppStateWithAdPreview, IAdPreviewState> =
    createFeatureSelector<IAppStateWithAdPreview, IAdPreviewState>('adPreview');

export const selectCurrentAdGroupId: MemoizedSelector<IAppStateWithAdPreview, string> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.adGroupId);

export const selectSelectedPreviewTime: MemoizedSelector<
    IAppStateWithAdPreview,
    { time: number | undefined; localTime: string }
> = createSelector(selectAdPreviewState, (state: IAdPreviewState) => ({
    time: state.time,
    localTime: state.localTime
}));

export const selectCurrentAdPreviewId: MemoizedSelector<IAppStateWithAdPreview, string> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.selectedAdId);

export const selectAdPreviewIsLoading: MemoizedSelector<IAppStateWithAdPreview, boolean> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.isLoading);

export const selectAdPreviews: MemoizedSelector<IAppStateWithAdPreview, IAdPreviewVM[]> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) =>
        state.currentViewTab === PreviewViewTab.Draft
            ? state.draftAdPreviews
            : state.publishedAdPreviews
    );

export const selectCurrentAdPreview: MemoizedSelector<IAppStateWithAdPreview, IAdPreviewVM> =
    createSelector(selectAdPreviewState, selectAdPreviews, (state, adPreviews) =>
        adPreviews.find((adPreview) => adPreview.id === state.selectedAdId)
    );

export const selectAdPreviewIndex: MemoizedSelector<IAppStateWithAdPreview, number> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.viewIndex);

export const selectCurrentViewTab: MemoizedSelector<IAppStateWithAdPreview, PreviewViewTab> =
    createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.currentViewTab);

export const selectCurrentAdPreviewEmptyState: MemoizedSelector<
    IAppStateWithAdPreview,
    PreviewEmptyState
> = createSelector(selectAdPreviewState, (state: IAdPreviewState) => state.previewEmptyState);
