import { ICreativeNode } from './creative-node';
import { IScheduleNode, IScheduleNodeDto } from './schedule-node';
import { IAutoOptimisationNode } from './auto-optimisation-node';

export const DEFAULT_NODE_SUFFIX = '-default';

export enum NodeType {
    CreativeNode = 0,
    Schedule = 1,
    AutoOptimisation = 2,
    Rotation = 4
}

export interface INode {
    type: NodeType;
    id: string;
    parentId?: string;
    children?: AnyNode[];
    properties: INodeProperties;
}

export interface INodeProperties {
    [key: string]: string;
}

export type AnyNode = ICreativeNode | IScheduleNode | IScheduleNodeDto | IAutoOptimisationNode;

export function isDecisionNode(node: INode): node is IScheduleNode | IAutoOptimisationNode {
    return node?.type === NodeType.Schedule || node?.type === NodeType.AutoOptimisation;
}
