import { ISize } from '@shared/models/common';

export enum PreviewType {
    Unknown,
    Draft,
    Published,
    Hosted
}

export interface IAdPreviewRequest {
    time: number;
    adIds: string[];
    localTime: string;
    timeZoneName: string;
    previewType: PreviewType;
}

export interface IAdPreviewResponse {
    adId: string;
    creativeNodes: IAdPreviewCreativeNode[];
}

export interface IGeneratedCreative {
    size?: ISize;
    id: string;
    image: { url: string; fileSize: number };
    creativeset: {
        id: string;
        name: string;
    };
    targetUrl?: string;
}

export interface IAdPreviewCreativeNode {
    probability: number;
    creative: IGeneratedCreative;
    id: string;
    path?: string;
    nodeIndex: number;
    isFallback: boolean;
    isOverridden: boolean;
    targetUrl: string;
}
