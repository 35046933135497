import { createAction, props } from '@ngrx/store';
import { IDecisionTree } from '@shared/models/campaigns';

export const createDecisionTrees = createAction(
    '[Decision Tree] Create',
    props<{ decisionTrees: IDecisionTree[]; insertTop?: boolean }>()
);

export const duplicateDecisionTree = createAction(
    '[Decision Tree] Duplicate',
    props<{ adGroupId: string }>()
);

export const updateDecisionTree = createAction(
    '[Decision Tree] Update',
    props<{ decisionTree: IDecisionTree }>()
);

export const deleteDecisionTree = createAction(
    '[Decision Tree] Delete',
    props<{ adGroupId: string }>()
);

export const reorderDecisionTrees = createAction(
    '[Decision Tree] Reorder',
    props<{ decisionTrees: IDecisionTree[] }>()
);

export type DecisionTreeActions =
    | ReturnType<typeof createDecisionTrees>
    | ReturnType<typeof duplicateDecisionTree>
    | ReturnType<typeof updateDecisionTree>
    | ReturnType<typeof deleteDecisionTree>
    | ReturnType<typeof reorderDecisionTrees>;
