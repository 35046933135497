import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromCampaign from '../../../store/state';
import { AdEventsMap } from '../models/ad-event';
import { IAdListFilter, IFilterValues, IListColumnsMap } from '../models/ad-list-filter';
import { IAdListSort } from '../models/ad-list-sort.model';
import { IListAd, IPagination } from '../models/ad-list.model';
import { IAdListState } from './ad-list.reducer';

export interface IAppState extends fromCampaign.IAppStateWithCampaign {
    adList: IAdListState;
}

const selectAdListState: MemoizedSelector<IAppState, IAdListState> = createFeatureSelector<
    IAppState,
    IAdListState
>('adList');

export const selectListItems: MemoizedSelector<IAppState, IListAd[]> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.listItems
);

export const selectAdsEventsLoading: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.adEvents.loading
);

export const selectAdsEventsLoaded: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.adEvents.loaded
);

export const selectAdsEventsMap: MemoizedSelector<IAppState, AdEventsMap> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.adEvents.adEventsMap
);

export const selectFilter: MemoizedSelector<IAppState, IAdListFilter> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.filter
);

export const selectListColumns: MemoizedSelector<IAppState, IListColumnsMap> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.columnsMap
);

export const selectFilterActive: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.listFilterActive || state.columnsFilterActive
);

export const selectListFilterActive: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.listFilterActive
);

export const selectColumnsFilterActive: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.columnsFilterActive
);

export const selectCurrentPageSize: MemoizedSelector<IAppState, number> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.currentPageSize
);

export const selectCurrentPage: MemoizedSelector<IAppState, number> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.currentPage
);

export const selectPagination: MemoizedSelector<IAppState, IPagination> = createSelector(
    selectAdListState,
    (state: IAdListState) => ({
        totalAmountOfItems: state.totalAmountOfItems,
        totalAmountOfPages: state.totalAmountOfPages,
        currentPage: state.currentPage,
        currentPageSize: state.currentPageSize
    })
);

export const selectSelectedAdsIds: MemoizedSelector<IAppState, string[]> = createSelector(
    selectAdListState,
    (state: IAdListState) =>
        state?.selectedListItems ? state.selectedListItems.map((item) => item.id) : []
);

export const selectSelectedAds: MemoizedSelector<IAppState, IListAd[]> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.selectedListItems
);

export const selectFilterValues: MemoizedSelector<IAppState, IFilterValues> = createSelector(
    selectAdListState,
    (state: IAdListState) => state?.filterValues
);

export const selectIsLoading: MemoizedSelector<IAppState, boolean> = createSelector(
    selectAdListState,
    (state: IAdListState) => state.isLoading
);

export const selectSort: MemoizedSelector<IAppState, IAdListSort> = createSelector(
    selectAdListState,
    (state: IAdListState) => ({
        sortBy: state.sortBy,
        descending: state.descending
    })
);

export const selectAdStatuses = createSelector(
    selectAdListState,
    (state: IAdListState) => state.adStatuses
);
