import { inject, Injectable } from '@angular/core';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { createAdContent } from '@campaign/utilities/ad-content-validation/ad-content.utils';
import { CampaignApiService } from '@core/services/campaigns';
import { CachedCreativesetService } from '@core/services/internal/cached-creativeset.service';
import { TimeZoneService } from '@core/services/internal/timezone.service';
import { selectBrandFallbackCreatives } from '@core/store/brand.selectors';
import { Store } from '@ngrx/store';
import {
    AdUtilities,
    AnyAd,
    AnyNode,
    AutoOptimisationRestartDraftState,
    AutoOptimisationRestartState,
    checkIfAutoOptNeedsRestart,
    CreativeNodeUtilities,
    DEFAULT_NODE_SUFFIX,
    DraftCampaignUtilities,
    ICreativeNode,
    ICreativeNodeWithParentType,
    IDecisionTree,
    IDraftCampaign,
    IFallbackCreative,
    IScheduleEvent,
    IScheduleNode,
    NodeType,
    ScheduleEventUtilities,
    ScheduleNodeUtilities
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import {
    CampaignErrorReason,
    IAdContent,
    ICheckedCreativeNode,
    ICheckedDecisionTree,
    IDraftCampaignValidationResult,
    IValidationMap
} from '@shared/models/campaigns/validation';
import { ICreativeSetVM } from '@shared/models/studio/creative-view-models';
import { checkIfStringArrayHasDuplicate } from '@shared/utilities/array-utilities';
import { lastValueFrom, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    checkIfMapContainHeavyVideo,
    generateAdHeavyVideoMapForNode
} from '../utilities/heavy-video.utils';
@Injectable({ providedIn: 'root' })
export class DraftCampaignValidationService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private cachedCreativeService = inject(CachedCreativesetService);
    private campaignApiService = inject(CampaignApiService);
    private timeZoneService = inject(TimeZoneService);

    /**
     * Checks if draft campaign can be pushed live
     */
    public async canPushLive(
        draftCampaign: IDraftCampaign
    ): Promise<IDraftCampaignValidationResult> {
        // check if we have something to push
        if (!draftCampaign.isDirty) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.DraftHasNoNewChanges
                }
            };
        }

        // check if we have ads
        if (!this.draftCampaignHasAds(draftCampaign)) {
            // error no ads
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.HasNoAds
                }
            };
        }

        // check for ad group name duplicates
        const adGroupNames = draftCampaign.decisionTrees.map((tree) => tree.adGroup.name);
        if (checkIfStringArrayHasDuplicate(adGroupNames)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.HasDuplicatedAdGroupNames
                }
            };
        }

        // checks the draft campaign
        const checkedTrees: ICheckedDecisionTree[] = await this.checkDraftCampaign(draftCampaign);

        if (checkedTrees.some((tree) => tree.autoOptNodeIsMissingSet)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.AutoOptNodeIsMissingCreativeSet
                }
            };
        }

        if (checkedTrees.some((tree) => tree.hasMultipleChoices && !tree.isValid)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.AdsHaveMultipleChoices
                }
            };
        }

        if (checkedTrees.some((tree) => tree.autoOptAdIsMissingDesign)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.AutoOptAdIsMissingDesign
                }
            };
        }

        if (checkedTrees.some((tree) => tree.autoOptUsingSameCreative)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.AutoOptIsUsingSameCreative
                }
            };
        }

        if (checkedTrees.some((tree) => tree.hasInvalidScheduleAd)) {
            return {
                isValid: false,
                error: {
                    reason: CampaignErrorReason.ScheduleAdsAreInvalid
                }
            };
        }

        // last check if all trees are valid(all ads in those trees are valid - have design or default or fallback)
        if (checkedTrees.every((tree) => tree.isValid)) {
            return {
                isValid: true,
                checkedTrees
            };
        }

        return {
            isValid: false,
            error: {
                reason: CampaignErrorReason.AdsAreInvalid,
                details: checkedTrees
            }
        };
    }

    /**
     * Checks given draftCampaign for ads having fallback and creative nodes having matching creatives
     * @param draftCampaign
     */
    public async checkDraftCampaign(
        draftCampaign: IDraftCampaign
    ): Promise<ICheckedDecisionTree[]> {
        // fetch fallback creativeset first
        const fallbackCreatives: IFallbackCreative[] = await lastValueFrom(
            this.store.select(selectBrandFallbackCreatives).pipe(take(1))
        );

        // fetch creativeset dictionary
        const creativeSetDict: Map<string, ICreativeSetVM> =
            await this.fetchAllCreativeSets(draftCampaign);

        return draftCampaign.decisionTrees.map((tree) =>
            this.checkDecisionTree(tree, fallbackCreatives, creativeSetDict)
        );
    }

    private checkDecisionTree(
        tree: IDecisionTree,
        fallbackCreatives: IFallbackCreative[],
        creativeSetDict: Map<string, ICreativeSetVM>
    ): ICheckedDecisionTree {
        // check if all ads have fallback and save into a lookup map
        const adFallbackMap: IValidationMap = this.getAdFallbackMap(
            tree.adGroup.ads,
            fallbackCreatives
        );
        const allAdsHaveFallback: boolean =
            Object.keys(adFallbackMap).length > 0 &&
            Object.values(adFallbackMap).every((hasFallback) => hasFallback);

        // this flattens the decision tree into an array of nodes
        const allNodes: AnyNode[] = DecisionTreeUtilities.getAllDescendants([tree.root]);

        // collect all events you wanna run validation on, inactive and past events should not be validated
        const excludedNodeIds = this.getExcludedNodeIds(allNodes);

        const nodesToValidate = this.getCreativeNodesToValidate(
            allNodes,
            tree.root.type,
            excludedNodeIds,
            false
        );

        const excludedNodesToValidate = this.getCreativeNodesToValidate(
            allNodes,
            tree.root.type,
            excludedNodeIds,
            true
        );

        // this validates all default nodes before validating creative nodes
        // so we can get the sibling default node of a creative node from a lookup table (map in this case) when needed
        const checkedDefaultNodes: ICheckedCreativeNode[] = this.getCheckedDefaultNodes(
            nodesToValidate,
            creativeSetDict,
            adFallbackMap,
            tree.adGroup.ads
        );

        // check creative nodes that are not default
        const checkedCreativeNodes: ICheckedCreativeNode[] = this.getCheckedCreativeNodes(
            nodesToValidate,
            creativeSetDict,
            checkedDefaultNodes,
            adFallbackMap,
            tree.adGroup.ads
        );

        const checkedExcludedCreativeNodes: ICheckedCreativeNode[] = this.getCheckedCreativeNodes(
            excludedNodesToValidate,
            creativeSetDict,
            checkedDefaultNodes,
            adFallbackMap,
            tree.adGroup.ads
        );

        const adHeavyVideoMap = generateAdHeavyVideoMapForNode(
            tree.adGroup,
            tree.root,
            creativeSetDict
        );

        const containHeavyVideo = checkIfMapContainHeavyVideo(adHeavyVideoMap);

        const hasMultipleChoices = checkedCreativeNodes.some((node) =>
            node.adContents.some(
                (content) =>
                    content.isValid === false &&
                    (content.invalidByMultipleSizes || content.invalidByMultipleVersions)
            )
        );

        const autoOptNodeIsMissingSet = checkedCreativeNodes.some((node) =>
            node.adContents.some(
                (content) =>
                    content.isValid === false &&
                    content.rootType === NodeType.AutoOptimisation &&
                    content.invalidByMissingCreativeSet
            )
        );

        const autoOptAdIsMissingDesign = checkedCreativeNodes.some((node) =>
            node.adContents.some(
                (content) => content.rootType === NodeType.AutoOptimisation && !content.hasDesign
            )
        );

        const allAutoOptNodes = nodesToValidate.filter(
            (node) => node.rootType === NodeType.AutoOptimisation
        );

        const allCreativesUsed: string[] = allAutoOptNodes.reduce((accumulated, node) => {
            const values: string[] = Object.values(node.properties);
            return [...accumulated, ...values];
        }, []);

        const autoOptUsingSameCreative =
            Array.from(new Set(allCreativesUsed)).length !== allCreativesUsed.length;

        const hasInvalidScheduleAd = checkedCreativeNodes.some((node) =>
            node.adContents.some(
                (content) =>
                    content.rootType === NodeType.Schedule &&
                    !content.hasDefault &&
                    !content.hasFallback
            )
        );

        // for schedule node if checked default node is valid then all checked creative nodes are valid also
        const isValid =
            (checkedDefaultNodes.length > 0 && checkedDefaultNodes.every((node) => node.isValid)) ||
            (checkedCreativeNodes.length > 0 && checkedCreativeNodes.every((node) => node.isValid));

        return {
            adGroupId: tree.adGroup.id,
            allAdsHaveFallback,
            adFallbackMap,
            adHeavyVideoMap,
            containHeavyVideo,
            checkedCreativeNodes,
            checkedDefaultNodes,
            checkedExcludedCreativeNodes,
            hasMultipleChoices,
            autoOptNodeIsMissingSet,
            autoOptAdIsMissingDesign,
            hasInvalidScheduleAd,
            isValid,
            autoOptUsingSameCreative
        };
    }

    private checkCreativeNode(
        creativeNode: ICreativeNodeWithParentType,
        creativeset: ICreativeSetVM | undefined,
        adFallbackMap: IValidationMap,
        ads: AnyAd[],
        defaultSiblingNode?: ICheckedCreativeNode | undefined
    ): ICheckedCreativeNode {
        const adContents: IAdContent[] = ads.map((ad) =>
            createAdContent(ad, creativeNode, adFallbackMap, creativeset, defaultSiblingNode)
        );

        // we should have more than 1 ad and all ads should be valid
        const isValid: boolean =
            adContents.length > 0 && adContents.every((adContent) => adContent.isValid);

        const usesDefault: boolean = adContents.some(
            (adContent) =>
                adContent.isValid &&
                adContent.rootType === NodeType.Schedule &&
                adContent.usesDefault
        );

        const usesFallback: boolean = adContents.some(
            (adContent) =>
                adContent.isValid &&
                (adContent.rootType === NodeType.Schedule ||
                    adContent.rootType === NodeType.CreativeNode) &&
                adContent.usesFallback
        );

        const hasMultipleChoices: boolean = adContents.some(
            (adContent) =>
                adContent.isValid === false &&
                (adContent.invalidByMultipleVersions || adContent.invalidByMultipleSizes)
        );

        const hasInactiveCreative: boolean = adContents.some(
            (adContent) => adContent.hasInactiveCreative
        );

        const isDefault: boolean = CreativeNodeUtilities.isDefaultNode(creativeNode);

        return {
            id: creativeNode.id,
            isValid,
            adContents,
            parentType: creativeNode.rootType,
            usesDefault,
            usesFallback,
            invalidByMultipleChoices: hasMultipleChoices,
            hasInactiveCreative,
            isDefault
        };
    }

    private getAdFallbackMap(ads: AnyAd[], fallbackCreatives: IFallbackCreative[]): IValidationMap {
        return ads.reduce((result: IValidationMap, currentAd) => {
            result[currentAd.id] = AdUtilities.hasFallback(currentAd, fallbackCreatives);

            return result;
        }, {});
    }

    private getExcludedNodeIds(allNodes: AnyNode[]): string[] {
        const excludedNodeIds: string[] = [];

        allNodes
            .filter((node) => ScheduleNodeUtilities.isScheduleNode(node))
            .forEach(({ events, properties, timeZoneId }: IScheduleNode) => {
                const offset = this.timeZoneService.getTimeZone(timeZoneId).offset;
                events.forEach((event: IScheduleEvent) => {
                    if (!event.isActive || !ScheduleEventUtilities.isUpcomingEvent(event, offset)) {
                        const creativeNodeId = properties[event.id];
                        excludedNodeIds.push(creativeNodeId);
                    }
                });
            });

        return excludedNodeIds;
    }

    private getCreativeNodesToValidate(
        allNodes: AnyNode[],
        rootType: NodeType,
        excludedNodeIds: string[],
        forExcluded: boolean
    ): ICreativeNodeWithParentType[] {
        return allNodes
            .filter((node) => excludedNodeIds.includes(node.id) === forExcluded)
            .filter((node) => CreativeNodeUtilities.isCreativeNode(node))
            .map((creativeNode: ICreativeNode) => ({
                ...creativeNode,
                rootType
            }));
    }

    private getCheckedDefaultNodes(
        nodesToValidate: ICreativeNodeWithParentType[],
        creativeSetDict: Map<string, ICreativeSetVM>,
        adFallbackMap: IValidationMap,
        ads: AnyAd[]
    ): ICheckedCreativeNode[] {
        return nodesToValidate
            .filter((node) => CreativeNodeUtilities.isDefaultNode(node))
            .map((defaultNode: ICreativeNodeWithParentType) => {
                const creativeSet: ICreativeSetVM = creativeSetDict.get(defaultNode.creativesetId);

                return this.checkCreativeNode(defaultNode, creativeSet, adFallbackMap, ads);
            });
    }

    private getCheckedCreativeNodes(
        nodesToValidate: ICreativeNodeWithParentType[],
        creativeSetDict: Map<string, ICreativeSetVM>,
        checkedDefaultNodes: ICheckedCreativeNode[],
        adFallbackMap: IValidationMap,
        ads: AnyAd[]
    ): ICheckedCreativeNode[] {
        return nodesToValidate
            .filter((node) => !CreativeNodeUtilities.isDefaultNode(node))
            .map((creativeNode: ICreativeNodeWithParentType) => {
                const creativeSet: ICreativeSetVM = creativeSetDict.get(creativeNode.creativesetId);
                // constructs the default node id with suffix
                const defaultSiblingId = `${creativeNode.parentId}${DEFAULT_NODE_SUFFIX}`;
                // gets the default sibling of the creative node from lookup table (map)
                const checkedDefault: ICheckedCreativeNode = checkedDefaultNodes.find(
                    (defaultNode) => defaultNode.id === defaultSiblingId
                );

                return this.checkCreativeNode(
                    creativeNode,
                    creativeSet,
                    adFallbackMap,
                    ads,
                    checkedDefault
                );
            });
    }

    /**
     * Prefetches all the creative sets from studio api to be used in the validation
     * @param draftCampaign
     */
    private async fetchAllCreativeSets(
        draftCampaign: IDraftCampaign
    ): Promise<Map<string, ICreativeSetVM>> {
        // gets all creative nodes on draft campaign
        const allCreativeNodes: ICreativeNode[] = [];
        draftCampaign.decisionTrees.forEach((tree) => {
            allCreativeNodes.push(...DecisionTreeUtilities.getAllCreativeNodes(tree.root));
        });

        // time to fetch creativesets, lets collect unique creativeset ids
        const creativesetIds: Set<string> = new Set();
        allCreativeNodes.forEach((node) => {
            if (node.creativesetId) {
                creativesetIds.add(node?.creativesetId);
            }
        });

        // fetch creativesets
        const creativesets: ICreativeSetVM[] = await lastValueFrom(
            this.cachedCreativeService
                .getCreativesetsByIds(Array.from(creativesetIds))
                .pipe(take(1))
        );

        // returns creativeset dictionary by id
        return new Map(creativesets.map((c) => [c.id, c]));
    }

    private draftCampaignHasAds(draftCampaign: IDraftCampaign): boolean {
        return (
            draftCampaign.decisionTrees &&
            draftCampaign.decisionTrees.length > 0 &&
            draftCampaign.decisionTrees.some(
                (tree) => tree.adGroup.ads && tree.adGroup.ads.length > 0
            )
        );
    }

    public autoOptimisationRestartState(
        draftCampaign: IDraftCampaign
    ): Observable<AutoOptimisationRestartState> {
        return DraftCampaignUtilities.hasAONodes(draftCampaign)
            ? this.campaignApiService.autoOptimisationRestartCheck(draftCampaign).pipe(
                  map((draftStates: AutoOptimisationRestartDraftState[]) =>
                      draftStates.filter((draftState) => checkIfAutoOptNeedsRestart(draftState))
                  ),
                  map((draftStates: AutoOptimisationRestartDraftState[]) => ({
                      showRestartAutoOptModal: Boolean(draftStates.length),
                      draftStates
                  }))
              )
            : of({
                  showRestartAutoOptModal: false,
                  draftStates: []
              });
    }
}
