import { ICreativeSet } from '@shared/models/studio';
import { ObjectID } from 'bson';
import { IDraftAd } from '../ad';
import { IAd, ICreateAdSpecification } from '../ad/ad.model';
import { AD_GROUP_DEFAULT_NAME, AdGroupUtilities, IAdGroup } from './ad-group';
import { IDecisionTree } from './decision-tree';
import { CreativeNodeUtilities, ICreativeNode } from './nodes/creative-node';

export type IAdWithCreativeRef = Pick<IAd, 'id' | 'size' | 'localizationId' | 'creativeRefs'>;

export class DecisionTreeCreateUtilities {
    public static createFromAdSpecifications(
        adSpecs: ICreateAdSpecification[],
        creativeSet: ICreativeSet,
        nextAdGroupNameIndex: number
    ): IDecisionTree[] {
        const ads: IAdWithCreativeRef[] = adSpecs.map((adSpec) => ({
            id: new ObjectID().toString(),
            size: adSpec.size,
            localizationId: adSpec.localizationId,
            creativeRefs: [adSpec.cretiveRef]
        }));

        return this.createFromAdsAndCreativeSets(ads, [creativeSet], nextAdGroupNameIndex);
    }

    public static createFromAdsAndCreativeSets(
        ads: IAdWithCreativeRef[],
        creativeSets: ICreativeSet[],
        nextAdGroupNameIndex = 1
    ): IDecisionTree[] {
        let adGroupNameIndex = nextAdGroupNameIndex;

        const groupedAdsByCreativesetIds: IAdWithCreativeRef[][] =
            AdGroupUtilities.groupAdsByCreativeSet(ads, creativeSets);
        const groupedAds: any[] = groupedAdsByCreativesetIds.reduce((prev, curr) => {
            const groupedBySizeAndLanguage: any[] =
                AdGroupUtilities.groupAdsBySizeAndLanguage(curr);

            return prev.concat(groupedBySizeAndLanguage);
        }, []);

        const adGroups: IAdGroup[] = [];
        groupedAds.forEach((group) => {
            adGroups.push({
                id: new ObjectID().toString(),
                name: `${AD_GROUP_DEFAULT_NAME} ${adGroupNameIndex}`,
                ads: group.map((ad) => ({
                    id: ad.id,
                    size: ad.size,
                    localizationId: ad.localizationId,
                    targetUrl: ad.targetUrl
                }))
            });

            adGroupNameIndex++;
        });

        const decisionTrees: IDecisionTree[] = [];

        adGroups.forEach((group) => {
            // setup creativesetId and creative nodes per ad group
            let creativesetId: string;
            const adToCreativeDictionary: any = {};

            group.ads.forEach((ad) => {
                const campaignAd: IAdWithCreativeRef = ads.find(
                    (campagignAd) => campagignAd.id === ad.id
                );

                // campaign ad without a decision tree should have only 1 creative assigned
                if (campaignAd.creativeRefs && campaignAd.creativeRefs.length === 1) {
                    adToCreativeDictionary[ad.id] = campaignAd.creativeRefs[0].creativeId;

                    creativesetId = campaignAd.creativeRefs[0].creativeSetId;
                }
            });

            const creativeNode: ICreativeNode = {
                id: new ObjectID().toString(),
                type: 0,
                creativesetId,
                properties: {
                    ...adToCreativeDictionary
                }
            };

            decisionTrees.push({ adGroup: group, root: creativeNode });
        });

        return decisionTrees;
    }

    public static createFromAdsAndEmptyRootNode(
        ads: IDraftAd[],
        adGroupNameIndex: number
    ): IDecisionTree {
        return {
            adGroup: {
                ads,
                id: new ObjectID().toString(),
                name: `${AD_GROUP_DEFAULT_NAME} ${adGroupNameIndex}`
            },
            root: CreativeNodeUtilities.create()
        };
    }
}
