import { createReducer, on } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';
import { loadUserSuccess } from './user.actions';

export interface IUserState {
    isLoaded: boolean;
    user: User;
}

const initialState: IUserState = {
    isLoaded: false,
    user: undefined
};

export const userReducer = createReducer(
    initialState,
    on(loadUserSuccess, (state, { user }) => ({
        ...state,
        isLoaded: true,
        user
    }))
);
