export enum RoleEnum {
    None = 0,
    User = 1,
    Admin = 2,
    Owner = 3,
    AccountAdmin = 4, // According to BE code, this one is deprecated since 2014-12-10
    ExternalUser = 5, // New ExternalUser for agencies (2014-12-10)
    UserWithoutEditor = 6,
    UserWithoutPublish = 7,
    UserWithoutEditorWithDuplicate = 8,
    UserWithoutAccessOthersContent = 9,
    UserWithOnlyPublish = 10,
    UserWithOnlyAnalytics = 11,
    UserWithoutEditorWithAnalytics = 12,
    UserWithoutDesign = 13
}
