import { createAction, props } from '@ngrx/store';
import { IAdValidationMap, ICheckedDecisionTree } from '@shared/models/campaigns';

export const validateDraftCampaign = createAction('[DraftCampaignValidation] Validate');

export const validateDraftCampaignComplete = createAction(
    '[DraftCampaignValidation] Validate Complete',
    props<{ validatedTrees: ICheckedDecisionTree[] }>()
);

export const validateAdsComplete = createAction(
    '[DraftCampaignValidation] Validate Ads Complete',
    props<{ adValidationMap: IAdValidationMap }>()
);

export type DraftCampaignValidationActions =
    | ReturnType<typeof validateDraftCampaign>
    | ReturnType<typeof validateDraftCampaignComplete>
    | ReturnType<typeof validateAdsComplete>;
