export enum CampaignErrorReason {
    ApiError = 'Error occured during api request',
    HasNoAds = 'Campaign has no ads or ad groups',
    DraftIsDirty = 'There are unsaved changes to draft campaign',
    DraftSaveFailure = 'Error occured during save of draft campaign',
    DraftHasNoNewChanges = 'There are no changes to push live',
    HasDuplicatedAdGroupNames = 'Some of Ad groups have the same name.',
    AdsHaveMultipleChoices = 'Creative have multiple creative sizes or versions that needs to be assigned',
    AutoOptNodeIsMissingCreativeSet = 'Some of AO is missing creative set',
    AutoOptAdIsMissingDesign = 'Some of AO ads are missing assigned creative (not fallback)',
    AutoOptIsUsingSameCreative = 'The same content cannot be used for multiple Auto-optimisation variants',
    ScheduleAdsAreInvalid = 'Some of schedule ads are invalid',
    AdsAreInvalid = 'Some of regular ads are invalid'
}

export interface ICampaignError {
    reason: CampaignErrorReason;
    details?: any;
}
