import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
@Component({
    imports: [UIModule],
    template: '<ui-loader></ui-loader>'
})
export class AuthComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);

    ngOnInit() {
        const codeParam = this.route.snapshot.queryParams.code;

        if (!codeParam) {
            this.router.navigate(['/404']);
        }
    }
}
