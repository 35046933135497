import { IAdCreativePair } from '@shared/models/campaigns';
import { ICreativeVersion } from '@shared/models/studio';
import { ICreativeVM } from '@shared/models/studio/creative-view-models';
import { ObjectID } from 'bson';
import { AnyNode, DEFAULT_NODE_SUFFIX, INodeProperties, NodeType } from '../node';
import { ICreativeNode } from './creative-node';

/**
 * Helper class to get properties safely
 */
export class CreativeNodeUtilities {
    public static isCreativeNode(node: AnyNode): node is ICreativeNode {
        return node?.type === NodeType.CreativeNode;
    }

    public static isDefaultNode(node: AnyNode): node is ICreativeNode & boolean {
        return this.isCreativeNode(node) && node.id.endsWith(DEFAULT_NODE_SUFFIX);
    }

    public static isEmptyDefaultNode(node: AnyNode): boolean {
        return CreativeNodeUtilities.isDefaultNode(node) && !node.creativesetId;
    }

    public static create(override?: Partial<ICreativeNode>): ICreativeNode {
        return {
            type: NodeType.CreativeNode,
            id: new ObjectID().toString(),
            properties: {},
            ...override
        };
    }
    /**
     * Assign parent id to immutable node
     */
    public static assignParentIdToNode(node: AnyNode, parentId: string): AnyNode {
        const nodeClone: AnyNode = JSON.parse(JSON.stringify(node));
        nodeClone.parentId = parentId;

        return nodeClone;
    }

    /**
     * extract previously assigned creative id value so we can read version value
     */
    public static extractVersionFromCreativeNode(
        node: ICreativeNode,
        creativesByLanguageId: ICreativeVM[]
    ): ICreativeVersion | undefined {
        const versionIds = [...new Set(creativesByLanguageId.map((cr) => cr.version.id))];

        if (versionIds.length === 1) {
            return creativesByLanguageId[0].version;
        }

        const foundCreative: ICreativeVM = creativesByLanguageId.find((creative) =>
            Object.values(node.properties).includes(creative.id)
        );

        return foundCreative ? foundCreative.version : undefined;
    }

    /**
     * Creates new node properties from ad creative pairs.
     * @param pairs
     */
    public static adCreativePairsToNodeProperties(pairs: IAdCreativePair[]): INodeProperties {
        const properties: INodeProperties = {};
        // We want to allow ads without creatives, since we support multiple of the same size in a version
        pairs.forEach((pair) => (properties[pair.ad.id] = pair.creative?.id));

        return properties;
    }

    /**
     * Updates node properties
     *
     *
     * @param node
     * @param props
     */
    public static updateNodeProperties(node: ICreativeNode, props: INodeProperties): void {
        Object.keys(props).forEach((key) => (node.properties[key] = props[key]));
    }
}
