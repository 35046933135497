import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { ICreativeSet, ICreativeSetDto } from '@shared/models/studio';
import { map, Observable, of, switchMap } from 'rxjs';
import { UserService } from '../bannerflow/user.service';
import { mapCreativeSet } from './creativeset-mapper';
import { IGetCreativesHeavyAssetMetadataQueryResponse } from '@shared/models/studio/getCreativesHeavyAssetMetadataQueryResponse';
import { IGetCreativeSetsByIdsResponse } from '@shared/models/studio/get-creative-sets-by-ids-response';

@Injectable({
    providedIn: 'root'
})
export class StudioApiService {
    private readonly http = inject(HttpClient);
    private readonly userService = inject(UserService);

    public getCreativeSetsByIds(creativeSetIds: string[]): Observable<ICreativeSet[]> {
        const url = `${environment.studioApi}/api/creative-sets/get-creativesets-by-ids`;
        const body = {
            ids: creativeSetIds.map((id) => parseInt(id, 10)).filter((id) => !isNaN(id)),
            brandId: this.userService?.user?.brand?.id ?? ''
        };

        return this.http
            .post<IGetCreativeSetsByIdsResponse>(url, body, { observe: 'response' })
            .pipe(
                switchMap((response) => {
                    // If no content (204 No Content), return an empty array
                    if (response.status === HttpStatusCode.NoContent || !response.body) {
                        return of([] as ICreativeSet[]);
                    }

                    const creativeIds = response.body.creativeSets.flatMap((creativeSet) =>
                        creativeSet.creatives.map((creative) => creative.id)
                    );

                    return this.getHeavyAssetMetadata(creativeIds).pipe(
                        map((heavyAssetMetadata) => {
                            if (heavyAssetMetadata) {
                                response.body.creativeSets.forEach((creativeSet) => {
                                    creativeSet.creatives.forEach((creative) => {
                                        const heavyAsset =
                                            heavyAssetMetadata.creativesHeavyAssetMetadata.find(
                                                (ham) => ham.id === creative.id.toString()
                                            );
                                        if (heavyAsset) {
                                            creative.hasHeavyAsset = true;
                                        }
                                    });
                                });
                            }

                            return response.body.creativeSets.map((creativeSet: ICreativeSetDto) =>
                                mapCreativeSet(creativeSet)
                            );
                        })
                    );
                })
            );
    }

    public getHeavyAssetMetadata(
        creativeIds: number[]
    ): Observable<IGetCreativesHeavyAssetMetadataQueryResponse | undefined> {
        const url = `${environment.studioApi}/api/creatives/heavy-asset-metadata`;
        const body = { ids: creativeIds };
        const headers = new HttpHeaders({ 'bf-brand-id': this.userService?.user?.brand?.id ?? '' });

        const result: Observable<IGetCreativesHeavyAssetMetadataQueryResponse | undefined> =
            this.http
                .post<IGetCreativesHeavyAssetMetadataQueryResponse>(url, body, {
                    headers,
                    observe: 'response'
                })
                .pipe(
                    map((response) => {
                        // There are no heavy assets
                        if (response.status === HttpStatusCode.NoContent) {
                            return undefined;
                        }

                        return response.body;
                    })
                );

        return result;
    }

    public getPreviewUrl(creativeSetId: string, creativeId: string): Observable<string> {
        const url = `${environment.acgUrl}/preview-url?creativeset=${creativeSetId}&creative=${creativeId}`;

        const result: Observable<string> = this.http
            .get(url)
            .pipe(map((response: any) => response.previewUrl));

        return result;
    }
}
