import { IDraftAd } from '../../ad';

export const AD_GROUP_DEFAULT_NAME = 'Ad Group';

export interface IAdGroup {
    id: string;
    name?: string;
    ads: IDraftAd[];
    selected?: boolean;
}
