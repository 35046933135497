import * as fromRoot from '@core/core.reducer';
import {
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';
import {
    autoOptimisationReducer,
    IAutoOptimisationState
} from './auto-optimisation/auto-optimisation.reducer';
import { campaignReducer, ICampaignState } from './campaign/campaign.reducer';
import {
    draftCampaignValidationReducer,
    IDraftCampaignValidationState
} from './draft-campaign-validation/draft-campaign-validation.reducer';
import { draftCampaignReducer, IDraftCampaignState } from './draft-campaign/draft-campaign.reducer';

export interface IAppStateWithCampaign extends fromRoot.IAppState {
    campaignManager: ICampaignManagerState;
}
interface ICampaignManagerState {
    campaignState: ICampaignState;
    draftCampaignState: IDraftCampaignState;
    draftCampaignValidationState: IDraftCampaignValidationState;
    autoOptimisationState: IAutoOptimisationState;
}

export const campaignManagerReducer: ActionReducerMap<ICampaignManagerState> = {
    campaignState: campaignReducer,
    draftCampaignState: draftCampaignReducer,
    draftCampaignValidationState: draftCampaignValidationReducer,
    autoOptimisationState: autoOptimisationReducer
};

const selectCampaignManagerState: MemoizedSelector<IAppStateWithCampaign, ICampaignManagerState> =
    createFeatureSelector<IAppStateWithCampaign, ICampaignManagerState>('campaignManager');

export const selectCampaignState: MemoizedSelector<IAppStateWithCampaign, ICampaignState> =
    createSelector(
        selectCampaignManagerState,
        (state: ICampaignManagerState) => state.campaignState
    );

export const selectDraftCampaignState: MemoizedSelector<
    IAppStateWithCampaign,
    IDraftCampaignState
> = createSelector(
    selectCampaignManagerState,
    (state: ICampaignManagerState) => state.draftCampaignState
);

export const selectDraftCampaignValidationState: MemoizedSelector<
    IAppStateWithCampaign,
    IDraftCampaignValidationState
> = createSelector(
    selectCampaignManagerState,
    (state: ICampaignManagerState) => state.draftCampaignValidationState
);

export const selectAutoOptimisationState: MemoizedSelector<
    IAppStateWithCampaign,
    IAutoOptimisationState
> = createSelector(
    selectCampaignManagerState,
    (state: ICampaignManagerState) => state.autoOptimisationState
);
