/**
 * Pure typescript magic
 */
export function groupByKey<T, K extends keyof T>(array: T[], key: K): Map<T[K], T[]> {
    return array.reduce((prev, curr) => {
        const groupedValues: T[] = prev.get(curr[key]) || [];

        groupedValues.push(curr);
        prev.set(curr[key], groupedValues);

        return prev;
    }, new Map());
}

/**
 * Testing utility
 * @param array
 */
export function shuffleArray<T>(array: T[]): T[] {
    const shuffled: T[] = [...array];

    for (let i: number = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled;
}

export function getUniquesById<T extends { id: string }>(array: T[]): T[] {
    if (array === undefined) {
        return;
    }

    const result = [];
    const uniqueMap = new Map();

    for (const item of array) {
        if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, true);
            result.push(item);
        }
    }

    return result;
}

export function checkIfStringArrayHasDuplicate(array: string[]): boolean {
    return new Set(array).size !== array.length;
}
