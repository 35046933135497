import { IEnvironment } from './environment.interface';

export const sandboxEnvironment: IEnvironment = {
    production: false,
    version: 'sandbox',
    stage: 'sandbox',
    appUrl: 'https://sandbox-cm.bannerflow.com',
    idpUrl: 'https://sandbox-login.bannerflow.com',
    studioUrl: 'https://sandbox-studio.bannerflow.com',
    acgUrl: 'https://sandbox-api.bannerflow.com/acg',
    publishServiceUrl: 'https://sandbox-api.bannerflow.com/ps',
    campaignServiceUrl: 'https://sandbox-api.bannerflow.com/cs',
    bannerflowUrl: 'https://sandbox-home.bannerflow.com',
    adServiceUrl: 'https://c.sandbox-bannerflow.net',
    gtmId: '',
    intercomId: '',
    azureFeaturesConnectionString:
        'Endpoint=https://bf-shared-sandbox-ac.azconfig.io;Id=+d6H-l8-s0:ZDsMi4u4B3bL3Y9VAexD;Secret=kycnl5jmUghCUDu70c/LdKSinLqeEtkZE08EvTEsLK4=',
    bannerflowCdnUrl: 'https://c.sandbox-bannerflow.net/',
    commonListViewUrl: 'https://sandbox-api.bannerflow.com/list-service',
    analyticsFrontendUrl: 'https://sandbox-analytics.bannerflow.com',
    clientId: 'Ih6cO7HQzwx03endovuoYcRPwV0HG2az',
    apiGatewayUrl: 'https://sandbox-api.bannerflow.com',
    brandManagerUrl: 'https://sandbox-api.bannerflow.com/brand-manager',
    newRelic: {
        enabled: true,
        accountId: '4232543',
        trustKey: '4122654',
        agentId: '538562446',
        licenseKey: 'NRJS-d0b27a9b958bc4b281c',
        applicationId: '538562446',
        origins: [
            'https://sandbox-home.bannerflow.com',
            'https://c.sandbox-bannerflow.net',
            'https://sandbox-api.bannerflow.com/list-service',
            'https://bf-excelservice-sandbox-func.azurewebsites.net',
            'https://sandbox-login.bannerflow.com',
            'https://sandbox-api.bannerflow.com/ps',
            'https://sandbox-sapi.bannerflow.com',
            'https://sandbox-studio.bannerflow.com',
            'https://sandbox-ziporc.bannerflow.com',
            'https://sandbox-acg.bannerflow.com',
            'https://sandbox-api.bannerflow.com/cs'
        ]
    },
    accountAccessUrl: 'https://sandbox-api.bannerflow.com/account-access'
};

export const environment = sandboxEnvironment;
