import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AppInitService } from './app-init.service';

@Injectable({ providedIn: 'root' })
export class AppInitResolver implements Resolve<boolean> {
    constructor(private appInitService: AppInitService) {}

    public resolve(): Observable<boolean> {
        return this.appInitService.loadAllInParallel();
    }
}
