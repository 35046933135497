import { createAction, props } from '@ngrx/store';
import { ICampaign, IDraftCampaign } from '@shared/models/campaigns';
import { ICampaignError } from '@shared/models/campaigns/validation';

export const loadDraftCampaign = createAction(
    '[DraftCampaign] Load',
    props<{ campaign: ICampaign }>()
);

export const loadDraftCampaignSuccess = createAction(
    '[DraftCampaign] Load Successful',
    props<{ draftCampaign: IDraftCampaign }>()
);

export const loadLiveCampaign = createAction(
    '[DraftCampaign] Load Live Campaign',
    props<{ campaignId: string }>()
);

export const loadLiveCampaignSuccess = createAction(
    '[DraftCampaign] Load Live Campaign Successful',
    props<{ liveCampaign: IDraftCampaign }>()
);

export const updateDraftCampaign = createAction(
    '[DraftCampaign] Update',
    props<{ changes: Partial<IDraftCampaign> }>()
);

export const updateAndPushDraftCampaign = createAction(
    '[DraftCampaign] Update and Push Live',
    props<{ changes: Partial<IDraftCampaign> }>()
);

export const setDraftCampaignHistory = createAction(
    '[DraftCampaign] Set History',
    props<{ history: IDraftCampaign[] }>()
);

export const saveDraftCampaign = createAction('[DraftCampaign] Save');

export const saveDraftCampaignSuccess = createAction(
    '[DraftCampaign] Saved',
    props<{ draftCampaign: IDraftCampaign }>()
);

export const saveDraftCampaignFailure = createAction(
    '[DraftCampaign] Save failed',
    props<{ error: ICampaignError }>()
);

export const undoDraftCampaign = createAction('[DraftCampaign] Undo');

export const pushLiveDraftCampaign = createAction(
    '[DraftCampaign] Push Live',
    props<{ hideDialog: boolean }>()
);

export const pushLiveDraftCampaignSuccess = createAction(
    '[DraftCampaign] Push Live Success',
    props<{ campaign: ICampaign }>()
);

export const pushLiveDraftCampaignFailure = createAction(
    '[DraftCampaign] Push Live Failure',
    props<{ error: ICampaignError }>()
);

export const pushLiveDraftCampaignCancelled = createAction('[DraftCampaign] Push Live Cancelled');

export const discardDraftCampaign = createAction('[DraftCampaign] Discard Changes');

export const discardDraftCampaignSuccess = createAction(
    '[DraftCampaign] Discard Changes Success',
    props<{ draftCampaign: IDraftCampaign }>()
);

export const sortAllDraftCampaignAds = createAction(
    '[DraftCampaign] Sort All Ads',
    props<{ draftCampaign: IDraftCampaign }>()
);

export type DraftCampaignActions =
    | ReturnType<typeof loadDraftCampaign>
    | ReturnType<typeof loadDraftCampaignSuccess>
    | ReturnType<typeof loadLiveCampaign>
    | ReturnType<typeof loadLiveCampaignSuccess>
    | ReturnType<typeof setDraftCampaignHistory>
    | ReturnType<typeof updateDraftCampaign>
    | ReturnType<typeof updateAndPushDraftCampaign>
    | ReturnType<typeof saveDraftCampaign>
    | ReturnType<typeof saveDraftCampaignSuccess>
    | ReturnType<typeof saveDraftCampaignFailure>
    | ReturnType<typeof undoDraftCampaign>
    | ReturnType<typeof pushLiveDraftCampaign>
    | ReturnType<typeof pushLiveDraftCampaignSuccess>
    | ReturnType<typeof pushLiveDraftCampaignFailure>
    | ReturnType<typeof pushLiveDraftCampaignCancelled>
    | ReturnType<typeof discardDraftCampaign>
    | ReturnType<typeof discardDraftCampaignSuccess>
    | ReturnType<typeof sortAllDraftCampaignAds>;
