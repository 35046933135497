import { environment } from '@environments/environment';
import { ICreativeGeneratedInfo } from '../campaigns/campaign/creative-info';
import { ICreative, ICreativeSet } from './creative';

export interface ICreativeVM extends ICreative {
    isGenerated?: boolean;
}

export interface ICreativeSetVM extends ICreativeSet {
    creatives: ICreativeVM[];
}

export class CreativeVMFactory {
    public static createCreativesetVM(
        creativeset: ICreativeSet,
        genInfos: ICreativeGeneratedInfo[]
    ): ICreativeSetVM {
        const creatives: ICreativeVM[] = creativeset.creatives.map((c) => {
            const genInfo: ICreativeGeneratedInfo = genInfos.find(
                (gi) => gi.studioCreativeId === c.id
            );
            const isGenerated = !!genInfo;

            return {
                ...c,
                targetUrl: isGenerated ? genInfo.targetUrl : c.targetUrl,
                preloadImageUrl: isGenerated
                    ? environment.bannerflowCdnUrl + genInfo.imageUrl
                    : c.preloadImageUrl,
                isGenerated
            };
        });
        const creativesetVM: ICreativeSetVM = {
            ...creativeset,
            creatives
        };

        return creativesetVM;
    }
}
