import { createSelector } from '@ngrx/store';
import { IDecisionTree, IDraftCampaign } from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { selectDraftCampaign } from '../draft-campaign/draft-campaign.selectors';

export const selectDecisionTrees = createSelector(
    selectDraftCampaign,
    (draftCampaign: IDraftCampaign) => draftCampaign?.decisionTrees
);

export const selectDecisionTreeById = (adGroupId: string) =>
    createSelector(selectDecisionTrees, (decisionTrees: IDecisionTree[]) =>
        decisionTrees.find((tree) => tree.adGroup.id === adGroupId)
    );

export const selectDecisionTreeByNodeId = (nodeId: string) =>
    createSelector(selectDecisionTrees, (decisionTrees: IDecisionTree[]) => {
        if (decisionTrees) {
            return DecisionTreeUtilities.findTreeOfNode(decisionTrees, nodeId);
        }
        return undefined;
    });

export const selectAdGroupNames = createSelector(selectDecisionTrees, (trees) =>
    trees.map((tree) => tree.adGroup.name)
);
