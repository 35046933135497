import { Injectable, inject } from '@angular/core';
import { BannerflowApiService } from '@core/services/bannerflow';
import { PublishApiService } from '@core/services/publish';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { from, lastValueFrom, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    loadLocalizationsSuccess,
    loadPublishOptionsSuccess,
    loadSizesSuccess,
    loadFallbackCreativeSetSuccess,
    loadHeavyAssetFallbackMapSuccess
} from './brand.actions';
import { StudioApiService } from '@core/services/studio';

@Injectable()
export class BrandEffects {
    private readonly actions$ = inject(Actions);
    private readonly bannerflowService = inject(BannerflowApiService);
    private readonly publishService = inject(PublishApiService);
    private readonly studioService = inject(StudioApiService);

    public loadSizes$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Sizes'),
            switchMap(() =>
                this.bannerflowService
                    .getSizes()
                    .pipe(map((sizes: ISize[]) => loadSizesSuccess({ sizes })))
            )
        )
    );

    public loadLocalizations$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Localizations'),
            switchMap(() =>
                this.bannerflowService
                    .getLocalizations()
                    .pipe(
                        map((localizations: ILocalization[]) =>
                            loadLocalizationsSuccess({ localizations })
                        )
                    )
            )
        )
    );

    public loadPublishOptions$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType('[Brand] Load Publish Options'),
            switchMap(() =>
                from(this.publishService.getPublishOptionConfigurations()).pipe(
                    map((publishOptions: PublishOptionConfiguration[]) =>
                        loadPublishOptionsSuccess({ publishOptions })
                    )
                )
            )
        )
    );

    public mapFallbackCreatives$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(loadFallbackCreativeSetSuccess),
            switchMap(async ({ fallbackCreatives }) => {
                let heavyAssetFallback: string[] = [];

                if (fallbackCreatives.length > 0) {
                    await lastValueFrom(
                        this.studioService.getHeavyAssetMetadata(
                            fallbackCreatives.map((creative) => Number(creative.id))
                        )
                    ).then((haf) => {
                        if (haf?.creativesHeavyAssetMetadata?.length > 0) {
                            heavyAssetFallback = haf.creativesHeavyAssetMetadata.map(
                                (creative) => creative.id
                            );
                        }
                    });
                }
                return loadHeavyAssetFallbackMapSuccess({ heavyAssetFallback });
            })
        )
    );
}
