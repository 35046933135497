import { ObjectID } from 'bson';
import {
    AutoOptimisationMetric,
    IAutoOptimisationVariant
} from '../../auto-optimisation/auto-optimisation.model';
import { CreativeNodeUtilities } from '../creative-node/creative-node-utilities';
import { AnyNode, INodeProperties, NodeType } from '../node';
import { IAutoOptimisationNode } from './auto-optimisation-node.model';

export class AutoOptimisationNodeUtilities {
    public static isAONode(node: AnyNode): node is IAutoOptimisationNode {
        return node?.type === NodeType.AutoOptimisation;
    }

    public static createAutoOptNode(childNode?: AnyNode): IAutoOptimisationNode {
        const variants: IAutoOptimisationVariant[] = [
            {
                id: new ObjectID().toString(),
                name: 'Variant A'
            },
            {
                id: new ObjectID().toString(),
                name: 'Variant B'
            }
        ];

        const autoOptNodeId: string = new ObjectID().toString();

        // create 2 creative nodes
        let children: AnyNode[] = [
            childNode || CreativeNodeUtilities.create(),
            CreativeNodeUtilities.create()
        ];
        children = children.map((child) =>
            CreativeNodeUtilities.assignParentIdToNode(child, autoOptNodeId)
        );

        const properties: INodeProperties = {
            [variants[0].id]: children[0].id,
            [variants[1].id]: children[1].id
        };

        const node: IAutoOptimisationNode = {
            id: autoOptNodeId,
            type: NodeType.AutoOptimisation,
            name: 'Auto-optimisation',
            properties,
            variants,
            children,
            metric: AutoOptimisationMetric.CTR,
            pixelId: ''
        };

        return node;
    }
}
