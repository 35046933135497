export class PublishLogDataTypeGuard {
    public static isPublishLog(json: any) {
        return (
            'correlationId' in json &&
            'accountId' in json &&
            'brandId' in json &&
            'adId' in json &&
            'created' in json &&
            'status' in json &&
            'destinationName' in json &&
            'destinationIcon' in json &&
            'publishOptionType' in json &&
            'publishOptionConfigurationName' in json &&
            'publishedBy' in json &&
            'clickThroughBy' in json &&
            'adTag' in json &&
            'destinationMetadata' in json &&
            'errorMessage' in json &&
            'detailedErrorMessage' in json
        );
    }
}
