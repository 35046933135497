import { ICreativeSet } from '@shared/models/studio';
import { IDraftCampaign } from '../draft-campaign';
import { DraftCampaignUtilities } from '../draft-campaign-utilities';
import { IAutoOptimisationNode, ICreativeNode } from '../nodes';
import {
    AutoOptimisationRestartChangeType,
    AutoOptimisationRestartDraftState,
    AutoOptimisationRestartReason
} from './auto-optimisation-restart.model';

export function generateAutoOptRestartSummary(
    draftStates: AutoOptimisationRestartDraftState[],
    draftCampaign: IDraftCampaign,
    liveCampaign: IDraftCampaign,
    creativeSets: ICreativeSet[]
): string[] {
    return draftStates.map((draftState) => {
        const affectedTree = DraftCampaignUtilities.findRootTreeInDraftCampaignById(
            draftCampaign,
            draftState.nodeId
        );

        const affectedLiveTree = DraftCampaignUtilities.findRootTreeInDraftCampaignById(
            liveCampaign,
            draftState.nodeId,
            false
        );

        const adGroupName = affectedTree.adGroup.name;
        const reasons = draftState.restartDetails.map((restartDetail) => {
            const { affected, reason } = restartDetail;
            switch (reason) {
                case AutoOptimisationRestartReason.VARIANTS_ADDED: {
                    if (affected.length === 1) {
                        return `${affected[0].name} was added`;
                    }

                    return `${affected.map((variant) => variant.name).join(', ')} were added`;
                }
                case AutoOptimisationRestartReason.VARIANTS_REMOVED: {
                    if (affected.length === 1) {
                        return `${affected[0].name} was removed`;
                    }

                    return `${affected.map((variant) => variant.name).join(', ')} were removed`;
                }
                case AutoOptimisationRestartReason.CREATIVE_SETS_CHANGED: {
                    return `${affected
                        .map((variant) => {
                            const { id, name } = variant;
                            const affectedChildId = affectedTree.root.properties[id];
                            const affectedChild = affectedTree.root.children.find(
                                (child) => child.id === affectedChildId
                            );
                            const creativeSetId = (affectedChild as ICreativeNode).creativesetId;
                            const affectedLiveChild = affectedLiveTree.root.children.find(
                                (child) => child.id === affectedChildId
                            );
                            const liveCreativeSetId = (affectedLiveChild as ICreativeNode)
                                .creativesetId;
                            const creativeSet = creativeSets.find((cs) => cs.id === creativeSetId);

                            const isTheSameCreativeSet = creativeSetId === liveCreativeSetId;

                            if (isTheSameCreativeSet) {
                                return `${name} creative set ${creativeSet.name} version was changed`;
                            }

                            return `${name} creative set was changed to ${creativeSet.name}`;
                        })
                        .join(', ')}`;
                }
                case AutoOptimisationRestartReason.PIXEL_CHANGED: {
                    return 'Tracking pixel has changed.';
                }
                case AutoOptimisationRestartReason.KPITYPE_CHANGED: {
                    return 'KPI Type has changed.';
                }
                default: {
                    throw new Error('Unknown Auto Optimisation restart reason.');
                }
            }
        });

        return `Auto-optimisation in ${adGroupName} will be restarted since: ${reasons.join(
            ', '
        )}.`;
    });
}

export function checkIfAutoOptNeedsRestart({
    changeType
}: AutoOptimisationRestartDraftState): boolean {
    return changeType === AutoOptimisationRestartChangeType.MODIFIED_REQUIRES_RESTART;
}

export function getCreativeNodeOfVariant(
    variantId: string,
    node: IAutoOptimisationNode
): ICreativeNode {
    try {
        const nodeId: string = node.properties[variantId];

        return node.children.find((child) => child.id === nodeId) as ICreativeNode;
    } catch (error) {
        throw new Error(error);
    }
}
