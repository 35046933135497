import { Injectable, inject } from '@angular/core';
import { INotificationConfig, UINotificationService } from '@bannerflow/ui';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private uiNotificationService = inject(UINotificationService);

    private readonly defaultConfig: INotificationConfig = {
        type: 'success',
        icon: null,
        autoCloseDelay: 5000,
        placement: 'top',
        hideCloseIcon: null
    };

    public showInformationNotification(
        message: string,
        customConfig: INotificationConfig = {}
    ): void {
        const config: INotificationConfig = {
            ...this.defaultConfig,
            type: 'info',
            hideCloseIcon: false,
            ...customConfig
        };

        this.uiNotificationService.open(message, config);
    }

    public showErrorNotification(message: string, customConfig: INotificationConfig = {}): void {
        const config: INotificationConfig = {
            ...this.defaultConfig,
            type: 'error',
            icon: 'close',
            hideCloseIcon: false,
            ...customConfig
        };

        this.uiNotificationService.open(message, config);
    }

    public showWarning(message: string, customConfig: INotificationConfig = {}): void {
        const config: INotificationConfig = {
            ...this.defaultConfig,
            type: 'warning',
            icon: 'close',
            hideCloseIcon: false,
            ...customConfig
        };

        this.uiNotificationService.open(message, config);
    }
}
