import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class IntercomService {
    constructor() {}

    public init(): void {
        if (window['Intercom']) {
            (window as any).Intercom('boot', {
                app_id: environment.intercomId,
                alignment: 'right',
                horizontal_padding: 20,
                vertical_padding: 20
            });
        }
    }

    public showNewMessage(message?: string): void {
        if (window['Intercom']) {
            (window as any).Intercom('showNewMessage', message);
        }
    }

    public showNews(): void {
        if (window['Intercom']) {
            (window as any).Intercom('showSpace', 'news');
        }
    }
}
