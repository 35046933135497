/**
 * Google Search: "what is typescript enum flags?"
 */
export enum DaysOfWeek {
    Monday = 1 << 0,
    Tuesday = 1 << 1,
    Wednesday = 1 << 2,
    Thursday = 1 << 3,
    Friday = 1 << 4,
    Saturday = 1 << 5,
    Sunday = 1 << 6,
    WeekendDays = Sunday | Saturday,
    WorkDays = Monday | Tuesday | Wednesday | Thursday | Friday,
    EveryDay = WeekendDays | WorkDays
}

export class DaysOfWeekUtilities {
    /**
     * Returns weekday starting from Monday
     * @param dateTime
     */
    public static getDay(dateTime: number): number {
        // Javascript Date() starts with Sunday=0
        // we shift days to satisfy Sunday=6
        return (new Date(dateTime).getDay() + 6) % 7;
    }

    public static dayToEnum(day: number): DaysOfWeek {
        return 1 << day;
    }

    public static includesDay(selectedDays: number, day: number): boolean {
        return !!(selectedDays & this.dayToEnum(day));
    }

    public static getWeekDayArray(daysOfWeek: DaysOfWeek): number[] {
        const result: number[] = [];

        for (let day = 0; day < 7; day++) {
            if (DaysOfWeekUtilities.includesDay(daysOfWeek, day)) {
                result.push(day);
            }
        }

        return result;
    }
}
