import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppStateWithEditor } from '../editor.state';

@Injectable()
export class CreativeSetPreviewEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject<Store<IAppStateWithEditor>>(Store);
}
