export enum PublishAttemptStatus {
    New = 0,
    AwaitingCreatives = 1,
    Pending = 2,
    Success = 3,
    Failure = 4
}

export enum AdAttemptStatus {
    Unknown = 0,
    Pending = 1,
    Success = 2,
    CreativeFailed = 3,
    CreativeTimedOut = 4,
    PublishingFailed = 5,
    PublishingTimedOut = 6
}
