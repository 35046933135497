import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';

// This component is used by Auth0 to handle the authorization PKCE code
@Component({
    standalone: true,
    imports: [UIModule],
    template: '<ui-loader></ui-loader>'
})
export class AuthComponent implements OnInit {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    ngOnInit() {
        const codeParam = this.route.snapshot.queryParams.code;

        if (!codeParam) {
            this.router.navigate(['/404']);
        }
    }
}
