import { IPalette } from './user.interface';

export class Palette implements IPalette {
    public name = 'New palette';
    public swatch: string[] = [];

    public deserialize(json: any): this {
        if (!json) {
            return null;
        }

        this.name = json.name;
        this.swatch = json.swatch;

        return this;
    }
}
