import { IBannerSet, ISizeFormat } from '@shared/models/bannerflow';
import { ILocalization } from '@shared/models/common';

export class BannerflowTypeGuards {
    public static isBannerSet(json: any): json is IBannerSet {
        return (
            'id' in json &&
            'creativesetId' in json &&
            'name' in json &&
            'thumb' in json &&
            'isFolder' in json &&
            'created' in json &&
            'modified' in json
        );
    }

    public static isSizeFormat(json: any): json is ISizeFormat {
        return 'id' in json && 'name' in json && 'size' in json;
    }

    public static isLocalization(json: any): json is ILocalization {
        return 'id' in json && 'culture' in json && 'name' in json;
    }
}
