import { PublicationSpecification } from '@shared/models/publish/publish-option-specification';
import {
    Destination,
    PublishAd,
    ParametersDictionary,
    SupportedAdType
} from '@shared/models/publish';

export class PublicationSpecificationFactory {
    /**
     * Creates a new `PublicationSpecification`
     *
     * @param publishOptionConfigurationId
     * @param ads
     * @param parameters
     * @param configDestination
     * @param supportedAdType
     */
    public static create(
        publishOptionConfigurationId: string,
        ads: PublishAd[],
        parameters: ParametersDictionary,
        configDestination: Destination,

        supportedAdType?: SupportedAdType
    ): PublicationSpecification {
        const destination: Destination = this.generateDestinationMeta(configDestination);

        const updatedParameters = {
            ...parameters
        };

        return new PublicationSpecification().deserialize({
            publishOptionConfigurationId,
            ads,
            parameters: updatedParameters,
            destination,
            supportedAdType
        });
    }

    private static generateDestinationMeta(publishDestination: Destination): Destination {
        const destination: Destination = new Destination();
        destination.id = publishDestination.id;
        destination.name = publishDestination.name;
        destination.icon = publishDestination.icon;

        return destination.serialize();
    }
}
