import { Injectable, OnDestroy } from '@angular/core';
import { IAppState, selectUserState } from '@core/core.reducer';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/bannerflow';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export enum KnownUserFeature {
    Studio = 'Studio',
    CampaignManager = 'CampaignManager',
    Analytics = 'Analytics',
    AutoOptimisation = 'AutoOptimization',
    Scheduling = 'Scheduling',
    CommonListView = 'CommonListView'
}

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnDestroy {
    public user: User;
    public subscription: Subscription;

    public get accountSlug(): string {
        return this.user?.brand.accountSlug;
    }

    public get brandSlug(): string {
        return this.user?.brand.slug;
    }

    public get brandId(): string {
        return this.user?.brand.id;
    }

    public get isAccountCMP(): boolean {
        return this.checkIsAccountCMP();
    }

    constructor(private store: Store<IAppState>) {
        this.subscription = this.store
            .select(selectUserState)
            .pipe(map((state) => state?.user))
            .subscribe((user) => {
                this.user = user;
            });
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public hasFeature(knownFeature: KnownUserFeature | string): boolean {
        return this.user.account.features.includes(knownFeature);
    }

    private checkIsAccountCMP(): boolean {
        const cmpFeatures: string[] = [KnownUserFeature.Studio, KnownUserFeature.CampaignManager];

        return cmpFeatures.every((feature) => this.hasFeature(feature));
    }
}
