import { IFallbackCreative } from '@shared/models/campaigns';
import { ILocalization, ISize } from '@shared/models/common';
import { PublishOptionConfiguration } from '@shared/models/publish';
import { BrandActions } from './brand.actions';

export interface IBrandState {
    sizes: ISize[];
    localizations: ILocalization[];
    fallbackCreatives: IFallbackCreative[];
    fallbackCreativeSetId: string;
    publishOptions: PublishOptionConfiguration[];
}

const initialState: IBrandState = {
    sizes: [],
    localizations: [],
    fallbackCreatives: [],
    fallbackCreativeSetId: '',
    publishOptions: []
};

export function brandReducer(state: IBrandState = initialState, action: BrandActions): IBrandState {
    switch (action.type) {
        case '[Brand] Load Sizes': {
            return { ...state, sizes: [] };
        }
        case '[Brand] Load Localizations': {
            return { ...state, localizations: [] };
        }
        case '[Brand] Load Success Sizes': {
            return { ...state, sizes: (action as any).sizes };
        }
        case '[Brand] Load Success Localizations': {
            return { ...state, localizations: (action as any).localizations };
        }
        case '[Brand] Load Fallback Creative set': {
            return { ...state, fallbackCreatives: [] };
        }
        case '[Brand] Load Fallback Creative Set Success': {
            const fallbackCreatives = (action as any).fallbackCreatives;
            let fallbackCreativeSetId = '';

            if (fallbackCreatives.length) {
                fallbackCreativeSetId = fallbackCreatives[0].creativeset?.id;
            }

            return {
                ...state,
                fallbackCreatives,
                fallbackCreativeSetId
            };
        }
        case '[Brand] Load Publish Options Success': {
            return {
                ...state,
                publishOptions: (action as any).publishOptions
            };
        }
        default:
            return state;
    }
}
