import { Injectable } from '@angular/core';
import {
    IBFError,
    IBFHttpError,
    INotificationConfig,
    IUIErrorDialogConfig,
    UIErrorDialogService,
    UINotificationService
} from '@bannerflow/ui';
import { environment } from '@environments/environment';

/**
 * Draft on a generic error handling...
 */
class BFError implements IBFError {
    public title: string;
    public message: string;
    public code: number;

    constructor(params: Partial<BFError> = {}) {
        Object.assign(this, params);
    }
}

/**
 * Error returned by API service when
 * an HTTP request was unsuccessful (status not equal 200)
 */
class BFHttpError extends BFError implements IBFHttpError {
    public requestId: string;
    public requestUrl: string;
    public status: number;
    public originalResponse: any;

    public get logglyUrl(): string {
        if (!this.requestId) {
            return '';
        }
        const fromDate: Date = new Date(Date.now() - 1000 * 60 * 5);
        const encodedDate: string = encodeURIComponent(fromDate.toISOString());

        return `https://bannerflow.loggly.com/search#terms=${this.requestId}&from=${encodedDate}`;
    }

    /**
     * Creates a new instance of the BFHttpError class.
     * @param message The error message returned by the server.
     * @param status The HTTP status code.
     */
    constructor(response?: Response) {
        super();

        // Pass original response (for old errors)
        this.originalResponse = response;

        if (response) {
            let json: any;

            // If not json prevent crash
            try {
                json = response.json ? response.json() : undefined;
            } catch (e) {
                console.error(e);
            }

            if (json) {
                this.message = json.message;
            } else if (this.originalResponse.error && this.originalResponse.error.message) {
                this.message = this.originalResponse.error.message;
            }
            this.title = response.statusText;
            this.status = response.status;
            this.requestUrl = response.url;

            if (response.headers) {
                this.requestId = response.headers.get('bannerflow-request-id');
            }
        }
    }
}
@Injectable({
    providedIn: 'root'
})
export class ApiHelperService {
    constructor(
        private errorDialogService: UIErrorDialogService,
        private notificationService: UINotificationService
    ) {}

    public catchError(error: any): void {
        const bfHttpError: BFHttpError = new BFHttpError(error);

        // Show dialog for unhandled errors
        if (bfHttpError.status === 500) {
            const config: IUIErrorDialogConfig = {};
            this.errorDialogService.show(config, bfHttpError);
        } else {
            let msg: string = bfHttpError.message ? bfHttpError.message : 'Something went wrong!';

            if (bfHttpError.status === 409) {
                msg = 'Conflict with other item, please try again';
            } else if (bfHttpError.status === 404) {
                msg = 'Page Not found';
            } else if (error.status === 401) {
                msg = 'Unauthorized request, please try again';
            } else if (!environment.production) {
                // log when you don't know what the hell is going on
                console.error(error);
            }

            const config: INotificationConfig = {
                type: 'error',
                autoCloseDelay: 5000,
                placement: 'top'
            };

            this.notificationService.open(msg, config);
        }
    }
}
