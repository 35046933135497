import { IAdPublishStatusUpdate, IFallbackCreative } from '@shared/models/campaigns';
import { SizeUtilities } from '@shared/models/common';
import { IAdListFilter, IListColumn, IListColumnsMap } from '../models/ad-list-filter';
import { AdPublishStateMap, PAGE_SIZES, IListAd } from '../models/ad-list.model';

export function checkIsListFilterActive(filter: IAdListFilter): boolean {
    return Object.keys(filter)
        .map((key) => filter[key])
        .some((filterValue) => filterValue.length > 0);
}

export function checkIsColumnFilterActive(columns: IListColumnsMap): boolean {
    return Object.keys(columns)
        .map((key) => columns[key])
        .some((col: IListColumn) => !col.selected);
}

export function deserializeFilter(filterObject: IAdListFilter): { [key: string]: string } {
    return Object.keys(filterObject).reduce((result: { [key: string]: string }, currentKey) => {
        if (filterObject[currentKey].length) {
            const currentValues = filterObject[currentKey].join(',');
            result[currentKey] = currentValues;
        }

        return result;
    }, {});
}

export function canAssign(ad: IListAd, creative: IFallbackCreative): boolean {
    return (
        SizeUtilities.isSameSize(creative.size, ad.size) &&
        creative.version?.localization?.id === ad?.language?.id &&
        !!creative.design?.id
    );
}

export function getAdFallbackCreative(
    ad: IListAd,
    fallbackCreatives: IFallbackCreative[]
): IFallbackCreative | undefined {
    return fallbackCreatives.find((creative) => canAssign(ad, creative));
}

export function getCurrentPageSize(totalAmount: number, currentPageSize: number): number {
    const isCurrentBaseOption = PAGE_SIZES.includes(currentPageSize);
    if (isCurrentBaseOption) {
        return currentPageSize;
    }

    // custom page size => All ads option
    return totalAmount;
}

export function createAdPublishStateMap(
    adStates: (IAdPublishStatusUpdate | IListAd)[]
): AdPublishStateMap {
    return adStates.reduce(
        (result: AdPublishStateMap, current: IAdPublishStatusUpdate | IListAd) => {
            if ('adId' in current) {
                result[current.adId] = {
                    adId: current.adId,
                    status: current.status,
                    destinations: current.destinations
                };
            } else {
                result[current.id] = {
                    adId: current.id,
                    status: current.status,
                    destinations: current.destinations
                };
            }

            return result;
        },
        {}
    );
}
