import { KeyValue } from './key-value.model';

export class StaticProperty {
    public key: string;
    public value?: any;
    public values?: KeyValue[];
    public valueType: string;
    public isRequired: boolean;
    public selectedValue?: any;
    public showInPublishFlow: boolean;

    public static deserialize(json: any): StaticProperty | null {
        if (!json) {
            return null;
        }
        const property: StaticProperty = new StaticProperty();
        property.key = json.key;
        property.valueType = json.valueType;
        property.isRequired = json.isRequired;

        if (property.valueType === 'String') {
            property.value = json.value;
            property.showInPublishFlow = false;
        } else if (property.valueType === 'KeyValue' || property.valueType === 'MultiKeyValue') {
            property.values = json.values
                ? json.values.map((value: any) => KeyValue.deserialize(value))
                : [];
            property.showInPublishFlow = this.keyValueShouldBeInPF(json.key);
        } else if (property.valueType === 'Boolean') {
            property.showInPublishFlow = false;
            if (json.value === 'true') {
                property.value = true;
            } else {
                property.value = false;
            }
        }

        return property;
    }

    public serialize(): StaticProperty {
        const property: StaticProperty = new StaticProperty();

        property.key = this.key;

        if (
            this.value !== undefined &&
            (this.valueType === 'String' || this.valueType === 'Boolean')
        ) {
            property.value = this.value.toString();
        } else if (this.valueType === 'KeyValue' || this.valueType === 'MultiKeyValue') {
            property.values = this.values;
        }

        return property;
    }

    public clone(): StaticProperty {
        const property: StaticProperty = new StaticProperty();

        property.valueType = this.valueType;
        property.key = this.key;
        property.value = this.value;
        property.values = this.values
            ? this.values.map((value: any) => value.clone())
            : this.values;
        property.isRequired = this.isRequired;
        property.selectedValue = this.selectedValue;
        property.showInPublishFlow = this.showInPublishFlow;

        return property;
    }

    /** Ignore specific static properties that should not be in publish flow */
    private static keyValueShouldBeInPF(propertyKey: string): boolean {
        const key: string = propertyKey.toLowerCase();

        if (
            key === 'language' ||
            key === 'languages' ||
            key === 'size' ||
            key === 'sizes' ||
            key === 'currency' ||
            key === 'currencies'
        ) {
            return false;
        }

        return true;
    }
}
