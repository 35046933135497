import { IAutoOptimisationVariant, IDraftCampaign } from '@shared/models/campaigns';

export enum AutoOptimisationRestartChangeType {
    NEW = 'NEW',
    NOT_MODIFIED = 'NOT_MODIFIED',
    MODIFIED_REQUIRES_RESTART = 'MODIFIED_REQUIRES_RESTART'
}

export enum AutoOptimisationRestartReason {
    VARIANTS_ADDED = 'VARIANTS_ADDED',
    VARIANTS_REMOVED = 'VARIANTS_REMOVED',
    CREATIVE_SETS_CHANGED = 'CREATIVE_SETS_CHANGED',
    KPITYPE_CHANGED = 'KPITYPE_CHANGED',
    PIXEL_CHANGED = 'PIXEL_CHANGED'
}

export type AutoOptimisationRestartDraftState = {
    nodeId: string;
    changeType: AutoOptimisationRestartChangeType;
    restartDetails: AutoOptimisationRestartDetail[];
};

export type AutoOptimisationRestartDetail = {
    affected: IAutoOptimisationVariant[];
    reason: AutoOptimisationRestartReason;
};

export type AutoOptimisationRestartResponse = {
    draftStates: AutoOptimisationRestartDraftState[];
};

export type AutoOptimisationRestartRequest = {
    draftCampaignModel: IDraftCampaign;
};

export type AutoOptimisationRestartState = {
    showRestartAutoOptModal: boolean;
} & AutoOptimisationRestartResponse;
