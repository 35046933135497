import { AdTagPatterns } from './ad-patterns.model';
import { AdTagSettings } from './ad-settings.model';
import { TagPattern } from './tag-pattern.model';
import { TagSetting } from './tag-setting.model';

export class AdTag {
    public patterns?: AdTagPatterns | null;
    public settings?: AdTagSettings | null;

    public static deserialize(json: any): AdTag | null {
        if (!json) {
            return null;
        }
        const adTag: AdTag = new AdTag();

        // The Json in Dictionary format
        if (json.patterns) {
            const adTagPatterns: AdTagPatterns = new AdTagPatterns();
            const patternsKeys: string[] = Object.keys(json.patterns);

            patternsKeys.forEach((key: string) => {
                adTagPatterns[key] = TagPattern.deserialize(json.patterns[key]);
            });

            adTag.patterns = adTagPatterns;
        }

        // The Json in Dictionary format
        if (json.settings) {
            const adTagSettings: AdTagSettings = new AdTagSettings();
            const settingsKeys: string[] = Object.keys(json.settings);

            settingsKeys.forEach((key: string) => {
                // Deserialize the object only if it's enabled
                if (json.settings[key].isEnabled) {
                    adTagSettings[key] = TagSetting.deserialize(json.settings[key]);
                }
            });

            adTag.settings = adTagSettings;
        }

        return adTag;
    }

    /**
     * Serialize Ad Tag pattern
     */
    public serialize(): AdTag {
        const adTag: AdTag = new AdTag();

        // serialize settings from dictionary
        if (this.settings) {
            adTag.settings = new AdTagSettings();
            const settingsKeys: string[] = Object.keys(this.settings);

            settingsKeys.forEach((key) => {
                adTag.settings[key] = this.settings[key].serialize();
            });
        }

        // Serialize patterns from dictionary
        if (this.patterns) {
            adTag.patterns = new AdTagPatterns();
            const patternsKeys: string[] = Object.keys(this.patterns);

            patternsKeys.forEach((key) => {
                adTag.patterns[key] = this.patterns[key].serialize();
            });
        }

        return adTag;
    }

    /**
     * Clone Ad Tag pattern
     */
    public clone(): AdTag {
        const adTag: AdTag = new AdTag();

        // Clone settings from dictionary
        if (this.settings) {
            adTag.settings = new AdTagSettings();
            const settingsKeys: string[] = Object.keys(this.settings);

            settingsKeys.forEach((key) => {
                adTag.settings[key] = this.settings[key].clone();
            });
        }

        // Clone patterns from dictionary
        if (this.patterns) {
            adTag.patterns = new AdTagPatterns();
            const patternsKeys: string[] = Object.keys(this.patterns);

            patternsKeys.forEach((key) => {
                adTag.patterns[key] = this.patterns[key].clone();
            });
        }

        return adTag;
    }
}
