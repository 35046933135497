import { Injectable, inject } from '@angular/core';
import {
    deleteDecisionTree,
    updateDecisionTree
} from '@campaign/store/decision-tree/decision-tree.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
    AnyNode,
    CreativeNodeUtilities,
    DraftCampaignUtilities,
    ICreativeNode
} from '@shared/models/campaigns';
import { DecisionTreeUtilities } from '@shared/models/campaigns/draft-campaign/decision-tree-utilities';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { hideCreativePanel } from '../creative-panel/creative-panel.actions';
import { selectSelectedCreativeNode } from '../editor.selectors';
import { IAppStateWithEditor } from '../editor.state';
import { hideLeftPanel } from '../left-panel/left-panel.actions';
import { clearSelectedCreativeNode, selectCreativeNode } from './creative-node.actions';
import {
    discardDraftCampaignSuccess,
    updateDraftCampaign
} from '@campaign/store/draft-campaign/draft-campaign.actions';

@Injectable()
export class CreativeNodeEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject<Store<IAppStateWithEditor>>(Store);


    public clearSelectedCreativeNodeonDeleteOrDiscardDecisionTree$: Observable<Action> =
        createEffect(() =>
            this.actions$.pipe(
                ofType(deleteDecisionTree, discardDraftCampaignSuccess),
                map(() => clearSelectedCreativeNode())
            )
        );

    public updateSelectedCreativeNodeOnDecisionTreeChange$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDecisionTree),
            withLatestFrom(this.store.select(selectSelectedCreativeNode)),
            filter(([_action, creativeNode]) => !!creativeNode),
            map(([action, creativeNode]) => {
                const { decisionTree } = action;

                const foundNode: AnyNode = DecisionTreeUtilities.findNode(
                    decisionTree.root,
                    creativeNode.id
                );

                return { foundNode };
            }),
            filter(
                (value) => value.foundNode && CreativeNodeUtilities.isCreativeNode(value.foundNode)
            ),
            map((value) => selectCreativeNode({ payload: value.foundNode as ICreativeNode }))
        )
    );

    public updateSelectedOnDraftCampaignChange$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(updateDraftCampaign),
            withLatestFrom(this.store.select(selectSelectedCreativeNode)),
            filter(([_action, creativeNode]) => !!creativeNode),
            map(([action, creativeNode]) => {
                const { decisionTrees } = action.changes;

                return DraftCampaignUtilities.findNodeInDraftCampaign(
                    decisionTrees,
                    creativeNode.id
                );
            }),
            filter((foundNode) => foundNode && CreativeNodeUtilities.isCreativeNode(foundNode)),
            map((foundNode) => selectCreativeNode({ payload: foundNode as ICreativeNode }))
        )
    );

    public togglePanelsOnSelection$: Observable<void> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(selectCreativeNode),
                filter((action) => !action.payload.creativesetId),
                map((action) => {
                    if (action.payload.creativesetId) {
                        hideLeftPanel();
                    } else {
                        hideCreativePanel();
                    }
                })
            ),
        {
            dispatch: false
        }
    );
}
