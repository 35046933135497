import { Injectable, inject } from '@angular/core';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import { saveDraftCampaign } from '@campaign/store/draft-campaign/draft-campaign.actions';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { CampaignDialogService } from '@core/services/campaigns';
import { IntercomService } from '@core/services/monitoring/intercom.service';
import { Store } from '@ngrx/store';
import { CampaignErrorReason, ICampaignError } from '@shared/models/campaigns';

@Injectable({ providedIn: 'root' })
export class CampaignErrorHandlerService {
    private campaignDialogService = inject(CampaignDialogService);
    private store = inject<Store<IAppStateWithCampaign>>(Store);
    private intercomService = inject(IntercomService);


    public handlePublishErrors(error: ICampaignError): void {
        switch (error.reason) {
            case CampaignErrorReason.HasNoAds: {
                this.campaignDialogService.showNoAdsDialog();
                break;
            }

            case CampaignErrorReason.AdsHaveMultipleChoices: {
                this.campaignDialogService.showMultipleChoicesDialog();
                break;
            }

            case CampaignErrorReason.AdsAreInvalid: {
                this.campaignDialogService.showAdsAreInvalidDialog();
                break;
            }

            case CampaignErrorReason.AutoOptNodeIsMissingCreativeSet: {
                this.campaignDialogService.showAONodeIsMissingCreativeSetDialog();
                break;
            }

            case CampaignErrorReason.AutoOptAdIsMissingDesign: {
                this.campaignDialogService.showAOAdIsMissingDesignDialog();
                break;
            }

            case CampaignErrorReason.ScheduleAdsAreInvalid: {
                this.campaignDialogService.showScheduleAdsAreInvalidDialog();
                break;
            }

            case CampaignErrorReason.HasDuplicatedAdGroupNames: {
                this.campaignDialogService.showDuplicatedAdGroupNamesDialog();
                break;
            }

            case CampaignErrorReason.DraftIsDirty: {
                this.campaignDialogService.showDraftIsDirtyDialog();
                break;
            }

            case CampaignErrorReason.AutoOptIsUsingSameCreative: {
                this.campaignDialogService.showAOIsUsingSameCreativeDialog(
                    CampaignErrorReason.AutoOptIsUsingSameCreative
                );
                break;
            }

            default: {
                this.campaignDialogService.showUnknownError();
                break;
            }
        }
    }

    public handlePushLiveErrors(error: ICampaignError): void {
        switch (error.reason) {
            case CampaignErrorReason.HasNoAds: {
                this.campaignDialogService.showNoAdsDialog(true);
                break;
            }

            case CampaignErrorReason.AdsHaveMultipleChoices: {
                this.campaignDialogService.showMultipleChoicesDialog(true);
                break;
            }

            case CampaignErrorReason.AdsAreInvalid: {
                this.campaignDialogService.showAdsAreInvalidDialog(true);
                break;
            }

            case CampaignErrorReason.AutoOptNodeIsMissingCreativeSet: {
                this.campaignDialogService.showAONodeIsMissingCreativeSetDialog(true);
                break;
            }

            case CampaignErrorReason.AutoOptIsUsingSameCreative: {
                this.campaignDialogService.showAOIsUsingSameCreativeDialog(error.reason, true);
                break;
            }

            case CampaignErrorReason.AutoOptAdIsMissingDesign: {
                this.campaignDialogService.showAOAdIsMissingDesignDialog(true);
                break;
            }

            case CampaignErrorReason.ScheduleAdsAreInvalid: {
                this.campaignDialogService.showScheduleAdsAreInvalidDialog(true);
                break;
            }

            case CampaignErrorReason.HasDuplicatedAdGroupNames: {
                this.campaignDialogService.showDuplicatedAdGroupNamesDialog();
                break;
            }

            default: {
                this.campaignDialogService.showUnknownError();
                break;
            }
        }
    }

    // Errors with extra logic that can't be handled in effects
    public async handleSaveErrors(error: ICampaignError): Promise<void> {
        switch (error.reason) {
            case CampaignErrorReason.DraftSaveFailure: {
                const result: UIConfirmDialogResult =
                    await this.campaignDialogService.showDraftCampaignSaveFailureDialog();

                if (result === 'confirm') {
                    this.store.dispatch(saveDraftCampaign());
                } else if (result === 'discard') {
                    this.intercomService?.showNewMessage();
                }

                break;
            }

            default: {
                this.campaignDialogService.showUnknownError();
                break;
            }
        }
    }
}
