import { ActionReducerMap } from '@ngrx/store';
import { selectedCreativeNode } from './creative-node/creative-node.reducers';
import {
    selectedCreativeVersion,
    showCreativeSetPreview
} from './creative-set-preview/creative-set-preview.reducers';
import { EditorActions } from './editor.actions';
import { IEditorState } from './editor.state';
import { leftPanelReducer } from './left-panel/left-panel.reducers';

export const EDITOR_SCROLL_STORAGE_KEY = 'editorScrollValue';
const defaultScrollValue = 0;
const scrollValue = +localStorage.getItem(EDITOR_SCROLL_STORAGE_KEY) || defaultScrollValue;

function editorScroll(state: number = scrollValue, action: EditorActions): number {
    if (action.type === '[Editor] Set scroll position') {
        if (action.payload === undefined) {
            localStorage.setItem(EDITOR_SCROLL_STORAGE_KEY, scrollValue.toString());
            return scrollValue;
        } else {
            localStorage.setItem(EDITOR_SCROLL_STORAGE_KEY, action.payload.toString());
            return action.payload;
        }
    } else {
        return state;
    }
}

function panToAdGroup(state: boolean = false, action: EditorActions): boolean {
    if (action.type === '[Editor] Pan to Ad Group') {
        return action.payload;
    }
    return state;
}

export const editorReducer: ActionReducerMap<IEditorState> = {
    selectedCreativeVersion,
    showCreativeSetPreview,
    leftPanel: leftPanelReducer,
    selectedCreativeNode,
    editorScroll,
    panToAdGroup
};
