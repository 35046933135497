export function copyObject<T>(item: T): T {
    return JSON.parse(JSON.stringify(item));
}

export function isEmpty(obj: object | null | undefined): boolean {
    if (obj === null || obj === undefined) {
        return true; // Object is null or undefined, so it is considered empty
    }

    return Object.keys(obj).length === 0;
}
