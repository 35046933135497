import { Injectable } from '@angular/core';
import {
    CreativeNodeUtilities,
    IDecisionTree,
    IDraftCampaign,
    ScheduleNodeUtilities
} from '@shared/models/campaigns';

@Injectable({ providedIn: 'root' })
export class DraftCampaginApiMiddlewareService {
    public prepareForDraftCampaignAPI(draftCampaign: IDraftCampaign): IDraftCampaign {
        const decisionTrees = draftCampaign.decisionTrees.map((tree) => this.sanitizeNodes(tree));

        return { ...draftCampaign, decisionTrees };
    }

    private sanitizeNodes(tree: IDecisionTree): IDecisionTree {
        if (!ScheduleNodeUtilities.isScheduleNode(tree.root)) {
            return tree;
        }

        const children = tree.root.children.filter(
            (child) => !CreativeNodeUtilities.isEmptyDefaultNode(child)
        );

        return { ...tree, root: { ...tree.root, children } };
    }
}
