import { AdStatus } from '../ad-status.enum';

export type AdStatusView = 'NOT PUBLISHED' | 'PUBLISHING' | 'PUBLISHED' | 'PUBLISH FAILED';

export class AdStatusUtilities {
    public static mapAdStatusToViewModel(status: AdStatus): AdStatusView {
        switch (status) {
            default:
            case AdStatus.Unknown:
            case AdStatus.Created:
            case AdStatus.Draft:
                return 'NOT PUBLISHED';

            case AdStatus.Optimizing:
            case AdStatus.BeingPublished:
                return 'PUBLISHING';

            case AdStatus.Published:
                return 'PUBLISHED';

            case AdStatus.PublishFailed:
            case AdStatus.CreativesFailed:
                return 'PUBLISH FAILED';
        }
    }
}
