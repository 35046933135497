import { PreviewEmptyState, PreviewViewTab } from '../models/ad-preview-view-model';
import { AdPreviewActions } from './ad-preview.actions';
import { IAdPreviewState } from './ad-preview.state';

export const initialState: IAdPreviewState = {
    isLoading: true,
    adGroupId: undefined,
    time: undefined,
    localTime: undefined,
    selectedAdId: undefined,
    viewIndex: 0,
    previewEmptyState: PreviewEmptyState.Unknown,
    currentViewTab: PreviewViewTab.Unknown,
    draftAdPreviews: [],
    publishedAdPreviews: []
};

export function adPreviewReducer(
    state: IAdPreviewState = initialState,
    action: AdPreviewActions
): IAdPreviewState {
    switch (action.type) {
        case '[Ad Preview] Set Ad Group':
            return {
                ...state,
                adGroupId: action.adGroupId
            };

        case '[Ad Preview] Set Preview Time':
            return {
                ...state,
                time: action.time,
                localTime: action.localTime
            };

        case '[Ad Preview] Select Ad':
            return {
                ...state,
                selectedAdId: action.adId
            };

        case '[Ad Preview] Load All':
            return {
                ...state,
                viewIndex: 0,
                isLoading: true
            };

        case '[Ad Preview] Load Complete':
            return {
                ...state,
                isLoading: false,
                draftAdPreviews: action.draftAdPreviews,
                publishedAdPreviews: action.publishedAdPreviews
            };

        case '[Ad Preview] Set Preview View Tab':
            return {
                ...state,
                currentViewTab: action.viewTab,
                viewIndex: 0
            };

        case '[Ad Preview] Set Preview View Index':
            return {
                ...state,
                viewIndex: action.viewIndex
            };

        case '[Ad Preview] Set Current Preview Empty State':
            return {
                ...state,
                previewEmptyState: action.previewEmptyState
            };

        case '[Ad Preview] Unload':
            return {
                ...initialState
            };

        default:
            return state;
    }
}
