import { findCheckedCreativeNode } from '@campaign/utilities/tree-validation.utils';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IAdValidationMap, ICheckedDecisionTree } from '@shared/models/campaigns';
import { IAppStateWithCampaign, selectDraftCampaignValidationState } from '../state';
import { IDraftCampaignValidationState } from './draft-campaign-validation.reducer';

export const selectDraftHasChecked: MemoizedSelector<IAppStateWithCampaign, boolean> =
    createSelector(
        selectDraftCampaignValidationState,
        (state: IDraftCampaignValidationState) => state.hasChecked
    );

export const selectAdsChecked: MemoizedSelector<IAppStateWithCampaign, boolean> = createSelector(
    selectDraftCampaignValidationState,
    (state: IDraftCampaignValidationState) => state.hasCheckedAds
);

export const selectAdValidationMap: MemoizedSelector<IAppStateWithCampaign, IAdValidationMap> =
    createSelector(
        selectDraftCampaignValidationState,
        (state: IDraftCampaignValidationState) => state.adValidationMap
    );

export const selectCheckedDecisionTrees: MemoizedSelector<
    IAppStateWithCampaign,
    ICheckedDecisionTree[]
> = createSelector(
    selectDraftCampaignValidationState,
    (state: IDraftCampaignValidationState) => state.checkedDecisionTrees
);

export const selectCheckedDecisionTreeById = (adGroupId: string) =>
    createSelector(selectCheckedDecisionTrees, (checkedTrees: ICheckedDecisionTree[]) =>
        checkedTrees.find((tree) => tree.adGroupId === adGroupId)
    );

export const selectCheckedCreativeNode = (creativeNodeId: string) =>
    createSelector(selectCheckedDecisionTrees, (checkedTrees: ICheckedDecisionTree[]) => {
        if (!checkedTrees) {
            return undefined;
        }

        return findCheckedCreativeNode(checkedTrees, creativeNodeId);
    });
