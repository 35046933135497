import { IDraftCampaign } from '@shared/models/campaigns';
import * as draftCampaignActions from './draft-campaign.actions';

export interface IDraftCampaignState {
    draftCampaign: IDraftCampaign;
    liveCampaign: IDraftCampaign;
    lastSaveDate: Date;
    lastPushDate: Date;
    isPushingLive: boolean;
    undoHistory: IDraftCampaign[];
    saving: boolean;
}

const initialState: IDraftCampaignState = {
    draftCampaign: undefined,
    liveCampaign: undefined,
    lastSaveDate: undefined,
    lastPushDate: undefined,
    isPushingLive: false,
    undoHistory: [],
    saving: false
};

export function draftCampaignReducer(
    state: IDraftCampaignState = initialState,
    action: ReturnType<(typeof draftCampaignActions)[keyof typeof draftCampaignActions]>
): IDraftCampaignState {
    switch (action.type) {
        case '[DraftCampaign] Load Successful':
            return {
                ...state,
                draftCampaign: action.draftCampaign
            };

        case '[DraftCampaign] Load Live Campaign Successful':
            return {
                ...state,
                liveCampaign: action.liveCampaign
            };

        case '[DraftCampaign] Update':
        case '[DraftCampaign] Update and Push Live':
            return {
                ...state,
                draftCampaign: { ...state.draftCampaign, ...action.changes },
                undoHistory: [...state.undoHistory, state.draftCampaign]
            };

        case '[DraftCampaign] Set History':
            return {
                ...state,
                undoHistory: action.history
            };

        case '[DraftCampaign] Save':
            return {
                ...state,
                saving: true
            };

        case '[DraftCampaign] Saved':
            return {
                ...state,
                draftCampaign: action.draftCampaign,
                lastSaveDate: new Date(),
                saving: false
            };

        case '[DraftCampaign] Save failed':
            return {
                ...state,
                saving: false
            };

        case '[DraftCampaign] Push Live': {
            return {
                ...state,
                isPushingLive: true
            };
        }

        case '[DraftCampaign] Push Live Success':
            return {
                ...state,
                undoHistory: [],
                isPushingLive: false,
                lastPushDate: new Date(),
                draftCampaign: { ...state.draftCampaign, isDirty: false }
            };

        case '[DraftCampaign] Push Live Cancelled':
        case '[DraftCampaign] Push Live Failure':
            return {
                ...state,
                isPushingLive: false
            };

        case '[DraftCampaign] Discard Changes Success':
            return {
                ...state,
                draftCampaign: action.draftCampaign,
                undoHistory: [],
                lastSaveDate: new Date()
            };

        case '[DraftCampaign] Sort All Ads':
            return {
                ...state,
                draftCampaign: action.draftCampaign
            };

        default:
            return state;
    }
}
