import { createAction, props } from '@ngrx/store';
import { ICreativeNode } from '@shared/models/campaigns';

export const selectCreativeNode = createAction(
    '[Creative Node] Select Creative Node',
    props<{ payload: ICreativeNode }>()
);

export const clearSelectedCreativeNode = createAction('[Creative Node] Clear Selected');

export type CreativeNodeActions =
    | ReturnType<typeof selectCreativeNode>
    | ReturnType<typeof clearSelectedCreativeNode>;
