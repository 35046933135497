import { Injectable, inject } from '@angular/core';
import { updateDecisionTree } from '@campaign/store/decision-tree/decision-tree.actions';
import {
    selectDecisionTreeById,
    selectDecisionTreeByNodeId,
    selectDecisionTrees
} from '@campaign/store/decision-tree/decision-tree.selectors';
import {
    selectCheckedDecisionTreeById,
    selectCheckedDecisionTrees
} from '@campaign/store/draft-campaign-validation/draft-campaign-validation.selectors';
import { IAppStateWithCampaign } from '@campaign/store/state';
import { Store } from '@ngrx/store';
import { IDecisionTree } from '@shared/models/campaigns';
import { ICheckedDecisionTree } from '@shared/models/campaigns/validation';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DecisionTreeService {
    private store = inject<Store<IAppStateWithCampaign>>(Store);


    public selectDecisionTrees(): Observable<IDecisionTree[]> {
        return this.store
            .select(selectDecisionTrees)
            .pipe(filter((decisionTrees) => !!decisionTrees));
    }

    public selectCheckedDecisionTrees(): Observable<ICheckedDecisionTree[]> {
        return this.store.select(selectCheckedDecisionTrees);
    }

    public selectDecisionTree(adGroupId: string): Observable<IDecisionTree> {
        return this.store.select(selectDecisionTreeById(adGroupId));
    }

    public selectCheckedTree(adGroupId: string): Observable<ICheckedDecisionTree> {
        return this.store.select(selectCheckedDecisionTreeById(adGroupId));
    }

    public selectDecisionTreeOfNode(nodeId: string): Observable<IDecisionTree | undefined> {
        return this.store.select(selectDecisionTreeByNodeId(nodeId));
    }

    public updateDecisionTree(decisionTree: IDecisionTree): void {
        this.store.dispatch(updateDecisionTree({ decisionTree }));
    }
}
