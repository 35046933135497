import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {
    constructor() {}

    public ngOnInit() {}
}
