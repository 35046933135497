import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { UIModule } from '@bannerflow/ui';
import { GlobalErrorHandler } from '@core/services/monitoring/global-error-handler.service';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NotFoundPageComponent } from './pages/not-found/not-found-page.component';

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, CoreModule, AppRoutingModule, UIModule],
    declarations: [AppComponent, NotFoundPageComponent],
    providers: [
        Title,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideSentinelService(
            withNewRelic({
                ...environment.newRelic,
                applicationVersion: environment.version,
                releaseName: environment.version,
                releaseId: environment.version
            })
        )
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
