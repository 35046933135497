import { createSelector, MemoizedSelector } from '@ngrx/store';
import { IDraftCampaign } from '@shared/models/campaigns';
import { IAppStateWithCampaign, selectDraftCampaignState } from '../state';
import { IDraftCampaignState } from './draft-campaign.reducer';

export const selectDraftCampaign: MemoizedSelector<IAppStateWithCampaign, IDraftCampaign> =
    createSelector(selectDraftCampaignState, (state: IDraftCampaignState) => state.draftCampaign);

export const selectLiveCampaign: MemoizedSelector<IAppStateWithCampaign, IDraftCampaign> =
    createSelector(selectDraftCampaignState, (state: IDraftCampaignState) => state.liveCampaign);

export const selectDraftCampaignDirtyState: MemoizedSelector<IAppStateWithCampaign, boolean> =
    createSelector(
        selectDraftCampaignState,
        (state: IDraftCampaignState) => state.draftCampaign?.isDirty
    );

export const selectDraftCampaignSaveState: MemoizedSelector<IAppStateWithCampaign, boolean> =
    createSelector(selectDraftCampaignState, (state: IDraftCampaignState) => state.saving);
