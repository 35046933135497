import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { IAppState } from '@campaign/pages/list/store/ad-list.selectors';
import {
    receivedAdPublishStatusesUpdate,
    receivedCampaignPublishStatusUpdate
} from '@campaign/store/campaign/campaign.actions';
import { environment } from '@environments/environment';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { IAdPublishStatusUpdate, ICampaignPublishStatusUpdate } from '@shared/models/campaigns';
import { filter, firstValueFrom } from 'rxjs';
import { UserService } from '../bannerflow/user.service';

@Injectable({
    providedIn: 'root'
})
export class PublishStatusService implements OnDestroy {
    private connection: HubConnection;

    constructor(
        private readonly authService: AuthService,
        private readonly store: Store<IAppState>,
        private readonly userService: UserService
    ) {}

    ngOnDestroy() {
        this.connection.stop();
    }

    public prepareConnection(): void {
        this.authService.isAuthenticated$
            .pipe(filter((authenticated) => !!authenticated))
            .subscribe(() => {
                const headers = {
                    'bf-brand-id': this.userService?.user?.brand?.id ?? ''
                };
                this.createConnection(headers);
            });
    }

    private createConnection(headers: Record<string, string>): void {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${environment.campaignServiceUrl}/hub`, {
                accessTokenFactory: () => firstValueFrom(this.authService.getAccessTokenSilently()),
                headers
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None)
            .build();
    }

    public openConnection(accountSlug: string, brandSlug: string, campaignId: string) {
        if (this.connection.state === HubConnectionState.Connected) {
            return;
        }

        this.connection
            .start()
            .then(() => {
                this.connection.send(
                    'SubscribeToPublishingStatusUpdates',
                    accountSlug,
                    brandSlug,
                    campaignId
                );
            })
            .then(() => this.observeStatuses())
            .catch((err) => console.error(err.toString()));
    }

    public observeStatuses() {
        this.connection.on('ReceiveAdStatuses', (adPublishStatuses: IAdPublishStatusUpdate[]) => {
            this.store.dispatch(receivedAdPublishStatusesUpdate({ adPublishStatuses }));
        });

        this.connection.on(
            'ReceiveCampaignStatus',
            (campaignPublishStatus: ICampaignPublishStatusUpdate) => {
                this.store.dispatch(
                    receivedCampaignPublishStatusUpdate({
                        campaignPublishStatus
                    })
                );
            }
        );
    }
}
