import { ICheckedDecisionTree } from '@shared/models/campaigns';

export function findCheckedCreativeNode(
    checkedTrees: ICheckedDecisionTree[],
    creativeNodeId: string
) {
    const allCheckedNodes = checkedTrees.flatMap((tree) => [
        ...tree.checkedCreativeNodes,
        ...tree.checkedDefaultNodes,
        ...tree.checkedExcludedCreativeNodes
    ]);

    return allCheckedNodes.find((node) => node.id === creativeNodeId);
}
